import BillingService from 'pages/Billing/BillingService';

import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ReactComponent as CloseWindowIcon } from 'assets/icons/remove-attachment.svg';
import Button from 'components/common/Button/Button';
import PopoverPanel from 'components/common/PopoverPanel/PopoverPanel';
import useUser from 'redux/authentication/hooks/useUser';

import styles from './BillingBadge.module.scss';

interface BillingProps {
  targetRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
  title: string;
  description?: any;
  onClick: () => void;
  btnLabel: string;
}

const BillingPopover = ({
  targetRef,
  onClose,
  title,
  description,
  onClick,
  btnLabel,
}: BillingProps) => {
  return (
    <PopoverPanel
      targetRef={targetRef}
      targetElementPos={'center.right'}
      childrenElementPos={'center.left'}
      paddingHorizontal={24}
      onBackdropClick={onClose}
    >
      <div className={styles.planPopup}>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <div>
            <div className={styles.closeContainer} onClick={onClose}>
              <CloseWindowIcon className={styles.closeBtn} />
            </div>
          </div>
        </div>
        {description && <div className={styles.description}>{description}</div>}

        <Button fullWidth size="small" label={btnLabel} onClick={onClick} />
      </div>
    </PopoverPanel>
  );
};

const BillingBadge: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { getPortalLink, getCheckoutLink } = BillingService();
  const [showRenew, toggleRenew] = useState(false);
  const [showPaymentDetails, togglePaymentDetails] = useState(false);
  const [paymentDetailsButton, setPaymentDetailsButton] = useState(
    `${t('billing:addPaymentDetails')}`
  );
  const [subscribeButton, setSubscribeButton] = useState(`${t('billing:subscribeNow')}`);

  const refRenew = useRef(null);
  const refTrial = useRef(null);

  useEffect(() => {
    toggleRenew(false);
    togglePaymentDetails(false);
    setPaymentDetailsButton(`${t('billing:addPaymentDetails')}`);
    setSubscribeButton(`${t('billing:subscribeNow')}`);
  }, [location.pathname]);

  const user = useUser();

  const navigateToBilling = async (flow = null) => {
    if (flow == null) {
      setSubscribeButton(`${t('billing:pleaseWait')}`);
      const checkoutLink = await getCheckoutLink(user.email);
      if (checkoutLink) {
        localStorage.setItem('billingFlow', 'checkout');
        window.location.href = checkoutLink;
      }
    } else {
      setPaymentDetailsButton(`${t('billing:pleaseWait')}`);
      const portalLink = await getPortalLink(user.email, flow);
      if (portalLink) {
        localStorage.setItem('billingFlow', 'add-payment');
        window.location.href = portalLink;
      }
    }
  };

  const plan = user.permissions?.type;
  const trial_days_left = parseInt(user?.trial_days_left);
  const total_trial_days = parseInt(user?.total_trial_days);
  const in_trial = user?.in_trial;

  if (plan === 'free' || !plan) {
    return (
      <div className={classNames(styles.planDetails, styles.free)}>
        <div className={styles.badgeTitle}>{t('billing:freePlan')}</div>
      </div>
    );
  }

  if (plan === 'paid' && in_trial && trial_days_left > 0) {
    return (
      <div>
        <div
          ref={refTrial}
          className={classNames(styles.planDetails, styles.trial)}
          onClick={() => togglePaymentDetails(true)}
        >
          <div className={classNames(styles.trialDays, styles.badgeTitle)}>
            {t('billing:daysLeft', {
              days: trial_days_left,
            })}
          </div>
          <div className={styles.trialProgressContainer}>
            <div
              className={styles.trialProgress}
              style={{
                width: `${100 - Math.round((trial_days_left / total_trial_days) * 100)}%`,
              }}
            ></div>
          </div>
        </div>

        {showPaymentDetails && (
          <BillingPopover
            targetRef={refTrial}
            title={t('billing:addPaymentDetailsTitle', { days: trial_days_left })}
            description={
              <Trans i18nKey="billing:upgradeTo" components={[<strong key="0"></strong>]} />
            }
            btnLabel={paymentDetailsButton}
            onClick={() => navigateToBilling('add-payment')}
            onClose={() => togglePaymentDetails(false)}
          />
        )}
      </div>
    );
  }
  if (plan === 'paid' && in_trial && trial_days_left <= 0) {
    return (
      <div>
        <div
          ref={refRenew}
          className={classNames(styles.planDetails, styles.renew)}
          onClick={() => toggleRenew(true)}
        >
          <div className={styles.badgeTitle}>{t('billing:renewPlan')}</div>
        </div>

        {showRenew && (
          <BillingPopover
            targetRef={refRenew}
            title={t('billing:renewPlanDetails')}
            btnLabel={subscribeButton}
            onClick={() => navigateToBilling()}
            onClose={() => toggleRenew(false)}
          />
        )}
      </div>
    );
  }
  return null;
};

export default BillingBadge;
