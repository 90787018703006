import Routes from 'routes/Routes';

import { useEffect, useState } from 'react';

import 'react-quill/dist/quill.snow.css';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import 'assets/styles/App.scss';
import { changeLanguage } from 'assets/translations/languageHandler';
import ConfirmationModalContextProvider from 'components/common/ConfirmationModal/confirmationModalContextProvider';
import LoaderWithFade from 'components/common/LoaderWithFade/LoaderWithFade';
import ScrollToTop from 'components/common/ScrollToTop/ScrollToTop';
import CookieBanner from 'components/logic/CookieBanner/CookieBanner';
import { initializePosthogIfCookieBannerAccepted } from 'components/logic/CookieBanner/PosthogHandler';
import useAuthentication from 'hooks/api/useAuthentication';
import useCurrentLanguage from 'hooks/generic/useCurrentLanguage';
import useFeatureFlagChecker from 'hooks/generic/useFeatureFlagChecker';
import actions from 'redux/app.slice';
import actionsAuth from 'redux/authentication/auth.slice';
import { useAppDispatch } from 'redux/hooks';
import createScript from 'utils/createScript';

function App() {
  const STRIPE_ENABLED = useFeatureFlagChecker('REACT_APP_STRIPE_ENABLED');

  useEffect(() => {
    // If opened from the website
    if ((location.href as string).includes('#register')) {
      dispatch(actionsAuth.setOpenedModal('register'));
    }
    // If opened from the website
    if ((location.href as string).includes('#login')) {
      dispatch(actionsAuth.setOpenedModal('login'));
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === '*' && (e.ctrlKey || e.metaKey)) {
        dispatch(actions.toogleDevMode());
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  const dispatch = useAppDispatch();

  const [showLoader, setShowLoader] = useState(true);
  const [requestPath] = useState(location.pathname);
  const { getCurrentUser } = useAuthentication();
  const currentLanguage = useCurrentLanguage();

  useEffect(() => {
    initializePosthogIfCookieBannerAccepted();
    (async () => {
      await getCurrentUser();
      await changeLanguage(currentLanguage);
      setShowLoader(false);
    })();
  }, []);

  useEffect(() => {
    if (STRIPE_ENABLED) {
      createScript('stripe-pricing', null, 'https://js.stripe.com/v3/pricing-table.js', true);
    }
  }, [STRIPE_ENABLED]);

  if (showLoader) {
    return <LoaderWithFade className="absoluteCenter" />;
  }

  return (
    <Router>
      <ScrollToTop>
        <ConfirmationModalContextProvider>
          <Route path="/" render={() => <Routes requestPath={requestPath} />} />
        </ConfirmationModalContextProvider>
      </ScrollToTop>
      <CookieBanner />
    </Router>
  );
}

export default App;
