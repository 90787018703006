import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import './global-declaration';
import './index.scss';
import { store } from './redux/store';

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);
