import { CSSProperties } from 'react';

import { isNumber } from 'lodash';

type VerticalOptions = 'top' | 'center' | 'bottom' | 'windowTop' | 'windowBottom';
type HorizontalOptions = 'left' | 'center' | 'right' | 'windowLeft' | 'windowRight';

export interface VerticalAnchorsAdjusted {
  targetAnchor: VerticalOptions;
  childrenAnchor: VerticalOptions;
  maxHeight: number | null;
  [key: string]: any;
}
export interface HorizontalAnchorsAdjusted {
  targetAnchor: HorizontalOptions;
  childrenAnchor: HorizontalOptions;
  maxWidth: number | null;
  [key: string]: any;
}
export interface Box {
  x: number;
  y: number;
  height: number;
  width: number;
  bottom: number;
  left: number;
  right: number;
  top: number;
}

export type PositionVertical = 'top' | 'center' | 'bottom';
export type PositionHorizontal = 'left' | 'center' | 'right';

const helper = {
  addPadding: (rect: DOMRect, paddingVertical: number, paddingHorizontal: number) => {
    const box: Box = {
      x: rect.x - paddingHorizontal,
      y: rect.y - paddingVertical,
      height: rect.height + paddingVertical + paddingVertical,
      width: rect.width + paddingHorizontal + paddingHorizontal,
      bottom: rect.bottom + paddingVertical,
      left: rect.left - paddingHorizontal,
      right: rect.right + paddingHorizontal,
      top: rect.top - paddingVertical,
    };

    return box;
  },
  getHorizontalAnchorAdjustObject: (
    targetElementPosHorizontal: PositionHorizontal,
    childrenElementPosHorizontal: PositionHorizontal
  ) => {
    const result: HorizontalAnchorsAdjusted = (() => {
      const self: HorizontalAnchorsAdjusted = {
        targetAnchor: targetElementPosHorizontal,
        childrenAnchor: childrenElementPosHorizontal,
        maxWidth: null,
      };

      self.reverseBothAnchors = () => {
        self.targetAnchor = helper.reverseHorizontalAnchor(self.targetAnchor);
        self.childrenAnchor = helper.reverseHorizontalAnchor(self.childrenAnchor);
        return self;
      };
      self.reverseTargetAnchor = () => {
        self.targetAnchor = helper.reverseHorizontalAnchor(self.targetAnchor);
        return self;
      };
      self.reverseChildrenAnchor = () => {
        self.childrenAnchor = helper.reverseHorizontalAnchor(self.childrenAnchor);
        return self;
      };
      self.setMaxWidth = (width: number) => {
        self.maxWidth = width;
        return self;
      };
      return self;
    })();

    return result;
  },
  getVerticalAnchorAdjustObject: (
    targetElementPosVertical: PositionVertical,
    childrenElementPosVertical: PositionVertical
  ) => {
    const result: VerticalAnchorsAdjusted = (() => {
      const self: VerticalAnchorsAdjusted = {
        targetAnchor: targetElementPosVertical,
        childrenAnchor: childrenElementPosVertical,
        maxHeight: null,
      };

      self.reverseBothAnchors = () => {
        self.targetAnchor = helper.reverseVerticalAnchor(self.targetAnchor);
        self.childrenAnchor = helper.reverseVerticalAnchor(self.childrenAnchor);
        return self;
      };
      self.reverseTargetAnchor = () => {
        self.targetAnchor = helper.reverseVerticalAnchor(self.targetAnchor);
        return self;
      };
      self.reverseChildrenAnchor = () => {
        self.childrenAnchor = helper.reverseVerticalAnchor(self.childrenAnchor);
        return self;
      };
      self.setMaxHeight = (width: number) => {
        self.maxHeight = width;
        return self;
      };
      return self;
    })();
    return result;
  },
  reverseVerticalAnchor: (anchor: VerticalOptions): VerticalOptions => {
    return anchor === 'top' ? 'bottom' : anchor === 'bottom' ? 'top' : anchor;
  },
  reverseHorizontalAnchor: (anchor: HorizontalOptions): HorizontalOptions => {
    return anchor === 'left' ? 'right' : anchor === 'right' ? 'left' : anchor;
  },
  getCssStylesByAnchors: (
    verticalAnchorsAdjusted: VerticalAnchorsAdjusted,
    horizontalAnchorsAdjusted: HorizontalAnchorsAdjusted,
    isMeasurmentRender: boolean,
    targetRect: Box,
    childrenRect: Box,
    childrenAtleastFullWidth: boolean
  ) => {
    const styles: CSSProperties = isMeasurmentRender
      ? {
          position: 'fixed',
          top: '0px',
          left: '0px',
          zIndex: 114,
        }
      : {
          position: 'fixed',
          height: verticalAnchorsAdjusted.maxHeight,
          width: horizontalAnchorsAdjusted.maxWidth,
          minWidth: childrenAtleastFullWidth ? targetRect.width : 'unset',
          zIndex: 114,
          // overflow: 'auto',
        };

    if (!isMeasurmentRender) {
      const verticalAnchorInPixels = (() => {
        if (verticalAnchorsAdjusted.targetAnchor === 'top') {
          return targetRect.top;
        }
        if (verticalAnchorsAdjusted.targetAnchor === 'center') {
          return targetRect.height / 2 + targetRect.top;
        }
        if (verticalAnchorsAdjusted.targetAnchor === 'bottom') {
          return targetRect.bottom;
        }
      })();
      if (verticalAnchorsAdjusted.childrenAnchor === 'windowTop') {
        styles.top = 0;
      }
      if (verticalAnchorsAdjusted.childrenAnchor === 'windowBottom') {
        styles.bottom = 0;
      }
      if (verticalAnchorsAdjusted.childrenAnchor === 'bottom') {
        styles.bottom = window.innerHeight - verticalAnchorInPixels;
      }
      if (verticalAnchorsAdjusted.childrenAnchor === 'top') {
        styles.top = verticalAnchorInPixels;
      }
      if (verticalAnchorsAdjusted.childrenAnchor === 'center') {
        styles.top = verticalAnchorInPixels;
        styles.transform = 'translateY(-50%)';
      }

      const horizontalAnchorInPixels = (() => {
        if (horizontalAnchorsAdjusted.targetAnchor === 'left') {
          return targetRect.left;
        }
        if (horizontalAnchorsAdjusted.targetAnchor === 'center') {
          styles.width = childrenRect.width;
          return targetRect.width / 2 + targetRect.left;
        }
        if (horizontalAnchorsAdjusted.targetAnchor === 'right') {
          return targetRect.right;
        }
      })();
      if (horizontalAnchorsAdjusted.childrenAnchor === 'windowLeft') {
        styles.left = 0;
      }
      if (horizontalAnchorsAdjusted.childrenAnchor === 'windowRight') {
        styles.right = 0;
      }
      if (horizontalAnchorsAdjusted.childrenAnchor === 'right') {
        styles.right = window.innerWidth - horizontalAnchorInPixels;
      }
      if (horizontalAnchorsAdjusted.childrenAnchor === 'left') {
        styles.left = horizontalAnchorInPixels;
      }
      if (horizontalAnchorsAdjusted.childrenAnchor === 'center') {
        styles.left = horizontalAnchorInPixels;
        if (verticalAnchorsAdjusted.childrenAnchor === 'center') {
          styles.transform = 'translate(-50%,-50%)';
        } else {
          styles.transform = 'translateX(-50%)';
        }
      }
    }

    return styles;
  },
  getVerticalAnchorPointsAdjusted: (
    targetBox: Box,
    childrenHeight: number,
    targetElementPosVertical: PositionVertical,
    childrenElementPosVertical: PositionVertical
  ): VerticalAnchorsAdjusted => {
    const moreSpaceOnTop = targetBox.top > window.innerHeight - targetBox.bottom;
    const moreSpaceOnBottom = !moreSpaceOnTop;

    let availableSpaceOnTop = 0;
    let availableSpaceOnBottom = 0;

    const considerOverlay = targetElementPosVertical === childrenElementPosVertical;
    const overflowAdjustment = considerOverlay ? targetBox.height : 0;

    switch (targetElementPosVertical) {
      case 'top':
        availableSpaceOnTop = targetBox.top + overflowAdjustment;
        availableSpaceOnBottom = window.innerHeight - targetBox.bottom + overflowAdjustment;
        break;
      case 'center':
        availableSpaceOnTop = targetBox.top + targetBox.height / 2;
        availableSpaceOnBottom = window.innerHeight - targetBox.bottom + targetBox.height / 2;
        break;
      case 'bottom':
        availableSpaceOnTop = targetBox.top + overflowAdjustment;
        availableSpaceOnBottom = window.innerHeight - targetBox.bottom + overflowAdjustment;
        break;
      default:
        break;
    }

    const result = helper.getVerticalAnchorAdjustObject(
      targetElementPosVertical,
      childrenElementPosVertical
    );

    if (childrenElementPosVertical === 'center') {
      if (
        childrenHeight / 2 <= availableSpaceOnTop &&
        childrenHeight / 2 < availableSpaceOnBottom
      ) {
        return result;
      }
      if (moreSpaceOnTop) {
        result.childrenAnchor = 'windowBottom';
        return result.setMaxHeight(Math.min(childrenHeight, window.innerHeight));
      }
      result.childrenAnchor = 'windowTop';
      return result.setMaxHeight(window.innerHeight);
    }

    if (childrenElementPosVertical === 'top') {
      if (childrenHeight <= availableSpaceOnBottom) {
        return result;
      }
      if (childrenHeight <= availableSpaceOnTop) {
        return result.reverseBothAnchors();
      }
      if (moreSpaceOnBottom) {
        return result.setMaxHeight(availableSpaceOnBottom);
      }
      return result.reverseBothAnchors().setMaxHeight(availableSpaceOnTop);
    }

    if (childrenElementPosVertical === 'bottom') {
      if (childrenHeight <= availableSpaceOnTop) {
        return result;
      }
      if (childrenHeight <= availableSpaceOnBottom) {
        return result.reverseBothAnchors();
      }
      if (moreSpaceOnTop) {
        return result.setMaxHeight(availableSpaceOnTop);
      }
      return result.reverseBothAnchors().setMaxHeight(availableSpaceOnBottom);
    }

    return result;
  },
  getHorizontalAnchorPointsAdjusted: (
    targetBox: Box,
    childrenWidth: number,
    targetElementPosHorizontal: PositionHorizontal,
    childrenElementPosHorizontal: PositionHorizontal
  ): HorizontalAnchorsAdjusted => {
    const moreSpaceOnLeft = targetBox.left > window.innerWidth - targetBox.right;
    const moreSpaceOnRight = !moreSpaceOnLeft;

    let availableSpaceOnLeft = 0;
    let availableSpaceOnRight = 0;

    const considerOverlay = targetElementPosHorizontal === childrenElementPosHorizontal;
    const overflowAdjustment = considerOverlay ? targetBox.width : 0;

    switch (targetElementPosHorizontal) {
      case 'left':
        availableSpaceOnLeft = targetBox.left + overflowAdjustment;
        availableSpaceOnRight = window.innerWidth - targetBox.right + overflowAdjustment;
        break;
      case 'center':
        availableSpaceOnLeft = targetBox.left + targetBox.width / 2;
        availableSpaceOnRight = window.innerWidth - targetBox.right + targetBox.width / 2;
        break;
      case 'right':
        availableSpaceOnLeft = targetBox.left + overflowAdjustment;
        availableSpaceOnRight = window.innerWidth - targetBox.right + overflowAdjustment;
        break;
      default:
        break;
    }

    const result = helper.getHorizontalAnchorAdjustObject(
      targetElementPosHorizontal,
      childrenElementPosHorizontal
    );

    if (childrenElementPosHorizontal === 'center') {
      if (childrenWidth / 2 <= availableSpaceOnLeft && childrenWidth / 2 < availableSpaceOnRight) {
        return result;
      }
      if (moreSpaceOnLeft) {
        result.childrenAnchor = 'windowRight';
        return result.setMaxWidth(Math.min(childrenWidth, window.innerWidth));
      }
      result.childrenAnchor = 'windowLeft';
      return result.setMaxWidth(window.innerWidth);
    }

    if (childrenElementPosHorizontal === 'left') {
      if (childrenWidth <= availableSpaceOnRight) {
        return result;
      }
      if (childrenWidth <= availableSpaceOnLeft) {
        return result.reverseBothAnchors();
      }
      if (moreSpaceOnRight) {
        return result.setMaxWidth(availableSpaceOnRight);
      }
      return result.reverseBothAnchors().setMaxWidth(availableSpaceOnLeft);
    }

    if (childrenElementPosHorizontal === 'right') {
      if (childrenWidth <= availableSpaceOnLeft) {
        return result;
      }
      if (childrenWidth <= availableSpaceOnRight) {
        return result.reverseBothAnchors();
      }
      if (moreSpaceOnLeft) {
        return result.setMaxWidth(availableSpaceOnLeft);
      }
      return result.reverseBothAnchors().setMaxWidth(availableSpaceOnRight);
    }

    return result;
  },
  getPopoverArrowStyles: (
    targetBox: Box,
    childrenBox: Box,
    childrenMinWidth: number,
    arrowColor: string,
    isMeasurmentRender: boolean
  ) => {
    const arrowLength = 7;

    const styles: CSSProperties = isMeasurmentRender
      ? {
          display: 'none',
        }
      : {
          display: 'block',
          position: 'absolute',
          height: 0,
          width: 0,
          zIndex: 114,
          borderLeft: `${arrowLength}px solid transparent`,
          borderRight: `${arrowLength}px solid transparent`,
          borderBottom: `${arrowLength}px solid ${arrowColor}`,
        };

    if (!isMeasurmentRender) {
      const childrenBoxLocal = {
        ...childrenBox,
        width: childrenBox.width > childrenMinWidth ? childrenBox.width : childrenMinWidth,
      };
      const targetTopLeftCornerX = targetBox.left;
      const targetTopLeftCornerY = targetBox.top;
      const targetBottomRightCornerX = targetBox.left + targetBox.width;
      const targetBottomRightCornerY = targetBox.top + targetBox.height;

      const childrenTopLeftCornerX = childrenBoxLocal.left;
      const childrenTopLeftCornerY = childrenBoxLocal.top;
      const childrenBottomRightCornerX = childrenBoxLocal.left + childrenBoxLocal.width;
      const childrenBottomRightCornerY = childrenBoxLocal.top + childrenBoxLocal.height;

      const horizontalOverlapOnly = !(
        targetTopLeftCornerX >= childrenBottomRightCornerX ||
        childrenTopLeftCornerX >= targetBottomRightCornerX
      );

      const verticalOverlapOnly = !(
        targetTopLeftCornerY >= childrenBottomRightCornerY ||
        childrenTopLeftCornerY >= targetBottomRightCornerY
      );

      const bothHorizontalAndVerticalOverlap = horizontalOverlapOnly && verticalOverlapOnly;
      const noHorizontalOrVerticalOverlap = !horizontalOverlapOnly && !verticalOverlapOnly;

      if (bothHorizontalAndVerticalOverlap || noHorizontalOrVerticalOverlap) {
        styles.display = 'none';
      } else {
        if (horizontalOverlapOnly) {
          const targetBoxAsReference = childrenBoxLocal.width >= targetBox.width;

          const referenceBox = targetBoxAsReference ? targetBox : childrenBoxLocal;
          const otherBox = targetBoxAsReference ? childrenBoxLocal : targetBox;

          const referenceBoxOnTop = referenceBox.bottom <= otherBox.top;
          const arrowDistanceFromTop = referenceBoxOnTop ? otherBox.top : otherBox.bottom;

          const targetBoxOnTop =
            (referenceBoxOnTop && targetBoxAsReference) ||
            (!referenceBoxOnTop && !targetBoxAsReference);

          styles.top = arrowDistanceFromTop - arrowLength;
          styles.left = targetBoxAsReference
            ? targetBox.left + targetBox.width / 2 + -arrowLength
            : otherBox.left + otherBox.width / 2 - arrowLength;
          if (!targetBoxOnTop) {
            styles.transform = 'rotate(180deg)';
            styles.top = arrowDistanceFromTop;
          }

          return styles;
        }

        // VERTICAL OVERLAP ONLY

        const targetBoxAsReference = childrenBoxLocal.height >= targetBox.height;

        const referenceBox = targetBoxAsReference ? targetBox : childrenBoxLocal;
        const otherBox = targetBoxAsReference ? childrenBoxLocal : targetBox;
        const referenceBoxHalfHeight = referenceBox.height / 2;

        const referenceBoxCenter = referenceBox.top + referenceBoxHalfHeight;
        const referenceBoxOnRight = referenceBox.left >= otherBox.right;

        const arrowDistanceFromLeft = referenceBoxOnRight ? otherBox.right : otherBox.left;

        if (
          targetBoxAsReference &&
          (referenceBoxCenter === otherBox.top || referenceBoxCenter === otherBox.bottom)
        ) {
          styles.display = 'none';
        }

        const targetBoxOnRight =
          (referenceBoxOnRight && targetBoxAsReference) ||
          (!referenceBoxOnRight && !targetBoxAsReference);

        // 1's added and subtracted below to close gap between arrow and popover
        // styles.top = referenceBoxCenter - arrowLength / 2;
        styles.top = targetBoxAsReference
          ? targetBox.top + targetBox.height / 2 - arrowLength / 2
          : otherBox.top + otherBox.height / 2 - arrowLength / 2;
        styles.left = arrowDistanceFromLeft - arrowLength / 2 - 1;
        styles.transform = 'rotate(90deg)';
        if (!targetBoxOnRight) {
          styles.transform = 'rotate(270deg)';
          styles.left = arrowDistanceFromLeft - arrowLength / 2 - arrowLength + 1;
        }
      }
    }

    return styles;
  },
  getArrowStyles: (
    popoverStyles,
    targetRect: Box,
    childrenRect: Box,
    arrowColor: string,
    isMeasurmentRender: boolean
  ) => {
    if (childrenRect) {
      let left: number;
      let right: number;
      let top: number;
      let bottom: number;

      if ('left' in popoverStyles) {
        left = Number(popoverStyles.left);
        right = Number(popoverStyles.left) + childrenRect.width;
      } else {
        right = window.innerWidth - Number(popoverStyles.right);
        left = window.innerWidth - (Number(popoverStyles.right) + childrenRect.width);
      }

      if ('top' in popoverStyles) {
        top = Number(popoverStyles.top);
        bottom = Number(popoverStyles.top) + childrenRect.height;
      } else {
        bottom = window.innerHeight - Number(popoverStyles.bottom);
        top = window.innerHeight - (Number(popoverStyles.bottom) + childrenRect.height);
      }

      const updatedChildrenRect = {
        ...childrenRect,
        top,
        bottom,
        left,
        right,
      };

      const childrenMinWidth = isNumber(popoverStyles.minWidth)
        ? popoverStyles.minWidth
        : childrenRect.width;

      return helper.getPopoverArrowStyles(
        targetRect,
        updatedChildrenRect,
        childrenMinWidth,
        arrowColor,
        isMeasurmentRender
      );
    }
  },
};

export default helper;
