import requestAPI from 'utils/apiHandler';

export default function BillingService() {
  return {
    getPortalLink: async (username, flow = null, returnUrl = null) => {
      const response = await requestAPI({
        url: '/portal-session',
        method: 'POST',
        data: { username, flow },
      });

      return response && response.status === 'ok' ? response.link : null;
    },

    getCheckoutLink: async username => {
      const response = await requestAPI({
        url: '/checkout-session',
        method: 'POST',
        data: { username },
      });

      return response && response.status === 'ok' ? response.link : null;
    },
  };
}
