export const formatTranslationPath = (path: string) => {
  return path.substring(4);
};
export const formatTranslationPathForUI = (path: string) => {
  return [`{t('${path.replace('.', ':')}')}`, `t('${path.replace('.', ':')}')`];
};

export const getAllSubPathsToPath = (stack: string) => {
  const objectPaths = ['lng'];
  if (!objectPaths.includes(stack)) {
    objectPaths.push(stack);
  }

  stack.split('.').forEach((itm, indexUpper) => {
    const path = stack
      .split('.')
      .filter((item, index) => index <= indexUpper)
      .join('.');

    if (path !== '' && !objectPaths.includes(path)) {
      objectPaths.push(path);
    }
  });
  return objectPaths;
};

export const iterateObject = (obj: any, stack: string, func: (obj: any, stack: string) => void) => {
  Object.keys(obj).forEach(property => {
    if (typeof obj[property] === 'object') {
      const currentStack = `${stack}.${property}`;

      func(obj[property], currentStack);
      iterateObject(obj[property], currentStack, func);
    }
  });
};

export const iterateObjectProperties = (
  obj: any,
  stack: string,
  func: (obj: any, property: string, stack: string) => void
) => {
  Object.keys(obj).forEach(property => {
    const currentStack = `${stack}.${property}`;
    func(obj, property, currentStack);
    if (typeof obj[property] === 'object') {
      iterateObjectProperties(obj[property], currentStack, func);
    }
  });
};

export const getLangugageFromPath = (path: string) => {
  return path.split('.')[1];
};

export default null;
