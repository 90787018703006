import { iterateObjectProperties } from 'pages/Translations/helper';

import i18n from 'i18next';
import _ from 'lodash';
import { initReactI18next } from 'react-i18next';

import requestAPI from 'utils/apiHandler';

export const changeLanguage = async languageCode => {
  if (i18n.language === languageCode) {
    return;
  }

  const response = await requestAPI(`/translations/en,${languageCode}`);
  const languageObj = (() => {
    if (languageCode === 'en') {
      // iterateObjectProperties(response[languageCode], '', (obj, property) => {
      //   if (typeof obj[property] === 'string') obj[property] = `**${obj[property]}`;
      // });
      return response.en;
    }
    iterateObjectProperties(response[languageCode] || response.en, '', (obj, property) => {
      if (obj[property] === '') {
        delete obj[property];
      }
    });
    return _.merge(response.en, response[languageCode]);
  })();

  Object.keys(languageObj).forEach(namespace => {
    i18n.addResourceBundle(languageCode, namespace, languageObj[namespace]);
  });

  i18n.changeLanguage(languageCode);
  window.localStorage.setItem('lang', languageCode);

  // Update the HTML lang attribute
  document.documentElement.lang = languageCode;
};

(async () => {
  i18n.use(initReactI18next).init({ resources: {} });
})();

export default i18n;
