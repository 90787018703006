import { changeLanguage } from 'assets/translations/languageHandler';
import { getPosthogInstance } from 'components/logic/CookieBanner/PosthogHandler';
import useCurrentLanguage from 'hooks/generic/useCurrentLanguage';
import actions from 'redux/authentication/auth.slice';
import { useAppDispatch } from 'redux/hooks';
import requestAPI from 'utils/apiHandler';

export interface LoginCredentials {
  email: string;
  password: string;
  rememberMe?: boolean;
}

export default function useAuthentication() {
  const dispatch = useAppDispatch();
  const currentLanguage = useCurrentLanguage();

  const getCurrentUser = async () => {
    try {
      const token = localStorage.getItem('tokenNewApi');
      if (!token) {
        throw new Error('Can not get token from cookies');
      }

      const response = await requestAPI('/me');

      if (response.status === 'not found') {
        logOut();
      }

      if (response.status === 'ok') {
        const userData = response.data;

        dispatch(actions.setUser(userData));
        changeLanguage(userData.language || currentLanguage);

        // Set Feature Flag
        const flags = await requestAPI('/feature-flags');
        if (flags) {
          const trueFlags = flags.filter(flag => flag.value).map(item => item.flagName);
          dispatch(actions.setFeatureFlags(trueFlags));
        }

        // Posthog Indetify User
        if (userData) {
          getPosthogInstance()?.identify(userData.email, {
            email: userData.email,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const login = async (
    credentials: LoginCredentials
  ): Promise<{ isSuccess: boolean; error: string }> => {
    try {
      const response = await requestAPI({
        url: '/login',
        method: 'POST',
        data: {
          email: credentials.email,
          password: credentials.password,
          rememberMe: credentials.rememberMe,
        },
      });

      if (response.data === 'Email or password are wrong') {
        return { isSuccess: false, error: response.data };
      }

      localStorage.setItem('tokenNewApi', response.token);
      await getCurrentUser();

      return { isSuccess: true, error: '' };
    } catch (error) {
      return { isSuccess: false, error: error.message.toString() };
    }
  };

  const loginAsTempUser = async () => {
    try {
      const response = await requestAPI({
        url: 'user/tmp',
        method: 'POST',
      });

      if (response.token) {
        localStorage.setItem('tokenNewApi', response.token);

        await getCurrentUser();
        return true;
      }
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  const logOut = async () => {
    try {
      // Clear redux state
      dispatch(actions.setUser(null));
      getPosthogInstance()?.reset();
      localStorage.removeItem('tokenNewApi');
      window.location.href = '/';
    } catch (error) {
      console.error(error);
    }
  };

  return {
    login,
    logOut,

    getCurrentUser,
    loginAsTempUser,
  };
}
