import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import Button from 'components/common/Button/Button';
import ModalDialog from 'components/common/ModalDialog/ModalDialog';
import TextField from 'components/common/TextField/TextField';
import useAuthentication, { LoginCredentials } from 'hooks/api/useAuthentication';
import useForm from 'hooks/generic/useForm';
import actions from 'redux/authentication/auth.slice';
import useUser from 'redux/authentication/hooks/useUser';
import { useAppDispatch } from 'redux/hooks';
import { shakeModal } from 'utils/shakeElement';

import styles from './LoginModal.module.scss';

const LoginModal: React.FC = () => {
  const [crendetials, setCredentials] = useState<LoginCredentials>({
    email: '',
    password: '',
    rememberMe: false,
  });
  const { login } = useAuthentication();

  const [error, setError] = useState('');
  const history = useHistory();

  const onLoginClick = async () => {
    form.setFormIsTouched(true);
    if (!form.isValid) {
      shakeModal();
      return;
    }
    form.setIsLoadingActions(true);
    const result = await login(crendetials);
    if (result.isSuccess) {
      history.push('');
      dispatch(actions.setOpenedModal(null));
    } else {
      setError(result.error);
      shakeModal();
    }

    form.setIsLoadingActions(false);
  };
  const [t] = useTranslation();

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      onLoginClick();
    }
  };

  const form = useForm({
    formIsTouched: false,
    rules: [
      ['email', crendetials.email, 'email', 'is not valid'],
      ['password', crendetials.password, 'required'],
    ],
  });

  const errorActual = form.isLoadingActions ? '' : error;

  const user = useUser();
  const isTempUser = !!user?.temporary;

  const validErrorMessages = {
    '"email" must be a valid email': t('login:emailIsNotValid'),
    'Email or password are wrong': t('login:emailOrPasswordAreWrong'),
  };

  const dispatch = useAppDispatch();

  return (
    <ModalDialog
      allowBackdropNav={true}
      onClose={
        isTempUser
          ? () => {
              dispatch(actions.setOpenedModal(null));
            }
          : undefined
      }
      open={true}
      title={t('login:login')}
      hasContentPadding={false}
      isLoadingActions={form.isLoadingActions}
      error={validErrorMessages[errorActual] ? validErrorMessages[errorActual] : ''}
      actions={[
        <div key={'1'} style={{ width: '100%' }}>
          <Button
            fullWidth={true}
            label={t('login:login')}
            onClick={onLoginClick}
            isLoading={form.isLoadingActions}
          />

          <br />
          <div className={styles.footer}>
            <div>{t('login:forgotYourPassword')}</div>
            <div>
              <Link to="#" onClick={() => dispatch(actions.setOpenedModal('resetPassword'))}>
                {t('login:resetPassword')}
              </Link>
            </div>
          </div>
        </div>,
      ]}
    >
      <form autoComplete="on" className={styles.container}>
        <div className={styles.header}>
          <div>
            {t('login:dontHaveAnAccountYet')}{' '}
            <Link to="#" onClick={() => dispatch(actions.setOpenedModal('register'))}>
              {t('login:registerNow')}
            </Link>
          </div>
          {/* <div>
            <Link to="demoMode">Demo mode</Link>
          </div> */}
        </div>
        <div className={styles.content}>
          <TextField
            labelAndValidation={{
              label: t('login:email'),
              form,
              property: 'email',
            }}
            name="email"
            id="email"
            placeholder="example@email.com"
            value={crendetials.email}
            onChange={e => {
              setCredentials(prevState => ({ ...prevState, email: e.target.value.toLowerCase() }));
            }}
            onKeyDown={onKeyDown}
            autoComplete="email"
          />
        </div>
        <div className={styles.line}></div>
        <div className={styles.content}>
          <TextField
            labelAndValidation={{
              label: t('common:personalInformation.password'),
              form,
              property: 'password',
            }}
            name="password"
            id="password"
            placeholder={t('login:enterYourPassword')}
            value={crendetials.password}
            onChange={e =>
              setCredentials(prevState => ({ ...prevState, password: e.target.value }))
            }
            onKeyDown={onKeyDown}
            type="password"
            autoComplete="current-password"
          />
        </div>
      </form>
    </ModalDialog>
  );
};

export default LoginModal;
