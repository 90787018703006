import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDown } from 'assets/icons/chevron.svg';
import LabelWithErrors from 'components/common/LabelWithErrors/LabelWithErrors';
import PopoverPanel, { PositionString } from 'components/common/PopoverPanel/PopoverPanel';
import fieldErrorParser, { FieldErrorParserInterface } from 'utils/fieldErrorParser';

import styles from './DropDown.module.scss';

interface AvailableClassNames {
  outer: string;
  outerSelected: string;
  inner: string;
  groupTitle: string;
}

export interface Props {
  options?: any[];
  value?: string;
  disabled?: boolean;
  onChange?: (value: any, option: any) => any;
  emptyItemLabel?: string;
  labelProperty?: string;
  idProperty?: string;
  groupProperty?: string;
  labelAndValidation?: FieldErrorParserInterface;
  renderSelected?: ({
    item,
    emptyItemSelected,
  }: {
    item: any;
    emptyItemSelected: boolean;
  }) => React.ReactNode;
  renderRow?: ({
    item,
    isSelected,
    classes,
    onClick,
    close,
  }: {
    item: any;
    isSelected: boolean;
    classes: AvailableClassNames;
    onClick: (item: any) => void;
    close: () => void;
  }) => React.ReactNode;
  renderPanel?: ({
    classes,
    close,
    handleSelection,
  }: {
    classes: AvailableClassNames;
    close: () => void;
    handleSelection: (option: any) => void;
  }) => React.ReactNode;
  iconStart?: React.ReactNode;
  targetElementPos?: PositionString;
  childrenElementPos?: PositionString;
  paddingVertical?: number;
  paddingHorizontal?: number;
  showChevron?: boolean;
  inputClassName?: string;
  panelClassName?: string;
  containerClassName?: string;
  showSelectedOption?: boolean;
  childrenAtleastFullWidth?: boolean;
  inline?: boolean;
  showArrow?: boolean;
  arrowColor?: string;
  hasSearch?: boolean;
  searchFunction?: (search: string, option: any) => boolean;
  isMultiSelect?: boolean;
  onDropdownOpen?: (value: boolean) => void;
}

const DropDown: React.FC<Props> = ({
  value,
  options = [],
  onChange,
  labelProperty = 'label',
  idProperty = 'id',
  groupProperty,
  emptyItemLabel = '',
  childrenElementPos = 'top.left',
  targetElementPos = 'bottom.left',
  paddingVertical = 2,
  paddingHorizontal = 0,
  iconStart,
  renderRow,
  renderPanel,
  showChevron = true,
  showSelectedOption = true,
  inputClassName = '',
  panelClassName = '',
  containerClassName = '',
  inline = false,
  labelAndValidation,
  disabled = false,
  childrenAtleastFullWidth = true,
  renderSelected,
  showArrow,
  arrowColor,
  hasSearch,
  searchFunction,
  isMultiSelect,
  onDropdownOpen,
}: Props) => {
  const { t } = useTranslation();
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [fieldIsTouched, setFieldIsTouched] = useState(false);
  const containerNode = useRef(null);
  const searchInputNode = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState('');
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(0);
  const optionRef = useRef<HTMLDivElement>(null);

  const dropdownOptions = search
    ? options.filter(option => {
        if (searchFunction) {
          return searchFunction(search, option);
        }

        return option[labelProperty].toLowerCase().includes(search.toLowerCase());
      })
    : options;

  let selectedOption = useMemo(() => {
    return dropdownOptions.find(option => option[idProperty] === value) || null;
  }, [value, dropdownOptions, idProperty]);

  const allOptions = useMemo(() => {
    if (!selectedOption || emptyItemLabel) {
      return [
        { [idProperty]: null, [labelProperty]: emptyItemLabel || t('common:buttonDropDownSelect') },
        ...dropdownOptions,
      ];
    }

    return dropdownOptions;
  }, [emptyItemLabel, dropdownOptions, selectedOption, idProperty, labelProperty, t]);

  if (!selectedOption) {
    selectedOption = allOptions[0];
  }

  const handleSelection = option => {
    setFieldIsTouched(true);
    option && onChange && onChange(option[idProperty], option);
    setOptionsVisible(false);

    if (containerNode.current) {
      containerNode.current.blur();
    }
  };

  const classesAsProps: AvailableClassNames = {
    outer: styles.optionOuter,
    outerSelected: styles.optionSelected,
    inner: styles.option,
    groupTitle: styles.groupTitle,
  };

  const errorInfo = fieldErrorParser(labelAndValidation, fieldIsTouched);
  const emptyItemSelected = selectedOption[labelProperty] === emptyItemLabel;

  const showSearchField = hasSearch && (isMultiSelect || (!isMultiSelect && optionsVisible));

  useEffect(() => {
    searchInputNode && optionsVisible && showSearchField && searchInputNode.current.focus();
    setSearch('');
    setFocusedOptionIndex(0);
    onDropdownOpen && onDropdownOpen(optionsVisible);
  }, [optionsVisible]);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    const { key } = e;
    let nextIndexCount = 0;

    if (key === 'ArrowDown') nextIndexCount = (focusedOptionIndex + 1) % allOptions.length;

    if (key === 'ArrowUp')
      nextIndexCount = (focusedOptionIndex + allOptions.length - 1) % allOptions.length;

    if (key === 'Escape') {
      setOptionsVisible(false);
    }

    if (key === 'Enter') {
      e.preventDefault();
      handleSelection(allOptions[focusedOptionIndex]);
    }

    setFocusedOptionIndex(nextIndexCount);
  };

  useEffect(() => {
    if (!optionRef.current) return;

    optionRef.current.scrollIntoView({
      block: 'center',
    });
  }, [focusedOptionIndex]);

  return (
    <div
      className={classNames(styles.dropDownContainer, containerClassName, {
        [styles.dropDownContainerInline]: inline,
        [styles.disabled]: disabled,
        [styles.inputError]: !!errorInfo.showFirstError,
        [styles.optionsVisible]: optionsVisible,
      })}
    >
      <LabelWithErrors errorInfo={errorInfo} labelAndValidation={labelAndValidation} />
      <div
        ref={containerNode}
        className={classNames(styles.input, inputClassName, {
          [styles.inputWithStartIcon]: !!iconStart,
        })}
        onClick={() => setOptionsVisible(true)}
      >
        {iconStart && !showSearchField && <div className={styles.iconStart}>{iconStart}</div>}

        {showSearchField ? (
          <input
            type="text"
            ref={searchInputNode}
            className={styles.searchField}
            value={search}
            onChange={e => setSearch(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <div
            className={classNames(styles.inputText, {
              [styles.inputTextWithStartIcon]: !!iconStart,
              [styles.inputForEmptyItem]: emptyItemSelected,
            })}
          >
            {renderSelected
              ? renderSelected({ item: selectedOption, emptyItemSelected })
              : selectedOption[labelProperty]}
          </div>
        )}
        <div
          className={classNames(styles.arrow, {
            [styles.arrowHidden]: !showChevron,
            [styles.arrowReversed]: optionsVisible,
          })}
        >
          <ArrowDown />
        </div>
      </div>

      {optionsVisible && (
        <PopoverPanel
          childrenAtleastFullWidth={childrenAtleastFullWidth}
          targetRef={containerNode}
          targetElementPos={targetElementPos}
          childrenElementPos={childrenElementPos}
          paddingVertical={paddingVertical}
          paddingHorizontal={paddingHorizontal}
          showArrow={showArrow}
          arrowColor={arrowColor}
          onBackdropClick={() => {
            setOptionsVisible(false);
          }}
        >
          <div
            //Event shield is for the whiteboard
            className={classNames(
              styles.dropdownPanel,
              panelClassName,
              'eventShield',
              't-div-dropdown-panel'
            )}
            tabIndex={1}
            onKeyDown={handleKeyDown}
          >
            {renderPanel
              ? renderPanel({
                  classes: classesAsProps,
                  close: () => handleSelection(undefined),
                  handleSelection,
                })
              : allOptions.map((item, index) => {
                  const isSelected = item[idProperty] === selectedOption[idProperty];
                  const isEmptyItem = item[labelProperty] === emptyItemLabel;

                  if (isEmptyItem && emptyItemSelected) {
                    return null;
                  }

                  const groupTitle: string = (() => {
                    if (!groupProperty) {
                      return null;
                    }

                    if (
                      (index === 0 && item[groupProperty]) ||
                      (index !== 0 && allOptions[index - 1][groupProperty] !== item[groupProperty])
                    ) {
                      return item[groupProperty];
                    }

                    return null;
                  })();

                  if (!showSelectedOption && isSelected) {
                    return null;
                  }
                  return renderRow ? (
                    <div
                      key={item[idProperty]}
                      className={classNames({
                        [styles.optionOuterHover]: index === focusedOptionIndex,
                      })}
                      ref={index === focusedOptionIndex ? optionRef : null}
                    >
                      {renderRow({
                        item,
                        isSelected,
                        classes: classesAsProps,
                        onClick: item => handleSelection(item),
                        close: () => handleSelection(undefined),
                      })}
                    </div>
                  ) : (
                    [
                      groupTitle ? (
                        <div key={groupTitle} className={styles.groupTitle}>
                          {groupTitle}
                        </div>
                      ) : null,
                      <div
                        key={item[idProperty]}
                        className={classNames(styles.optionOuter, {
                          [styles.optionSelected]: isSelected,
                          [styles.inputForEmptyItem]: isEmptyItem,
                          [styles.optionOuterHover]: index === focusedOptionIndex,
                        })}
                        onClick={() => handleSelection(item)}
                        ref={index === focusedOptionIndex ? optionRef : null}
                      >
                        <div className={styles.option}>{item[labelProperty]}</div>
                      </div>,
                    ]
                  );
                })}
          </div>
        </PopoverPanel>
      )}
    </div>
  );
};

export default DropDown;
