export interface IRuleDefinitionValues {
  mainValue: any;
  secValue: string[];
}

export default class Index {
  required(val: IRuleDefinitionValues) {
    return {
      isValid:
        val.mainValue &&
        String(val.mainValue)
          .replace(/(<([^>]+)>)/gi, '')
          .trim().length > 0,
      skip: false,
    };
  }

  string(val: IRuleDefinitionValues) {
    return {
      isValid: typeof val.mainValue === 'string' || val.mainValue instanceof String,
      skip: false,
    };
  }

  integer(val: IRuleDefinitionValues) {
    return { isValid: Number.isInteger(parseInt(val.mainValue)), skip: false };
  }

  nullable(val: IRuleDefinitionValues) {
    return { isValid: !val || val.mainValue.trim().length === 0, skip: true };
  }

  array(val: IRuleDefinitionValues) {
    return val.mainValue instanceof Array;
  }

  min(val: IRuleDefinitionValues) {
    if (!isNaN(val.mainValue)) {
      return { isValid: parseFloat(val.mainValue) >= parseFloat(val.secValue[0]) };
    }
    return { isValid: val.mainValue.length >= parseFloat(val.secValue[0]) };
  }

  max(val: IRuleDefinitionValues) {
    if (!isNaN(val.mainValue) && !isNaN(parseFloat(val.mainValue))) {
      return { isValid: parseFloat(val.mainValue) <= parseFloat(val.secValue[0]) };
    }
    return { isValid: val.mainValue.length <= parseFloat(val.secValue[0]) };
  }

  between(val: IRuleDefinitionValues) {
    let number = val.mainValue;
    if (isNaN(val.mainValue) && isNaN(parseFloat(val.mainValue))) {
      number = val.mainValue.length;
    }
    return {
      isValid: number >= parseFloat(val.secValue[0]) && number <= parseFloat(val.secValue[1]),
    };
  }

  regex(val: IRuleDefinitionValues) {
    const pattern = new RegExp(val.secValue[0]);
    return { isValid: pattern.test(val.mainValue) };
  }

  email(val: IRuleDefinitionValues) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return { isValid: pattern.test(val.mainValue) };
  }

  password(val: IRuleDefinitionValues) {
    const value = val.mainValue;
    const containsNumber = /[0-9]/.test(value);
    const containsUpperCase = /[A-Z]/.test(value);
    const containsLowerCase = /[a-z]/.test(value);
    const containsSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"|,.<>/?]/.test(value);
    const isLengthValid = value.length >= 8;

    const isValid =
      containsNumber &&
      containsUpperCase &&
      containsLowerCase &&
      containsSpecialCharacter &&
      isLengthValid;

    return { isValid };
  }
}
