import { Dispatch, SetStateAction, memo, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import LoaderWithFade from 'components/common/LoaderWithFade/LoaderWithFade';
import TextField from 'components/common/TextField/TextField';
import useForm from 'hooks/generic/useForm';
import requestAPI from 'utils/apiHandler';

import styles from './CouponInput.module.scss';

interface Props {
  coupon: string;
  setCoupon: Dispatch<SetStateAction<string>>;
  couponStatus: 'isValid' | 'notValid';
  setCouponStatus: Dispatch<SetStateAction<'isValid' | 'notValid'>>;
  onRemoveCoupon?: () => void;
}

const CouponInput: React.FC<Props> = ({
  coupon,
  setCoupon,
  couponStatus,
  setCouponStatus,
  onRemoveCoupon,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [couponMonths, setCouponMonths] = useState(null);

  useEffect(() => {
    if (coupon.length === 8) {
      validateCoupon();
    }
  }, [coupon]);

  const validateCoupon = async () => {
    setIsLoading(true);
    setCouponStatus(null);
    setCouponMonths(null);
    const res = await requestAPI({
      url: '/coupon/validate',
      method: 'POST',
      data: {
        code: coupon,
      },
    });
    setCouponStatus(res.status === 'ok' ? 'isValid' : 'notValid');
    setCouponMonths(res.status === 'ok' ? res.free_months : null);
    console.log({ res });

    setIsLoading(false);
  };

  const form = useForm({
    formIsTouched: true,
    rules: [
      [
        'coupon',
        coupon,
        () => couponStatus === 'isValid' || couponStatus === null,
        t('login:isNotValid'),
      ],
    ],
  });

  return (
    <div className={styles.couponCodeContainer}>
      <TextField
        autoFocus={true}
        value={coupon}
        onChange={e => {
          setCouponStatus(null);
          setCoupon(e.target.value.toUpperCase());
        }}
        maxLength={8}
        labelAndValidation={{
          label: t('login:couponCode'),
          form,
          formIsTouched: true,
          property: 'coupon',
        }}
        onBlur={() => {
          if (couponStatus !== 'isValid') {
            setCoupon('');
            setCouponStatus(null);
          }
        }}
        endIcon={isLoading ? <LoaderWithFade size={24} /> : null}
      />
      <div className={styles.footer}>
        {couponStatus === 'isValid' ? (
          <div className={styles.labelSuccess}>
            <CheckIcon className={styles.iconSuccess} />
            {t('login:campaignDaysForFree', { dayCount: couponMonths * 30 })}
          </div>
        ) : (
          <div></div>
        )}
        {onRemoveCoupon ? (
          <div className={styles.remove} onClick={onRemoveCoupon}>
            {t('common:remove')}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default memo(CouponInput);
