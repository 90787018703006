import { getPosthogInstance } from 'components/logic/CookieBanner/PosthogHandler';

import trimLeadingSlash from './trimLeadingSlash';

const defaults = {
  url: 'noUrlDefined',
  method: 'GET',
  data: {},
  body: undefined,
};

interface Params<T> {
  url: string;
  data?: T;
  body?: any;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  signal?: any;
}

const requestAPI = async <T = any, K = any>(request: Params<T> | string) => {
  try {
    const params = { ...defaults, ...(typeof request === 'string' ? { url: request } : request) };

    const fullUrl = params.url.startsWith('http')
      ? params.url
      : `${window.ENV.REACT_APP_BACKEND_URL}${trimLeadingSlash(params.url)}`;

    const sendBody = params.method !== 'GET';
    const token = localStorage.getItem('tokenNewApi');
    const headers: any = {};
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    if (sendBody && !params.body) {
      headers['content-type'] = 'application/json';
    }

    const response = await fetch(fullUrl, {
      headers,
      method: params.method,
      signal: params.signal,
      ...(sendBody && { body: params.body ? params.body : JSON.stringify(params.data) }),
    });

    const json = (await response.json()) as K;

    //@ts-ignore
    if (json && json.data === 'Forbidden') {
      getPosthogInstance()?.reset();
      localStorage.removeItem('tokenNewApi');
      window.location.href = '/';
    }
    return json;
  } catch (error) {
    return Promise.reject(error);
  }
};

//@ts-ignore
window.requestAPI = requestAPI;
export default requestAPI;
