import { CSSProperties, FC } from 'react';

import classNames from 'classnames';

import LoaderWithFade from 'components/common/LoaderWithFade/LoaderWithFade';
import FallbackAvatar from 'components/logic/FallbackAvatar/FallbackAvatar';
import useImageById from 'hooks/generic/useImageById';
import { useAppSelector } from 'redux/hooks';

import styles from './LoadingUserAvatar.module.scss';

interface Props {
  size?: number;
}

const LoadingUserAvatar: FC<Props> = ({ size = 100 }) => {
  const sizeStr = `${size}px`;
  const profile_image_id = useAppSelector(s => s.auth.user?.profile_image_id);
  const [imageBlob, isLoading] = useImageById(profile_image_id);

  return (
    <div
      className={classNames(styles.container, isLoading)}
      style={{ height: sizeStr, width: sizeStr }}
    >
      {isLoading ? (
        <div className={styles.loader}>
          <LoaderWithFade />
        </div>
      ) : profile_image_id ? (
        <img style={{ height: sizeStr, width: sizeStr }} src={imageBlob} alt="profile picture" />
      ) : (
        <FallbackAvatar />
      )}
    </div>
  );
};

export default LoadingUserAvatar;
