import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { errors as getErrors, validator } from 'utils/react-validator/react-validator';

interface useFormProps {
  errorHandler?: (set: (property: string, error: string) => void) => void;
  isLoadingInitials?: boolean;
  formIsTouched?: boolean;
  rules?: [string, any, string | ((value: any) => boolean), string?][];
}

const useForm = ({
  errorHandler,
  isLoadingInitials,
  rules,
  formIsTouched = false,
}: useFormProps) => {
  const { t } = useTranslation();

  const defaultErrorKeys = {
    required: t('common:isRequired'),
    regex: t('common:isInvalid'),
  };

  const [_formIsTouched, setFormIsTouched] = useState(formIsTouched);
  const [_isLoadingActions, setIsLoadingActions] = useState(false);
  const errors: { property: string; error: string }[] = [];
  const set = (property, error) => {
    errors.push({ property, error });
  };
  if (errorHandler) {
    errorHandler(set);
  }

  if (rules) {
    rules.forEach(rule => {
      const [property, value, ruleItem, translation] = rule;
      if (typeof ruleItem === 'function') {
        const isValid = ruleItem(value);
        if (!isValid) {
          errors.push({ property, error: translation || 'custom error' });
        }
      } else {
        const ruleNameBase = ruleItem.split(':')[0];
        validator(
          { value },
          {
            value: [ruleItem],
          },
          {
            [`value.${ruleNameBase}`]: translation,
          }
        );
        // @ts-ignore
        (getErrors()?.value || []).forEach(error => {
          let errorMessage = error;

          if (translation) {
            errorMessage = translation;
          } else if (defaultErrorKeys[ruleNameBase]) {
            errorMessage = defaultErrorKeys[ruleNameBase];
          }

          errors.push({ property, error: errorMessage });
        });
      }
    });
  }

  useEffect(() => {
    if (_formIsTouched) {
      setTimeout(() => {
        const errorLabels = document.querySelectorAll('[data-label-error="true"]');

        if (errorLabels.length > 0) {
          errorLabels[0].scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: 'nearest',
          });
        }
      }, 300);
    }
  }, [_formIsTouched]);

  return {
    errors,
    isValid: errors.length === 0,
    isLoadingInitials,
    // isLoadingActions,
    formIsTouched: _formIsTouched,
    setFormIsTouched,
    showFormError: _formIsTouched && errors.length !== 0,
    setIsLoadingActions,
    isLoadingActions: _isLoadingActions,
  };
};

export default useForm;
