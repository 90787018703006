import React from 'react';

import classNames from 'classnames';

import styles from './LoaderWithFade.module.scss';

interface Props {
  fade?: boolean;
  className?: string;
  size?: number;
}

const LoaderWithFade: React.FC<Props> = ({ fade = false, className, size = 38 }) => {
  const borderSize = Math.ceil(size / 10);
  return (
    <div className={classNames({ [styles.loaderFade]: fade, [className]: !!className })}>
      <div
        className={styles.loader}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          border: `${borderSize}px solid #f5f5f5`,
          borderTop: `${borderSize}px solid #3b95d1`,
        }}
      ></div>
    </div>
  );
};

export default LoaderWithFade;
