export default function shakeElement(el) {
  if (!el) return false;
  el.classList.add('rotateable');

  let index = 0;
  const values = [40, -40, 40, -40, 0];

  const interval = setInterval(() => {
    el.style.marginLeft = `${values[index]}px`;
    index++;
    if (index === values.length) {
      clearInterval(interval);
    }
  }, 100);

  return true;
}

export const shakeModal = () => {
  setTimeout(() => {
    shakeElement(document.getElementsByClassName('modal')[0]);
  }, 100);
};
