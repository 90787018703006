import React from 'react';

import classnames from 'classnames';

import LoaderWithFade from '../LoaderWithFade/LoaderWithFade';
import styles from './Button.module.scss';

const variantClasses = {
  primary: styles.primary,
  secondary: styles.secondary,
  link: styles.link,
  alt: styles.alt,
  altBright: styles.altBright,
  decline: styles.decline,
  declineText: styles.declineText,
};

const sizeClasses = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
};

const loaderSizes = {
  small: 16,
  medium: 20,
  large: 24,
};
interface Props {
  variant?: keyof typeof variantClasses;
  size?: keyof typeof sizeClasses;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  label?: string;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

const Button: React.FC<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  variant = 'primary',
  size = 'medium',
  label = '',
  fullWidth = false,
  startIcon,
  className,
  disabled,
  isLoading,
  ...rest
}) => {
  const variantClass = variantClasses[variant];
  const sizeClass = sizeClasses[size];
  return (
    <button
      className={classnames(styles.btn, variantClass, sizeClass, {
        [styles.disabled]: disabled,
        [styles.fullWidth]: fullWidth,
        [styles.iconOnly]: !label,
        [styles.loading]: isLoading,
        [className]: !!className,
      })}
      {...rest}
    >
      {startIcon && <div className={styles.iconContainer}>{startIcon}</div>}
      {label && <label>{label}</label>}
      {isLoading && (
        <div className={styles.loaderContainer}>
          <LoaderWithFade size={loaderSizes[size]} />
        </div>
      )}
    </button>
  );
};

export default React.memo(Button);
