import useLanguages from 'hooks/generic/useLanguages';
import useUser from 'redux/authentication/hooks/useUser';

const useCurrentLanguage = () => {
  const user = useUser();
  const languages = useLanguages();
  // If user has logged in and he has stored available language, use that
  if (user?.language) {
    return user?.language;
  }

  // If user in not logged in yet, but has selected a language, use that
  if (localStorage.getItem('lang')) {
    return localStorage.getItem('lang');
  }

  // Detect user languge from browser and check wheter it exists in the available languages
  const browserLanguage = navigator.language.split('-')[0].toLowerCase();
  if (languages.find(lang => lang.id === browserLanguage)) {
    return browserLanguage;
  }

  // If all else fails, use the default language
  return 'en';
};
export default useCurrentLanguage;
