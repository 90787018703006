import { useCallback, useState } from 'react';

export default function useSelectedIds(initialIds: string[]) {
  const [ids, setIds] = useState<string[]>(initialIds);

  const toogleId = useCallback(
    (id: string) => {
      setIds(ids => (ids.includes(id) ? ids.filter(i => i !== id) : [...ids, id]));
    },
    [setIds]
  );

  const addId = useCallback(
    (id: string) => {
      if (!ids.includes(id)) {
        setIds([...ids, id]);
      }
    },
    [ids]
  );

  const removeId = useCallback(
    (id: string) => {
      const isSelected = ids.includes(id);
      if (isSelected) {
        setIds(ids.filter(i => i !== id));
      }
    },
    [ids]
  );

  const setNewIds = useCallback(
    (newIds: string[]) => {
      setIds(newIds);
    },
    [setIds]
  );

  return {
    ids,
    toogleId,
    addId,
    removeId,
    setIds: setNewIds,
  };
}
