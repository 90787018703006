import DocumentsList from 'pages/Editor/DocumentsList';
import ConfirmCodeModal from 'pages/LogIn/ConfirmCodeModal';
import LoginModal from 'pages/LogIn/LoginModal';
import RegisterModal from 'pages/LogIn/RegisterModal';
import ResetPasswordModal from 'pages/LogIn/ResetPasswordModal';
import UserProfile from 'pages/UserProfile/UserProfile';

import React, { Suspense, useEffect } from 'react';

import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import LoaderWithFade from 'components/common/LoaderWithFade/LoaderWithFade';
import NavBar from 'components/logic/NavBar/NavBar';
import useUser from 'redux/authentication/hooks/useUser';
import { useAppSelector } from 'redux/hooks';

import Policies from '../pages/Policies/Policies';
import DemoModeLanding from './DemoModeLanding';
import styles from './Routes.module.scss';

// const EditProfile = React.lazy(() => import('pages/EditProfile/EditProfile'));

const Translations = React.lazy(() => import('pages/Translations/Translations'));
const Coupons = React.lazy(() => import('pages/Coupon/CouponList'));

const WhiteboardMenu = React.lazy(() => import('pages/Whiteboard/WhiteboardMenu'));
const LibraryMenu = React.lazy(() => import('pages/Library/LibraryMenu'));
const MyFiles = React.lazy(() => import('pages/MyFiles/MyFiles'));
const Editor = React.lazy(() => import('pages/Editor/Editor'));
const Calendar = React.lazy(() => import('pages/Calendar/Calendar'));
const Plans = React.lazy(() => import('pages/Plans/Plans'));
const Billing = React.lazy(() => import('pages/Billing/Billing'));

interface Props {
  requestPath?: string;
}

const AuthenticatedRoutes: React.FC<Props> = ({ requestPath }) => {
  const history = useHistory();

  const user = useUser();
  const authOpenedModal = useAppSelector(s => s.auth.openedModal);

  useEffect(() => {
    requestPath && history.replace(requestPath);
  }, []);

  const isTempUser = !!user?.temporary;
  const emailIsValidated = !!user?.email_verified_at;

  const fallback = (
    <div className={styles.loaderContainer}>
      <LoaderWithFade />
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.leftSectionContainer}>
        <NavBar />
      </div>

      {user && !emailIsValidated && <ConfirmCodeModal />}

      <div className={styles.rightSectionContainer}>
        <div className={styles.pageContainer}>
          {authOpenedModal === 'login' && <LoginModal />}
          {authOpenedModal === 'register' && <RegisterModal />}
          {authOpenedModal === 'resetPassword' && <ResetPasswordModal />}

          <Route path="/policies" render={() => <Policies />} />
          <Route path="/demoMode" exact render={() => <DemoModeLanding />} />

          {!user && (
            <Suspense fallback={fallback}>
              <Switch>
                <Route exact path="/" component={authOpenedModal ? null : LoginModal} />
                <Route path="/whiteboard" component={WhiteboardMenu} />
              </Switch>
            </Suspense>
          )}

          {user && emailIsValidated && (
            <Suspense fallback={fallback}>
              <Switch>
                <Route exact path="/" component={() => <Redirect to={'/whiteboard'} />} />

                <Route path="/whiteboard" component={WhiteboardMenu} />
                {user.role === 'admin' && [
                  <Route key="translations" exact path="/translations" component={Translations} />,
                  <Route key="coupons" exact path="/coupons" component={Coupons} />,
                ]}
                {!isTempUser && [
                  <Route key="library" path="/library" component={LibraryMenu} />,
                  <Route key="mymaterials" path="/mymaterials" component={MyFiles} />,
                  <Route key="calendar" path="/calendar" component={Calendar} />,
                  <Route key="account" path="/account" component={UserProfile} />,
                  <Route key="editor" path="/editor/:id" component={Editor} />,
                  <Route key="documents" path="/editor" component={DocumentsList} />,
                ]}
                <Route exact path="/plans" component={Plans} />
                <Route exact path="/billing" component={Billing} />
              </Switch>
            </Suspense>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(AuthenticatedRoutes);
