import React from 'react';

import classNames from 'classnames';

import { ReactComponent as ThreeDotsIcon } from 'assets/icons/three-dots.svg';
import ButtonDropDown from 'components/common/ButtonDropDown/ButtonDropDown';

import styles from './OptionButton.module.scss';

interface Props {
  hoverCircleVariant?: 'none' | 'primary' | 'secondary';
  dotsVariant: 'primary' | 'secondary';
  options: {
    icon?: React.ReactNode;
    label: string;
    id: string;
    visible?: boolean;
    danger?: boolean;
  }[];
  onClick: (id: string) => void;
  children?: React.ReactNode;
  onOptionsVisibleChange?: (visible: boolean) => void;
  containerClassName?: string;
}

const OptionButton: React.FC<Props> = ({
  children,
  options,
  onClick,
  hoverCircleVariant = 'primary',
  dotsVariant = 'primary',
  onOptionsVisibleChange,
  containerClassName,
}) => {
  return (
    <ButtonDropDown
      paddingVertical={7}
      value={null}
      variant="primary"
      containerClassName={containerClassName}
      childrenElementPos="top.center"
      targetElementPos="bottom.center"
      showChevron={true}
      showSelectedOption={false}
      options={options.filter(o => o.visible === undefined || o.visible)}
      onChange={item => {
        onClick(item);
      }}
      onOptionsVisibleChange={onOptionsVisibleChange}
      customButton={({ setOptionsVisible, optionsVisible }) => (
        <div
          className={classNames(styles.container, {
            [styles.optionsVisible]: optionsVisible,
            [styles.showHoverCircle]: hoverCircleVariant !== 'none',
            [styles.hoverCircleVariantPrimary]: hoverCircleVariant === 'primary',
            [styles.hoverCircleVariantSecondary]: hoverCircleVariant === 'secondary',
            [styles.dotsVariantPrimary]: dotsVariant === 'primary',
            [styles.dotsVariantSecondary]: dotsVariant === 'secondary',
          })}
          onClick={setOptionsVisible}
        >
          {children ?? <ThreeDotsIcon />}
        </div>
      )}
    />
  );
};

export default React.memo(OptionButton);
