import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import {
  ClassI,
  CountryI,
  LibraryItemI,
  LicenseI,
  MaterialTypeI,
  StateI,
  SubjectI,
} from './Interfaces';

type SortByType = 'most_recent' | 'rating' | 'rating_count';

const initialState: {
  filterOptions: {
    states: StateI[];
    subjects: SubjectI[];
    classes: ClassI[];
    materialTypes: MaterialTypeI[];
    licenses: LicenseI[];
    countries: CountryI[];
  };
  filters: {
    country: string;
    state: string;
    subjects: string[];
    classes: string[];
    materialTypes: string[];
    licenses: string[];
    search: string;
  };
  sortByOptions: SortByType[];
  libraryItems: LibraryItemI[];
  libraryItemTotalCount: number;
  sortBy: SortByType;
  sortDirection: 'asc' | 'desc';
} = {
  filterOptions: {
    states: [],
    subjects: [],
    classes: [],
    materialTypes: [],
    licenses: [],
    countries: [],
  },
  filters: {
    country: null,
    state: null,
    subjects: [],
    classes: [],
    materialTypes: [],
    licenses: [],
    search: '',
  },
  sortByOptions: ['most_recent', 'rating', 'rating_count'],
  libraryItems: [],
  libraryItemTotalCount: 0,
  sortBy: 'most_recent',
  sortDirection: 'desc',
};

const LibrarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.filters.search = action.payload;
    },
    setSortBy(state, action: PayloadAction<SortByType>) {
      state.sortBy = action.payload;
    },
    setSortDirection(state, action: PayloadAction<'asc' | 'desc'>) {
      state.sortDirection = action.payload;
    },
    resetState() {
      return initialState;
    },
    setLibraryItems(
      state,
      action: PayloadAction<{ items: LibraryItemI[]; libraryItemTotalCount: number }>
    ) {
      state.libraryItems = action.payload.items;
      state.libraryItemTotalCount = action.payload.libraryItemTotalCount;
    },
    toogleSubject(state, action: PayloadAction<string>) {
      const index = state.filters.subjects.indexOf(action.payload);
      if (index === -1) {
        state.filters.subjects.push(action.payload);
      } else {
        state.filters.subjects.splice(index, 1);
      }
    },
    toogleClass(state, action: PayloadAction<string>) {
      const index = state.filters.classes.indexOf(action.payload);
      if (index === -1) {
        state.filters.classes.push(action.payload);
      } else {
        state.filters.classes.splice(index, 1);
      }
    },
    toogleMaterialType(state, action: PayloadAction<string>) {
      const index = state.filters.materialTypes.indexOf(action.payload);
      if (index === -1) {
        state.filters.materialTypes.push(action.payload);
      } else {
        state.filters.materialTypes.splice(index, 1);
      }
    },
    setCountry(state, action: PayloadAction<string>) {
      state.filters.country = action.payload;
    },
    setState(state, action: PayloadAction<string>) {
      state.filters.state = action.payload;
    },
    setFilters(state, action: PayloadAction<(typeof initialState)['filterOptions']>) {
      state.filterOptions = action.payload;
    },
  },
});

export type LibraryStateI = typeof initialState;

export const { reducer } = LibrarySlice;
export default LibrarySlice.actions;
