import { useState } from 'react';

import classnames from 'classnames';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import VerificationInput from 'react-verification-input';

import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import ModalDialog from 'components/common/ModalDialog/ModalDialog';
import useAuthentication from 'hooks/api/useAuthentication';
import actions from 'redux/authentication/auth.slice';
import useUser from 'redux/authentication/hooks/useUser';
import { useAppDispatch } from 'redux/hooks';
import requestAPI from 'utils/apiHandler';
import { shakeModal } from 'utils/shakeElement';

import styles from './ConfirmCodeModal.module.scss';

const ConfirmCodeModal: React.FC = () => {
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const history = useHistory();
  const { getCurrentUser } = useAuthentication();
  const user = useUser();

  const resendCode = async () => {
    await requestAPI({
      url: '/user/resend-verification',
      method: 'PUT',
    });
    await getCurrentUser();
  };

  const verifyCode = async (code: string) => {
    setIsLoading(true);
    setShowError(false);
    const response = await requestAPI({
      url: `/verify/${code.toUpperCase()}`,
      method: 'POST',
    });

    if (response.message === 'ok') {
      localStorage.setItem('tokenNewApi', response.token);
      await getCurrentUser();
      history.push('/');
    } else {
      setShowError(true);
      setIsLoading(false);
      shakeModal();
    }
  };
  const emailIsValidated = !!user?.email_verified_at;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  return (
    <ModalDialog
      open={true}
      title={t('login:activateYourAccount')}
      hasContentPadding={false}
      isLoadingActions={isLoading}
      allowBackdropNav={true}
      onBackBtnClick={
        emailIsValidated
          ? () => {
              dispatch(actions.setOpenedModal('register'));
            }
          : undefined
      }
    >
      <div className={styles.container}>
        <div className={styles.headerTop}>
          <div className={styles.emailMessage}>
            <div className={styles.iconContainer}>
              <MailIcon />
            </div>
            <br />
            {t('login:checkYourEmail')}
            <br />
            {t('login:thePasswordVerificationCodeHasBeenSentTo', {
              email: user?.email,
              timestamp: DateTime.fromISO(user?.validationEmailTimeStamp).toFormat(
                'dd.MM.yyyy HH:mm'
              ),
            })}
          </div>
        </div>
        {showError && (
          <div className={styles.headerTopError}>{t('login:verificationCodeIsNotValid')}</div>
        )}
        <br />
        <div className={styles.codeContainer}>
          <VerificationInput
            autoFocus={true}
            value={code}
            onChange={code => {
              setCode(code);
              if (code.length !== 6) {
                setShowError(false);
              }
            }}
            onComplete={code => {
              setCode(code);
              setIsLoading(true);
              setShowError(false);

              verifyCode(code);
            }}
            placeholder=""
            classNames={{
              character: classnames(styles.character, {
                [styles.characterError]: showError,
              }),
              characterInactive: styles.characterInactive,
              characterSelected: styles.characterSelected,
              characterFilled: styles.characterFilled,
            }}
          />
        </div>
        {user?.email && (
          <div className={styles.header}>
            <div>{t('login:didntReceiveTheCode')}</div>
            <div onClick={resendCode}> {t('login:resendCode')}</div>
          </div>
        )}
      </div>
    </ModalDialog>
  );
};

export default ConfirmCodeModal;
