import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as PencilIcon } from 'assets/icons/pencil3.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash2.svg';
import Button from 'components/common/Button/Button';
import LoaderWithFade from 'components/common/LoaderWithFade/LoaderWithFade';
import useEffectAfterMount from 'hooks/generic/useEffectAfterMount';
import useImageById from 'hooks/generic/useImageById';
import actions from 'redux/authentication/auth.slice';
import useUser from 'redux/authentication/hooks/useUser';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import requestAPI from 'utils/apiHandler';

import styles from './UserProfileImage.module.scss';

const UserProfileImage: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useUser();

  const [isLoading, setIsLoading] = useState(!!user.profile_image_id);
  const inputFile = useRef(null);
  const { t } = useTranslation();

  const onEditClick = () => {
    inputFile.current.click();
  };

  const deleteProfileImage = async () => {
    try {
      await requestAPI({
        url: '/user',
        method: 'PUT',
        data: {
          profile_image_id: null,
        },
      });

      dispatch(actions.setAvatarUrl(null));
    } catch (err) {
      console.error(err);
    }
  };

  const onAvatarChange = async event => {
    try {
      if (event.target.files?.length) {
        setIsLoading(true);
        const file = event.target.files[0];

        const formdata = new FormData();
        formdata.append('file', file);
        const response = await requestAPI({
          url: '/upload',
          method: 'POST',
          body: formdata,
        });

        await requestAPI({
          url: '/user',
          method: 'PUT',
          data: {
            profile_image_id: response.id,
          },
        }).then(() => {
          if (response.status === 200) {
            dispatch(actions.setAvatarUrl(response.id));
          }
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const [imageUrlObject] = useImageById(user.profile_image_id);

  useEffectAfterMount(() => {
    setIsLoading(false);
  }, [imageUrlObject]);

  if (!user) return null;
  return (
    <div className={styles.container}>
      <div className={'text-label'}>{t('login:profilePicture')}</div>
      <input
        type="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={onAvatarChange}
        accept="image/png, image/jpeg"
      />
      <div className={styles.containerInner}>
        <div className={styles.imageContainer} onClick={onEditClick}>
          {isLoading && <LoaderWithFade />}
          {!isLoading && imageUrlObject && user.profile_image_id && <img src={imageUrlObject} />}
          {!isLoading && !user.profile_image_id && <PlusIcon />}
        </div>
        {user.profile_image_id ? (
          <div className={styles.actionButtons}>
            <Button
              fullWidth={true}
              onClick={onEditClick}
              variant="alt"
              startIcon={<PencilIcon />}
              label={t('common:edit')}
            />
            <Button
              fullWidth={true}
              className={styles.deleteButton}
              onClick={deleteProfileImage}
              variant="declineText"
              startIcon={<TrashIcon />}
              label={t('common:delete')}
            />
          </div>
        ) : (
          <div className={styles.infoText}>{t('login:profilePictureInfoText')}</div>
        )}
      </div>
    </div>
  );
};

export default UserProfileImage;
