import { memo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as ChangePasswordIcon } from 'assets/icons/changePassword.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { changeLanguage } from 'assets/translations/languageHandler';
import Button from 'components/common/Button/Button';
import DropDown from 'components/common/DropDown/DropDown';
import LoaderWithFade from 'components/common/LoaderWithFade/LoaderWithFade';
import TextField from 'components/common/TextField/TextField';
import useAuthentication from 'hooks/api/useAuthentication';
import useCurrentLanguage from 'hooks/generic/useCurrentLanguage';
import useForm from 'hooks/generic/useForm';
import useLanguages from 'hooks/generic/useLanguages';
import actions from 'redux/authentication/auth.slice';
import useUser from 'redux/authentication/hooks/useUser';
import { useAppDispatch } from 'redux/hooks';
import requestAPI from 'utils/apiHandler';

import Billing from './Billing';
import ChangePasswordModal from './ChangePasswordModal';
import CouponModal from './CouponModal';
import styles from './UserProfile.module.scss';
import UserProfileImage from './UserProfileImage';

const UserProfile: React.FC = () => {
  const dispatch = useAppDispatch();

  const { logOut } = useAuthentication();

  const { t } = useTranslation();
  const languages = useLanguages();
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const currentLanguage = useCurrentLanguage();
  const user = useUser();
  const [showCouponModal, setShowCouponModal] = useState(false);

  const [userData, setUser] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    language: currentLanguage,
  });

  const simpleStrRegex = 'regex:^[A-Za-z Šš Žž Õõ Ää Öö Üü -]+$';

  const form = useForm({
    formIsTouched: false,
    rules: [
      ['first_name', userData?.first_name, 'required'],
      ['first_name', userData?.first_name, simpleStrRegex],
      ['last_name', userData?.last_name, 'required'],
      ['last_name', userData?.last_name, simpleStrRegex],
    ],
  });

  const updateField = async (property: 'first_name' | 'last_name' | 'language', value: string) => {
    if (!form.errors.find(error => error.property === property)) {
      await requestAPI({
        url: '/user',
        method: 'PUT',
        data: {
          [property]: value.trim(),
        },
      });

      const { data } = await requestAPI('/me');
      const updatedValue = data[property];
      dispatch(actions.setUserField({ property, value: updatedValue }));
    }
  };

  const setUserDataField = (field, value) => {
    setUser(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  if (!userData) {
    return (
      <div className={styles.mainLoader}>
        <LoaderWithFade />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {showResetPasswordModal && (
        <ChangePasswordModal onClose={() => setShowResetPasswordModal(false)} />
      )}
      {showCouponModal && <CouponModal onClose={() => setShowCouponModal(false)} />}
      <div className={styles.containerInner}>
        <div className={styles.title}>{t('common:settings')}</div>

        <div className={styles.mainForm}>
          <div className={styles.detailsContainer}>
            <div className={styles.columns}>
              <TextField
                labelAndValidation={{
                  label: t('common:personalInformation.firstName'),
                  formIsTouched: form.formIsTouched,
                  errors: form.errors,
                  property: 'first_name',
                }}
                onKeyDown={e => {
                  e.key === 'Enter' && (e.target as HTMLInputElement)?.blur();
                }}
                value={userData.first_name}
                onChange={e => setUserDataField('first_name', e.target.value)}
                onBlur={() => updateField('first_name', userData.first_name)}
              />

              <TextField
                labelAndValidation={{
                  label: t('common:personalInformation.lastName'),
                  formIsTouched: form.formIsTouched,
                  errors: form.errors,
                  property: 'last_name',
                }}
                onKeyDown={e => {
                  e.key === 'Enter' && (e.target as HTMLInputElement)?.blur();
                }}
                value={userData.last_name}
                onChange={e => setUserDataField('last_name', e.target.value)}
                onBlur={() => updateField('last_name', userData.last_name)}
              />
            </div>

            <DropDown
              targetElementPos="top.left"
              childrenElementPos="bottom.left"
              paddingHorizontal={0}
              labelAndValidation={{
                label: t('common:personalInformation.language'),
                labelMarginTop: true,
              }}
              value={userData.language}
              options={languages}
              onChange={value => {
                setUserDataField('language', value);
                updateField('language', value);
                changeLanguage(value);
              }}
              showChevron={true}
            />
          </div>

          <div className={styles.billingContainer}>
            <div className={'text-label'}>{t('common:billing')}</div>
            <div className={styles.billingContent}>
              <Billing key={userData.language} />
            </div>
          </div>

          <div className={styles.footer}>
            <Button
              variant="alt"
              label={t('login:changePassword')}
              startIcon={<ChangePasswordIcon />}
              onClick={() => setShowResetPasswordModal(true)}
            />

            <Button
              className={styles.loginBtn}
              variant="secondary"
              startIcon={<LogoutIcon />}
              onClick={() => logOut()}
              label={t('common:logout')}
            />

            <Button
              className={styles.loginBtn}
              variant="secondary"
              onClick={() => setShowCouponModal(true)}
              label={t('login:addCoupon')}
            />
          </div>
          <div className={styles.imageContainer}>
            <UserProfileImage />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(UserProfile);
