import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as ChevronIcon } from 'assets/icons/chevron6.svg';

import styles from './PolicyTitle.module.scss';

interface Props {
  title?: string;
}

const PolicyTitle: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Link to="/policies" className={styles.back}>
        {t('common:policies')}
      </Link>
      <div className={styles.back}>
        <ChevronIcon style={{ rotate: '180deg' }} />
      </div>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default React.memo(PolicyTitle);
