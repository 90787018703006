import { useEffect, useState } from 'react';

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ...windowSize,
    // All these breakpoint values should match breakpoints in main scss file
    isExtraLarge: windowSize.width >= 1365,
    isLarge: windowSize.width < 1365,
    isMedium: windowSize.width < 992,
    isSmall: windowSize.width < 768,
    isExtraSmall: windowSize.width < 600,
    isExtraExtraSmall: windowSize.width < 400,
  };
}
