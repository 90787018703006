import { memo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import ModalDialog from 'components/common/ModalDialog/ModalDialog';
import TextField from 'components/common/TextField/TextField';
import useForm from 'hooks/generic/useForm';
import requestAPI from 'utils/apiHandler';

import styles from './ChangePasswordModal.module.scss';

interface Props {
  onClose: () => void;
}
const ChangePasswordModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  const [model, setModel] = useState({
    newPassword: '',
    oldPassword: '',
    confirmPassword: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const changePassword = async () => {
    if (!form.isValid) {
      return false;
    }
    try {
      form.setFormIsTouched(true);
      setErrorMessage('');
      setIsLoading(true);

      const response = await requestAPI({
        url: '/change-password',
        method: 'POST',
        data: {
          current_password: model.oldPassword,
          new_password: model.newPassword,
          confirm_password: model.confirmPassword,
        },
      });

      setIsLoading(false);
      if (response.status === 'ok') {
        onClose();
      } else if (response.message === 'Invalid current password') {
        setErrorMessage(t('common:passwordReset.currentPasswordIsIncorrect'));
      } else {
        setErrorMessage(t('common:somethingWentWrong'));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const form = useForm({
    formIsTouched: false,
    rules: [
      ['currentPassword', model.oldPassword, 'password', t('login:isNotValid')],
      ['newPassword', model.newPassword, 'password', t('login:isNotValid')],
      ['confirmPassword', model.confirmPassword, 'password', t('login:isNotValid')],
      [
        'confirmPassword',
        model.confirmPassword,
        () => {
          return model.confirmPassword === model.newPassword;
        },
        t('common:passwordReset.noMatch'),
      ],
    ],
  });

  return (
    <ModalDialog
      open={true}
      hasContentPadding={false}
      onClose={onClose}
      title={t('login:changePassword')}
      isLoadingActions={isLoading}
      actionsJustifyContent="right"
      error={(form.showFormError && '') || errorMessage}
      actions={[
        {
          fullWidth: true,
          label: t('login:changePassword'),
          onClick: () => {
            changePassword();
          },
        },
      ]}
    >
      <div className={styles.container}>
        <TextField
          name="currentPassword"
          value={model.oldPassword}
          onChange={e => setModel({ ...model, oldPassword: e.target.value })}
          type={'password'}
          autoFocus={true}
          placeholder={t('Password')}
          labelAndValidation={{
            label: t('common:passwordReset.currentPassword'),
            property: 'currentPassword',
            errors: form.errors,
          }}
        />

        <TextField
          name="newPassword"
          value={model.newPassword}
          onChange={e => setModel({ ...model, newPassword: e.target.value })}
          type={'password'}
          placeholder={t('common:personalInformation.newPassword')}
          labelAndValidation={{
            label: t('common:passwordReset.newPassword'),
            property: 'newPassword',
            errors: form.errors,
            toolTip: t('common:passwordReset.passwordRule'),
            labelMarginTop: true,
          }}
        />

        <TextField
          name="confirmPassword"
          value={model.confirmPassword}
          onChange={e => setModel({ ...model, confirmPassword: e.target.value })}
          type={'password'}
          placeholder={t('common:passwordReset.confirmNewPassword')}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              changePassword();
            }
          }}
          labelAndValidation={{
            label: t('common:passwordReset.confirmNewPassword'),
            property: 'confirmPassword',
            errors: form.errors,
            labelMarginTop: true,
            toolTip: t('common:passwordReset.passwordRule'),
          }}
        />
      </div>
    </ModalDialog>
  );
};

export default memo(ChangePasswordModal);
