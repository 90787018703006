import React, { useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDown } from 'assets/icons/chevron.svg';
import PopoverPanel, { PositionString } from 'components/common/PopoverPanel/PopoverPanel';

import styles from './ButtonDropDown.module.scss';

interface AvailableClassNames {
  outer: string;
  outerSelected: string;
  inner: string;
  groupTitle: string;
}

interface Props {
  options?: any[];
  value?: string;
  disabled?: boolean;
  onChange?: (value: any, option: any) => any;
  emptyItemLabel?: string;
  labelProperty?: string;
  idProperty?: string;

  renderRow?: ({
    item,
    isSelected,
    classes,
    onClick,
    close,
  }: {
    item: any;
    isSelected: boolean;
    classes: AvailableClassNames;
    onClick: (item: any) => void;
    close: () => void;
  }) => React.ReactNode;
  renderPanel?: ({
    classes,
    close,
  }: {
    classes: AvailableClassNames;
    close: () => void;
  }) => React.ReactNode;
  iconStart?: React.ReactNode;
  targetElementPos?: PositionString;
  childrenElementPos?: PositionString;
  paddingVertical?: number;
  paddingHorizontal?: number;
  variant?: 'primary' | 'secondary';
  showChevron?: boolean;
  inputClassName?: string;
  containerClassName?: string;
  showSelectedOption?: boolean;
  onOptionsVisibleChange?: (visible: boolean) => void;
  customButton?: ({
    setOptionsVisible,
    optionsVisible,
  }: {
    setOptionsVisible: () => void;
    optionsVisible: boolean;
  }) => React.ReactNode;
}

const ButtonDropDown: React.FC<Props> = ({
  value,
  options = [],
  onChange,
  labelProperty = 'label',
  idProperty = 'id',
  emptyItemLabel = '',
  targetElementPos = 'bottom.center',
  childrenElementPos = 'top.center',
  paddingVertical = 4,
  paddingHorizontal = 0,
  iconStart,
  renderRow,
  renderPanel,
  showChevron = true,
  showSelectedOption = true,
  inputClassName = '',
  containerClassName = '',
  variant = 'secondary',
  customButton,
  onOptionsVisibleChange,
}) => {
  const { t } = useTranslation();
  const [optionsVisible, setOptionsVisible] = useState(false);
  const containerNode = useRef(null);

  useEffect(() => {
    if (onOptionsVisibleChange) {
      onOptionsVisibleChange(optionsVisible);
    }
  }, [optionsVisible]);

  let selectedOption = useMemo(() => {
    return options.find(option => option[idProperty] === value) || null;
  }, [value, options, idProperty]);

  const allOptions = useMemo(() => {
    if (!selectedOption || emptyItemLabel) {
      return [
        { [idProperty]: null, [labelProperty]: emptyItemLabel || t('common:buttonDropDownSelect') },
        ...options,
      ];
    }

    return options;
  }, [emptyItemLabel, options, selectedOption, idProperty, labelProperty, t]);

  if (!selectedOption) {
    selectedOption = allOptions[0];
  }

  const handleSelection = option => {
    option && option && onChange(option[idProperty], option);
    setOptionsVisible(false);

    if (containerNode.current) {
      containerNode.current.blur();
    }
  };

  const classesAsProps: AvailableClassNames = {
    outer: styles.optionOuter,
    outerSelected: styles.optionSelected,
    inner: styles.option,
    groupTitle: styles.groupTitle,
  };

  return (
    <div
      ref={containerNode}
      className={classNames(styles.buttonDropDownContainer, {
        [styles.primary]: variant === 'primary',
        [styles.secondary]: variant === 'secondary',
        [styles.optionsVisible]: optionsVisible,
      })}
    >
      {customButton ? (
        customButton({ setOptionsVisible: () => setOptionsVisible(true), optionsVisible })
      ) : (
        <div
          className={classNames(styles.input, inputClassName)}
          onClick={() => setOptionsVisible(true)}
        >
          {iconStart && <div className={styles.iconStart}>{iconStart}</div>}
          <div
            className={classNames(styles.inputText, {
              [styles.inputTextWithStartIcon]: !!iconStart,
            })}
          >
            {selectedOption[labelProperty]}
          </div>
          <div
            className={classNames(styles.arrow, {
              [styles.arrowHidden]: !showChevron,
              [styles.arrowReversed]: optionsVisible,
            })}
          >
            <ArrowDown />
          </div>
        </div>
      )}

      {optionsVisible && (
        <PopoverPanel
          childrenAtleastFullWidth={true}
          targetRef={containerNode}
          targetElementPos={targetElementPos}
          childrenElementPos={childrenElementPos}
          paddingVertical={paddingVertical}
          paddingHorizontal={paddingHorizontal}
          onBackdropClick={() => {
            setOptionsVisible(false);
          }}
        >
          <div className={classNames(styles.dropdownPanel, containerClassName)} tabIndex={1}>
            {renderPanel
              ? renderPanel({
                  classes: classesAsProps,
                  close: () => handleSelection(undefined),
                })
              : allOptions.map(item => {
                  const isSelected = item[idProperty] === selectedOption[idProperty];

                  if (!showSelectedOption && isSelected) {
                    return null;
                  }

                  return renderRow ? (
                    renderRow({
                      item,
                      isSelected,
                      classes: classesAsProps,
                      onClick: item => handleSelection(item),
                      close: () => handleSelection(undefined),
                    })
                  ) : (
                    <div
                      className={classNames(styles.option, {
                        [styles.optionDanger]: item.danger,
                      })}
                      key={item[idProperty]}
                      onClick={() => handleSelection(item)}
                    >
                      {item.icon ? (
                        <div className={styles.iconContainer}>{item.icon}</div>
                      ) : (
                        <div className={styles.iconContainerEmpty}></div>
                      )}
                      <div className={styles.titleContainer}>{item[labelProperty]}</div>
                    </div>
                  );
                })}
          </div>
        </PopoverPanel>
      )}
    </div>
  );
};

export default ButtonDropDown;
