import FileTypeIcon from 'pages/MyFiles/FileTypeIcon';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-new.svg';
import { ReactComponent as NewMaterialIcon } from 'assets/icons/newMaterial.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash2.svg';
import Button from 'components/common/Button/Button';
import useConfirmDialog from 'components/common/ConfirmationModal/useConfirmDialog';
import LoaderWithFade from 'components/common/LoaderWithFade/LoaderWithFade';
import NothingFound from 'components/common/NothingFound/NothingFound';
import OptionButton from 'components/common/OptionButton/OptionButton';
import useSelectedIds from 'hooks/generic/useSelectedIds';
import requestAPI from 'utils/apiHandler';

import styles from './DocumentsList.module.scss';

interface DocumentI {
  _id: string;
  title: string;
  created_at: string;
  updated_at: string;
}

const DocumentsList: React.FC = () => {
  const router = useHistory();
  const [documents, setDocuments] = useState<DocumentI[]>([]);
  const { t } = useTranslation();
  const { ids, addId, removeId } = useSelectedIds([]);
  const [sortColumn, setSortColumn] = useState<'title' | 'created' | 'updated'>('updated');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const { deleteConfirmation } = useConfirmDialog();
  const [isLoading, setIsLoading] = useState(false);

  const fetchDocuments = async () => {
    setIsLoading(true);
    const responseDocuments = await requestAPI('/library/material');
    setDocuments(responseDocuments);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const createNewDocument = async () => {
    try {
      const { _id } = await requestAPI({
        url: '/myfiles',
        method: 'POST',
        data: {
          title: 'untitled',
          type: 'material',
          content: [],
        },
      });

      router.push(`/editor/${_id}`, { from: router.location.pathname });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteDocument = async (id: string) => {
    await requestAPI({
      url: `myfiles/${id}`,
      method: 'DELETE',
    });

    fetchDocuments();
  };

  const documentsSorted = _.orderBy(
    documents,
    [
      item => {
        if (sortColumn === 'title') {
          return item.title.toLowerCase();
        }
        if (sortColumn === 'created') {
          return DateTime.fromISO(item.created_at).toUnixInteger();
        }
        if (sortColumn === 'updated') {
          return DateTime.fromISO(item.updated_at).toUnixInteger();
        }
      },
    ],
    [sortOrder]
  );

  const getHeaderCell = (key: 'title' | 'created' | 'updated', title: string) => {
    return (
      <th className="text-label">
        <div
          className={styles.thContainer}
          onClick={() => {
            setSortColumn(key);
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
          }}
        >
          {title}
          <div className={styles.arrowIconContainer}>
            <ArrowRightIcon
              className={styles.arrowDownIcon}
              style={{ rotate: sortOrder === 'asc' ? '90deg' : '270deg' }}
            />
          </div>
        </div>
      </th>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>{t('common:nav.documents')}</div>

        <Button
          startIcon={<NewMaterialIcon />}
          label={t('documents:newDocument')}
          className="t-btn-create-document"
          onClick={createNewDocument}
        />
      </div>
      {documentsSorted.length > 0 && (
        <table className={styles.table}>
          <thead>
            <tr>
              {getHeaderCell('title', t('documents:documentList.name'))}
              {getHeaderCell('created', t('documents:documentList.created'))}
              {getHeaderCell('updated', t('documents:documentList.modified'))}
            </tr>
          </thead>
          <tbody>
            {documentsSorted.map(item => {
              return [
                <tr
                  key={item._id}
                  className={classNames({
                    [styles.itemSelected]: ids.includes(item._id),
                  })}
                  onClick={() => {
                    router.push(`/editor/${item._id}`, { from: router.location.pathname });
                  }}
                >
                  <td>
                    <div style={{ display: 'flex' }}>
                      <div className={styles.iconContainer}>
                        <FileTypeIcon type="material" />
                      </div>
                      <div className={styles.title}>{item.title}</div>
                    </div>
                  </td>
                  <td className={styles.text}>
                    {DateTime.fromISO(item.created_at).toLocaleString({
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </td>
                  <td className={styles.text} style={{ position: 'relative' }}>
                    {DateTime.fromISO(item.updated_at).toLocaleString({
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}

                    <div
                      className={styles.optionButtonStyles}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <OptionButton
                        hoverCircleVariant={'secondary'}
                        dotsVariant={'secondary'}
                        onOptionsVisibleChange={isVisible => {
                          isVisible ? addId(item._id) : removeId(item._id);
                        }}
                        options={[
                          {
                            id: 'delete',
                            label: t('common:delete'),
                            icon: <TrashIcon />,
                            danger: true,
                          },
                        ]}
                        onClick={async id => {
                          if (id === 'delete') {
                            if (
                              await deleteConfirmation({
                                title: t('documents:deleteDocument'),
                                message: `${t('documents:areYouSureYouWantToDeleteDocument')} ${
                                  item.title
                                }`,
                              })
                            ) {
                              deleteDocument(item._id);
                            }
                          }
                        }}
                      />
                    </div>
                  </td>
                </tr>,
                <tr key={`${item._id}_`} className={styles.spacerRow}>
                  <td className={styles.spacerCell} colSpan={99}></td>
                </tr>,
              ];
            })}
          </tbody>
        </table>
      )}
      {documents.length === 0 && !isLoading && (
        <NothingFound label={t('documents:documentListIsEmpty')} />
      )}
      {isLoading && (
        <center>
          <LoaderWithFade />
        </center>
      )}
    </div>
  );
};

export default React.memo(DocumentsList);
