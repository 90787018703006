const createScript = (id, scriptText, src, async) => {
  const script = document.createElement('script');

  if (id) {
    script.setAttribute('id', id);
  }

  if (src) {
    script.setAttribute('src', src);
  }

  if (async) {
    script.async = true;
  }

  if (scriptText) {
    script.appendChild(document.createTextNode(scriptText));
  }

  try {
    document.head.appendChild(script);
  } catch (e) {
    script.text = scriptText;
    document.head.appendChild(script);
  }
};

export default createScript;
