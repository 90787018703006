import React from 'react';

import classnames from 'classnames';
import ReactDOM from 'react-dom';

import { ReactComponent as ChevronIcon } from 'assets/icons/chevron7.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close2.svg';
import LoaderWithFade from 'components/common/LoaderWithFade/LoaderWithFade';

import Button from '../Button/Button';
import './ModalDialog.scoped.scss';

// const modalElement = document.getElementById('modal');

interface Props {
  open: boolean;
  error?: string;
  onClose?: () => void;
  title?: React.ReactNode;
  actions?: (React.ReactNode | React.ComponentProps<typeof Button>)[];
  actionsJustifyContent?: string;
  isLoadingContent?: boolean;
  isLoadingActions?: boolean;
  hasContentPadding?: boolean;
  onBackBtnClick?: () => void;
  className?: string;
  allowBackdropNav?: boolean;
  headerRightButtons?: {
    icon: any;
    onClick: () => void;
  }[];
}

const ModalDialog: React.FC<Props> = ({
  open,
  error = '',
  onClose,
  children,
  title = null,
  actions = [],
  isLoadingContent = false,
  isLoadingActions = false,
  hasContentPadding = true,
  actionsJustifyContent = 'center',
  onBackBtnClick,
  className,
  allowBackdropNav,
  headerRightButtons = [],
}) => {
  if (!open) {
    return null;
  }

  if (onClose) {
    headerRightButtons.push({
      icon: <CloseIcon />,
      onClick: onClose,
    });
  }

  return ReactDOM.createPortal(
    <div
      className={classnames('modal-dialog', className, {
        backdropAllowNav: allowBackdropNav,
      })}
    >
      <div className={'backdrop'} onClick={onClose} />

      {isLoadingContent && (
        <div className="loaderContainer">
          <LoaderWithFade fade={true} />
        </div>
      )}

      <div
        style={{ visibility: isLoadingContent ? 'hidden' : 'visible' }}
        className="modal"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className="modalHeader">
          {onBackBtnClick && (
            <div className="back-btn" onClick={onBackBtnClick}>
              <ChevronIcon />
            </div>
          )}

          {title && <div className="title">{title}</div>}

          <div className="headerButtons">
            {headerRightButtons.map((item, index) => (
              <div className="headerButton" onClick={item.onClick} key={index}>
                {item.icon}
              </div>
            ))}
          </div>
        </div>

        <div
          className={classnames('content', { contentPadding: hasContentPadding })}
          style={{
            height: isLoadingContent ? '1px' : 'auto',
            visibility: isLoadingContent ? 'hidden' : 'visible',
            opacity: isLoadingActions ? '0.5' : '1',
            pointerEvents: isLoadingActions ? 'none' : 'auto',
          }}
        >
          {children}
        </div>

        {error.length > 0 && <div className="error">{error}</div>}

        {actions.length > 0 && (
          <div
            className="actions"
            style={{
              justifyContent: actionsJustifyContent,
              opacity: isLoadingActions ? '0.5' : '1',
              pointerEvents: isLoadingActions ? 'none' : 'auto',
            }}
          >
            {actions.map((entry, index) => {
              if (React.isValidElement(entry)) {
                return entry;
              }

              const { onClick, variant, label, fullWidth, startIcon, size, className, disabled } =
                entry as React.ComponentProps<typeof Button>;

              const showLoader = isLoadingActions && actions.length === 1;

              return (
                <Button
                  key={`${label}_${index}`}
                  startIcon={startIcon}
                  isLoading={showLoader}
                  disabled={disabled}
                  size={size}
                  variant={variant}
                  fullWidth={fullWidth}
                  label={label}
                  onClick={onClick}
                  className={`actionButtonStyles ${className}`}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>,
    document.getElementById('modal')
  );
};

export default ModalDialog;
