import React from 'react';

import { useTranslation } from 'react-i18next';

import ModalDialog from 'components/common/ModalDialog/ModalDialog';

import { ConfirmDefaultType } from './confirmationModalContextProvider';

interface Props {
  resolver: React.MutableRefObject<(value: boolean | PromiseLike<boolean>) => void>;
  onHide: () => void;
  allowClosing: boolean;
  show: boolean;
  content: ConfirmDefaultType;
}

const ConfirmationModal: React.FC<Props> = ({ resolver, onHide, allowClosing, show, content }) => {
  const handleOk = () => {
    resolver.current && resolver.current(true);
    onHide();
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    onHide();
  };

  const { t } = useTranslation();
  return (
    <ModalDialog
      onClose={allowClosing ? onHide : null}
      open={show}
      title={content.title}
      actionsJustifyContent="center"
      actions={[
        {
          variant: 'alt',
          label: t('common:cancel'),
          onClick: handleCancel,
        },
        {
          label: t('common:ok'),
          onClick: handleOk,
        },
      ]}
    >
      <div>{content.message}</div>
    </ModalDialog>
  );
};

export default ConfirmationModal;
