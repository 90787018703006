import useUser from 'redux/authentication/hooks/useUser';

import styles from './FallbackAvatar.module.scss';

export const colors = [
  '#82DBDB',
  '#43C0A2',
  '#90D13C',
  '#3598D0',
  '#435BB0',
  '#E3C65D',
  '#EEB340',
  '#DE81A2',
  '#DB4E81',
  '#AA71F4',
  '#9F5EA9',
  '#D53A3A',
  '#9F5EA9',
];

const FallbackAvatar: React.FC = () => {
  const user = useUser();
  const username = `${user.first_name} ${user.last_name}`;
  // color depends on first letter, last letter and length of username
  // always returns same color for same name
  const pickColorFromArray = () => {
    const numberBasedOnCharCodes = String(
      username.charCodeAt(0) + username.charCodeAt(username.length - 1) + username.length
    );
    const numberLastChar = numberBasedOnCharCodes.slice(-1);
    return colors[colors.length - Number(numberLastChar) - 1];
  };
  const backgroundColor = pickColorFromArray();
  if (username?.length)
    return (
      <div className={styles.container} style={{ backgroundColor }}>
        {username[0].toUpperCase()}
      </div>
    );
};

export default FallbackAvatar;
