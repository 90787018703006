import html2pdf from 'html2pdf.js';

import requestAPI from './apiHandler';
import trimLeadingSlash from './trimLeadingSlash';

export const downloadFile = (url: string, title: string) => {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  getFileUrlObject(url).then(blobUrl => {
    anchor.href = blobUrl;
    anchor.download = title;
    anchor.click();
    window.URL.revokeObjectURL(blobUrl);
  });
};

const fetchImage = async (url: string) => {
  const response = await fetch(`${window.ENV.REACT_APP_BACKEND_URL}${url}`, {
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem('tokenNewApi')}`,
    }),
  });
  const blob = await response.blob();

  const imageObject = window.URL.createObjectURL(blob);

  return imageObject;
};

const imageCache: { [key: string]: string } = {};
export const getFileUrlObject = async (imageUrl: string) => {
  if (imageCache[imageUrl]) {
    return imageCache[imageUrl];
  }

  const imageObject = await fetchImage(trimLeadingSlash(imageUrl));

  imageCache[imageUrl] = imageObject;
  return imageObject;
};

export const getFileUrlObjectById = async (fileId: string) => {
  if (imageCache[fileId]) {
    return imageCache[fileId];
  }

  const imageObject = await fetchImage(`files-by-id/${fileId}`);

  imageCache[fileId] = imageObject;
  return imageObject;
};

export const getImageDimensions = async (imageObject: string) => {
  return new Promise<{ height: number; width: number }>(resolve => {
    const img = new Image();

    img.onload = function () {
      resolve({ height: img.height, width: img.width });
    };

    img.src = imageObject;
  });
};
export const uploadFile = async file => {
  const formdata = new FormData();
  formdata.append('file', file, encodeURI(file.name));
  const response = await requestAPI({
    url: '/upload',
    method: 'POST',
    body: formdata,
  });
  return response;
};

export const getImageSizeByUrl = async url => {
  const img = new Image();
  img.src = url;
  await img.decode();
  return { width: img.naturalWidth, height: img.naturalHeight };
};

export const getYoutubeVideoTitle = async url => {
  try {
    const response = await fetch(
      `http://www.youtube.com/oembed?url=${encodeURIComponent(url)}&format=json`
    );
    const data = await response.json();
    return data.title;
  } catch (err) {
    console.error('error getting video title', err);
    return 'Title';
  }
};

export const downloadHtmlAsPDF = async (
  mainElement: HTMLElement,
  ignoreElementsWithClasses?: string[],
  filename = 'download.pdf'
) => {
  if (!mainElement) {
    return;
  }

  // Get the dimensions of the element
  const elementRect = mainElement.getBoundingClientRect();

  // Specify PDF dimensions based on the element's size
  const pdfWidth = elementRect.width;
  const pdfHeight = elementRect.height;

  // Clone the content element to avoid modifying the original
  const clonedContent = mainElement.cloneNode(true) as HTMLElement;

  // Remove the elements having classes found in ignoreElementsWithClasses before conversion to PDF
  if (ignoreElementsWithClasses.length) {
    const elementsToIgnore = clonedContent.querySelectorAll(ignoreElementsWithClasses.join(', '));
    elementsToIgnore.forEach(ignoredElement => {
      ignoredElement.parentNode.removeChild(ignoredElement);
    });
  }

  const pdfOptions = {
    filename,
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { scale: 3, useCORS: true },
    jsPDF: { unit: 'px', format: [pdfWidth, pdfHeight], orientation: 'portrait' },
  };

  // Use html2pdf to create and download the PDF
  html2pdf()
    .set({
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    })
    .from(clonedContent)
    .set(pdfOptions)
    .save();
};
