import { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import ReactDOM from 'react-dom';

import useEffectAfterMount from 'hooks/generic/useEffectAfterMount';
import useWindowSize from 'hooks/generic/useWindowSize';

import PopoverArrow from './PopoverArrow';
import PopoverBackdrop from './PopoverBackdrop/PopoverBackdrop';
import helper, {
  Box,
  HorizontalAnchorsAdjusted,
  PositionHorizontal,
  PositionVertical,
  VerticalAnchorsAdjusted,
} from './helper';

let activePopoverCount = 0;

export const positions = [
  'top.left',
  'top.center',
  'top.right',
  'center.left',
  'center.center',
  'center.right',
  'bottom.left',
  'bottom.center',
  'bottom.right',
] as const;

export type PositionString = (typeof positions)[number];

interface Props {
  backdropOnTarget?: boolean;
  targetRef: React.MutableRefObject<any>;
  targetElementPos?: PositionString;
  childrenElementPos?: PositionString;
  childrenAtleastFullWidth?: boolean;
  childrenContainerClassName?: string;
  onBackdropClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  paddingVertical?: number;
  paddingHorizontal?: number;
  showArrow?: boolean;
  arrowColor?: string;
  backdropVisible?: boolean;
  updateKey?: string;
}

const PopoverPanel: React.FC<Props> = props => {
  const {
    children,
    targetRef,
    targetElementPos = 'bottom.left',
    childrenElementPos = 'top.left',
    onBackdropClick = () => null,
    childrenAtleastFullWidth,
    childrenContainerClassName,
    paddingVertical = 0,
    paddingHorizontal = 0,
    backdropOnTarget = true,
    showArrow = false,
    arrowColor = '#000000',
    backdropVisible = true,
    updateKey,
  } = props;

  const targetElementPosVertical = targetElementPos.split('.')[0] as PositionVertical;
  const targetElementPosHorizontal = targetElementPos.split('.')[1] as PositionHorizontal;

  const childrenElementPosVertical = childrenElementPos.split('.')[0] as PositionVertical;
  const childrenElementPosHorizontal = childrenElementPos.split('.')[1] as PositionHorizontal;

  const [isMeasurmentRender, setIsMeasurmentRender] = useState(true);
  const [targetRect, setTargetRect] = useState<Box>(null);
  const [childrenRect, setChildrenRect] = useState<Box>(null);
  const [verticalAnchorsAdjusted, setVerticalAnchorsAdjusted] =
    useState<VerticalAnchorsAdjusted>(null);
  const [horizontalAnchorsAdjusted, setHorizontalAnchorsAdjusted] =
    useState<HorizontalAnchorsAdjusted>(null);

  useEffect(() => {
    activePopoverCount++;
    return () => {
      activePopoverCount--;
    };
  }, []);

  const zIndexBackdrop = activePopoverCount + 105;
  const zIndexChildren = activePopoverCount + 106;

  useEffect(() => {
    if (updateKey) {
      setPosition(true);
    }
  }, [updateKey]);

  const childrenRef = useRef(null);

  const setPosition = (isUpdate = false) => {
    const childrenBox: DOMRect = childrenRef?.current?.getBoundingClientRect();
    const targetBoxRect: DOMRect = targetRef?.current?.getBoundingClientRect();

    const targetBox = helper.addPadding(targetBoxRect, paddingVertical, paddingHorizontal);

    const childrenHeight = isUpdate
      ? childrenRef.current.childNodes[0].scrollHeight
      : childrenBox.height;
    const childrenWidth = isUpdate
      ? childrenRef.current.childNodes[0].scrollWidth
      : childrenBox.width;

    setIsMeasurmentRender(false);
    setTargetRect(targetBox);
    setChildrenRect({ ...childrenBox, height: childrenHeight, width: childrenWidth });

    const verticalAnchorPointsAdjusted = helper.getVerticalAnchorPointsAdjusted(
      targetBox,
      childrenHeight,
      targetElementPosVertical,
      childrenElementPosVertical
    );
    const horizontalAnchorPointsAdjusted = helper.getHorizontalAnchorPointsAdjusted(
      targetBox,
      childrenWidth,
      targetElementPosHorizontal,
      childrenElementPosHorizontal
    );

    setVerticalAnchorsAdjusted(verticalAnchorPointsAdjusted);
    setHorizontalAnchorsAdjusted(horizontalAnchorPointsAdjusted);
  };

  const windowSize = useWindowSize();

  useEffectAfterMount(() => {
    setPosition(true);
  }, [windowSize.height, windowSize.width]);

  const setChildrenRef = node => {
    childrenRef.current = node;

    if (node && isMeasurmentRender) {
      setPosition(false);
      if (node.firstChild && backdropOnTarget) {
        node.firstChild.focus();
      }
    }
  };

  const styles = helper.getCssStylesByAnchors(
    verticalAnchorsAdjusted,
    horizontalAnchorsAdjusted,
    isMeasurmentRender,
    targetRect,
    childrenAtleastFullWidth,
    zIndexChildren
  );

  const arrowStyles = helper.getArrowStyles(
    styles,
    targetRect,
    childrenRect,
    arrowColor,
    isMeasurmentRender
  );

  useEffect(() => {
    const keys = [33, 34, 38, 40];
    const preventDefault = e => e.preventDefault();
    const preventKeys = e => keys.includes(e.keyCode) && preventDefault(e);

    targetRef.current.addEventListener('wheel', preventDefault, { passive: false });
    targetRef.current.addEventListener('touchmove', preventDefault, { passive: false });
    targetRef.current.addEventListener('keydown', preventKeys, false);
    return () => {
      targetRef.current?.removeEventListener('wheel', preventDefault, { passive: false });
      targetRef.current?.removeEventListener('touchmove', preventDefault, { passive: false });
      targetRef.current?.removeEventListener('keydown', preventKeys, false);
    };
  }, []);

  return ReactDOM.createPortal(
    <div>
      {backdropVisible && (
        <PopoverBackdrop
          backdropOnTarget={backdropOnTarget}
          onBackdropClick={onBackdropClick}
          targetRef={targetRef}
          zIndexBackdrop={zIndexBackdrop}
        />
      )}
      {showArrow && <PopoverArrow arrowStyles={arrowStyles} />}
      <div
        tabIndex={1}
        className={classNames(['children-container', childrenContainerClassName])}
        ref={setChildrenRef}
        style={styles}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </div>
    </div>,
    document.getElementById('popover')
  );
};

export default PopoverPanel;
