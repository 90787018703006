import * as React from 'react';

import messages from './messages';
import Rules, { IRuleDefinitionValues } from './rules';

type ValidationTypes =
  | 'string'
  | 'min:'
  | 'max:'
  | 'required'
  | 'nullable'
  | 'array'
  | 'integer'
  | 'regex:';

class ReactValidator {
  rules = new Rules();

  errors = {};

  //TODO: Rule can be function
  set(
    values: object,
    rules: Record<string, ValidationTypes[] | string[]>,
    messages: Record<string, string>
  ) {
    const keys = Object.keys(rules);
    this.errors = {};
    keys.forEach(obj => {
      let value = values;
      const props = obj.split('.');
      let propName = '';
      props.forEach(prop => {
        value = value[prop];
        propName = prop;
      });
      this.checkRules(value, rules[obj], propName, obj, messages);
    });
  }

  checkRules(
    value: object,
    rules: string[],
    propName: string,
    fullProp: string,
    customMessages: object = {}
  ) {
    const length = rules.length;
    for (let i = 0; i < length; i++) {
      const validation = this.callValidator(rules[i], value);
      if (!validation.isValid) {
        let validationMessage = null;
        // check if custom message is present and return it
        if (customMessages[`${fullProp}.${validation.rule}`]) {
          validationMessage = customMessages[`${fullProp}.${validation.rule}`];
        }
        if (!customMessages[`${fullProp}.${validation.rule}`]) {
          validationMessage = this.formatMessage(validation.rule, {
            attribute: fullProp,
            value1: validation.mainValue,
            value2: validation.secValue,
          });
        }
        if (validationMessage) {
          if (!this.errors[fullProp]) {
            this.errors[fullProp] = [];
          }
          this.errors[fullProp].push(validationMessage);
        }
      }

      if (validation.skip) {
        i = length;
      }
    }
  }

  formatMessage(rule, { attribute, value1, value2 }) {
    let message = `${messages[rule]}`.replace(':attribute', attribute);
    if (value2) {
      value2.forEach((str, key) => {
        message = message.replace(`:value_${key}`, str);
      });
    }

    return message.replace('value', value1);
  }

  callValidator(specificRule, value) {
    const rules = specificRule.split(':');
    const rule = rules[0];
    let secValueUpdate = null;

    if (rule !== 'regex' && rules[1]) {
      secValueUpdate = rules[1].split(',');
    }

    if (rule === 'regex') {
      secValueUpdate = [rules[1]];
    }

    const val: IRuleDefinitionValues = {
      mainValue: value,
      secValue: secValueUpdate,
    };
    return { ...this.rules[rule](val), ...val, rule };
  }
}

const localValidator = new ReactValidator();

export function validator(
  values: object,
  rules: Record<string, ValidationTypes[] | string[]>,
  messages?: Record<string, string>
) {
  localValidator.set(values, rules, messages);
}

export function isValid() {
  return Object.keys(localValidator.errors).length === 0;
}

export function errors() {
  return localValidator.errors;
}

export const ValidationError: React.FC<{ prop }> = ({ prop }) => {
  return localValidator.errors[prop];
};
