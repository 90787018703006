import BillingService from 'pages/Billing/BillingService';

import { memo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button/Button';
import useUser from 'redux/authentication/hooks/useUser';

import styles from './Billing.module.scss';

const Billing: React.FC = () => {
  const user = useUser();
  const { getPortalLink } = BillingService();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const navigateToPortal = async () => {
    setIsLoading(true);

    const flow = user.in_trial ? 'add-payment' : '';
    const link = await getPortalLink(user.email, flow);
    if (link) {
      window.location.href = link;
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {/* <div className={styles.text}> Next billing date: </div>
      <div className={styles.value}> 15.02.2024** </div> */}
      <div style={{ flexGrow: '1' }}></div>
      <Button
        label={isLoading ? t('login:loadingCustomerPortal') : t('login:manageBilling')}
        variant="secondary"
        className={styles.manageBillingBtn}
        onClick={navigateToPortal}
      />
    </div>
  );
};

export default memo(Billing);
