import { useEffect, useState } from 'react';

import { getFileUrlObjectById } from 'utils/fileHelper';

function useImageById(id: string) {
  const [imageUrlObject, setImageUrlObject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchImage = async () => {
    setIsLoading(true);
    const file = await getFileUrlObjectById(id);
    setImageUrlObject(file);
    setIsLoading(false);
  };

  useEffect(() => {
    if (id) {
      fetchImage();
    }
  }, [id]);

  if (!id) {
    return [null, false] as const;
  }

  return [imageUrlObject, isLoading] as const;
}

export default useImageById;
