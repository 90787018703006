import React from 'react';

import { useTranslation } from 'react-i18next';

import ModalDialog from 'components/common/ModalDialog/ModalDialog';

import { ConfirmDeleteType } from '../confirmationModalContextProvider';
import styles from './DeleteModal.module.scss';

interface Props {
  resolver: React.MutableRefObject<(value: boolean | PromiseLike<boolean>) => void>;
  onHide: () => void;
  show: boolean;
  content: ConfirmDeleteType;
}

const DeleteModal: React.FC<Props> = ({ resolver, onHide, show, content }) => {
  const handleOk = () => {
    resolver.current && resolver.current(true);
    onHide();
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    onHide();
  };

  const { t } = useTranslation();
  return (
    <ModalDialog
      onClose={onHide}
      open={show}
      title={content.title || t('common:deleteConfirmation')}
      actions={[
        {
          size: 'small',
          variant: 'alt',
          label: t('common:cancel'),
          onClick: handleCancel,
        },
        {
          size: 'small',
          variant: 'decline',
          label: t('common:delete'),
          onClick: handleOk,
        },
      ]}
    >
      <div className={styles.wrapper}>
        <div className={styles.message}>
          {content?.message || t('common:areYouSureToDelete').replace('?', '')}
        </div>

        {content?.targetElement && (
          <div className={styles.targetElement}>{content.targetElement}?</div>
        )}
      </div>
    </ModalDialog>
  );
};

export default DeleteModal;
