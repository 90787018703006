import { DateTime } from 'luxon';
import { TFunction } from 'react-i18next';

import { MyFileI } from './interfaces';

export const getMyFileTitle = (item: MyFileI) => {
  return item.type === 'folder' ? item.title : item.title; //.substring(14);
};

export const fileExtensionFileTypes = {
  png: 'image',
  image: 'image',
  jpeg: 'image',
  jpg: 'image',
  pdf: 'pdf',
};

export const getMyFileIconName = (item: MyFileI) => {
  switch (item.type) {
    case 'folder':
      return 'folder';
    case 'material':
      return 'material';
    case 'whiteboard':
      return 'whiteboard';
    default:
      return item.title.split('.').at(-1);
  }
};

export const iterateMyFiles = (
  items: MyFileI[],
  callback: (item: MyFileI, parents: MyFileI[]) => void,
  parents: MyFileI[] = []
) => {
  items.forEach(item => {
    callback(item, parents);
    const childs = item.childs || [];
    if (childs.length) {
      iterateMyFiles(item.childs, callback, [...parents, item]);
    }
  });
};

export const findMyFileById = (myFiles: MyFileI[], id: string) => {
  let selectedItem: MyFileI;
  let selectedItemParents: MyFileI[];
  iterateMyFiles(myFiles, (item, parents) => {
    if (item._id === id) {
      selectedItem = item;
      selectedItemParents = parents;
    }
  });
  return { selectedItem, selectedItemParents };
};
