export default {
  required: 'The :attribute filed is required',
  string: 'The :attribute is not in appropriate format',
  integer: 'The :attribute needs to be number',
  array: 'The :attribute needs to be array',
  min: 'The :attribute is smaller then :value_0',
  max: 'The :attribute is larger then :value_0',
  between: 'The :attribute needs to be between :value_0 and :value_1',
  in: 'The :attribute needs to be between :value_0 and :value_1',
  required_if: '',
  regex: 'The :attribute has characters that are not allowed',
  email: 'Email is not valid',
  password: 'Password is not valid',
};
