import React, { Suspense, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

const Terms_en = React.lazy(() => import('pages/Policies/Terms/Terms_en'));
const Terms_de = React.lazy(() => import('pages/Policies/Terms/Terms_de'));

const LegalDocs: React.FC = () => {
  const { i18n } = useTranslation();

  const language = i18n.resolvedLanguage;

  return <Suspense fallback={<i></i>}>{language === 'de' ? <Terms_de /> : <Terms_en />}</Suspense>;
};

export default React.memo(LegalDocs);
