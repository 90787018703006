import { createSlice } from '@reduxjs/toolkit';

const AppSlice = createSlice({
  name: 'app',
  initialState: { devMode: false },
  reducers: {
    toogleDevMode: state => {
      state.devMode = !state.devMode;
    },
  },
});

export const { reducer } = AppSlice;
export default AppSlice.actions;
