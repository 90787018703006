import React from 'react';

export interface FieldErrorParserInterface {
  errors?: { property: string; error: string }[];
  property?: string;
  label?: string;
  toolTip?: React.ReactNode;
  formIsTouched?: boolean;
  fieldIsTouched?: boolean;
  labelMarginTop?: boolean;
  form?: {
    errors?: { property: string; error: string }[];
    formIsTouched?: boolean;
  };
}
export interface FieldErrorParserResultInterface {
  showErrors: boolean;
  visibleErrors: {
    property: string;
    error: string;
  }[];
  firstError: string;
  showFirstError: string;
}

export default function fieldErrorParser(
  data: FieldErrorParserInterface = {},
  fieldIsTouched = true
): FieldErrorParserResultInterface {
  const formIsTouched = data?.form?.formIsTouched ?? data?.formIsTouched;
  const errors = data?.form?.errors ?? data?.errors;

  const showErrors = fieldIsTouched || formIsTouched;
  const visibleErrors = errors?.filter(err => err.property === data.property);
  const firstError = visibleErrors && visibleErrors.length ? visibleErrors[0].error : '';
  const showFirstError = showErrors && firstError;

  return {
    showErrors,
    visibleErrors,
    firstError,
    showFirstError,
  };
}
