import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export type RawFileWithController = { id: string; file: File; controller: AbortController };
export type FileUploadItem = {
  id: string;
  name: string;
  size: string;
  status: 'inProgress' | 'succeeded' | 'failed';
  statusMessage: string;
};

const initialState: {
  rawFiles: RawFileWithController[];
  files: FileUploadItem[];
  failedUploadsCount: number;
  successfulUploadsCount: number;
  showProgressPopup: boolean;
} = {
  rawFiles: [],
  files: [],
  failedUploadsCount: 0,
  successfulUploadsCount: 0,
  showProgressPopup: false,
};

const FileUploadsProgress = createSlice({
  name: 'fileUploads',
  initialState,
  reducers: {
    setRawFiles(state, action: PayloadAction<RawFileWithController[]>) {
      if (action.payload === null) return;
      state.rawFiles = action.payload;
      state.showProgressPopup = true;
    },
    setUploadFiles(state, action) {
      if (action.payload === null) return;
      state.files = action.payload;
      state.showProgressPopup = true;
    },
    setSuccessfulUpload(state, action) {
      state.files = state.files.map(file => {
        if (file.id === action.payload.id) {
          return {
            ...file,
            status: 'succeeded',
          };
        }

        return file;
      });

      state.showProgressPopup = true;
      state.failedUploadsCount = state.files.filter(file => file.status === 'failed').length;
      state.successfulUploadsCount = state.files.filter(file => file.status === 'succeeded').length;
    },
    setFailedUpload(state, action) {
      state.files = state.files.map(file => {
        if (file.id === action.payload.id) {
          return {
            ...file,
            status: 'failed',
            statusMessage: action.payload.message,
          };
        }

        return file;
      });

      state.rawFiles = state.rawFiles.map(rawFile => {
        if (rawFile.id === action.payload.id) {
          return { ...rawFile, controller: new AbortController() };
        }
        return rawFile;
      });

      state.showProgressPopup = true;
      state.failedUploadsCount = state.files.filter(file => file.status === 'failed').length;
      state.successfulUploadsCount = state.files.filter(file => file.status === 'succeeded').length;
    },
    setUploadInProgress(state, action) {
      state.files = state.files.map(file => {
        if (file.id === action.payload.id) {
          return {
            ...file,
            status: 'inProgress',
          };
        }

        return file;
      });

      state.showProgressPopup = true;
    },
    hideUploadsPopup(state) {
      state.showProgressPopup = false;
    },
  },
});

export const { reducer } = FileUploadsProgress;
export default FileUploadsProgress.actions;
