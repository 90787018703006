import posthog, { PostHog } from 'posthog-js';

import { getState } from 'redux/store';

let posthogInstance: PostHog;

export const initializePosthog = () => {
  if (!window.ENV.REACT_APP_PUBLIC_POSTHOG_KEY) {
    return;
  }

  posthogInstance = posthog.init(window.ENV.REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: window.ENV.REACT_APP_PUBLIC_POSTHOG_HOST,
    session_recording: {
      maskAllInputs: true,
      inlineStylesheet: true,
    },
    enable_recording_console_log: true,
    opt_in_site_apps: true,
  }) as PostHog;

  const email = getState().auth.user?.email;
  if (email) {
    posthogInstance.identify(email, { email });
  }
};

export const initializePosthogIfCookieBannerAccepted = () => {
  if (posthogInstance) {
    return;
  }
  const cookieBannerIsAccepted = localStorage.getItem('cookieBannerIsAccepted') === 'true';
  if (cookieBannerIsAccepted) {
    initializePosthog();
  }
};

export const getPosthogInstance = () => {
  return posthogInstance;
};

export default null;
