import React, { useContext, useRef, useState } from 'react';

import ConfirmationModal from './ConfirmationModal';
import DeleteModal from './DeleteModal/DeleteModal';
import useModalShow from './useModalShow';

type ModalType = 'delete' | 'default';

export type ConfirmDeleteType = {
  title?: string;
  message?: string | JSX.Element;
  targetElement?: string | JSX.Element;
};
export type ConfirmDefaultType = {
  title: string;
  message: string | JSX.Element;
  canDissmiss?: boolean;
};

type ModalContextType = {
  showDeleteConfirmation: (content: ConfirmDeleteType) => Promise<boolean>;
  showDefaultConfirmation: (content: ConfirmDefaultType) => Promise<boolean>;
};

const ConfirmationModalContext = React.createContext<ModalContextType>(null);

const ConfirmationModalContextProvider: React.FC = props => {
  const { setShow, show, onHide } = useModalShow();
  const [content, setContent] = useState<ConfirmDeleteType | ConfirmDefaultType>(null);
  const [modalType, setModalType] = useState<ModalType>(null);

  const resolver = useRef<(value: boolean | PromiseLike<boolean>) => void>();

  const handleDeleteShow = (newContent: ConfirmDeleteType): Promise<boolean> => {
    setContent(newContent);
    setModalType('delete');
    setShow(true);
    return new Promise(resolve => {
      resolver.current = resolve;
    });
  };
  const handleDefaultShow = (newContent: ConfirmDefaultType): Promise<boolean> => {
    setContent(newContent);
    setModalType('default');
    setShow(true);
    return new Promise(resolve => {
      resolver.current = resolve;
    });
  };

  const modalContext: ModalContextType = {
    showDeleteConfirmation: handleDeleteShow,
    showDefaultConfirmation: handleDefaultShow,
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {props.children}

      {content && modalType === 'delete' && (
        <DeleteModal
          content={content as ConfirmDeleteType}
          onHide={onHide}
          resolver={resolver}
          show={show}
        />
      )}
      {content && modalType === 'default' && (
        <ConfirmationModal
          content={content as ConfirmDefaultType}
          onHide={onHide}
          allowClosing={(content as ConfirmDefaultType).canDissmiss}
          resolver={resolver}
          show={show}
        />
      )}
    </ConfirmationModalContext.Provider>
  );
};

const useConfirmationModalContext = (): ModalContextType => useContext(ConfirmationModalContext);

export { useConfirmationModalContext };

export default ConfirmationModalContextProvider;
