import classNames from 'classnames';

import styles from './PopoverBackdrop.module.scss';

interface Props {
  backdropOnTarget?: boolean;
  onBackdropClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  targetRef: React.MutableRefObject<any>;
  zIndexBackdrop: number;
}

const PopoverBackdrop: React.FC<Props> = ({
  onBackdropClick,
  backdropOnTarget,
  targetRef,
  zIndexBackdrop,
}) => {
  const zIndex = zIndexBackdrop;
  return (
    <div>
      {backdropOnTarget && (
        <div
          className={classNames(styles.backdrop, 'eventShield')}
          onClick={e => onBackdropClick(e)}
          style={{ zIndex }}
        ></div>
      )}
      {!backdropOnTarget &&
        (() => {
          const targetBox = targetRef?.current?.getBoundingClientRect();

          if (!targetBox) {
            return;
          }
          const top = `${targetBox.top}px`;
          const left = `${targetBox.left}px`;
          const right = `${window.innerWidth - targetBox.left - targetBox.width}px`;
          const bottom = `${window.innerHeight - targetBox.top - targetBox.height}px`;
          const height = `${targetBox?.height}px`;

          return (
            <div onClick={e => onBackdropClick(e)}>
              <div className={styles.backdropItemTop} style={{ height: top, zIndex }}></div>
              <div
                className={styles.backdropItemLeft}
                style={{ width: left, top, height, zIndex }}
              ></div>
              <div
                className={styles.backdropItemRight}
                style={{ width: right, top, height, zIndex }}
              ></div>
              <div className={styles.backdropItemBottom} style={{ height: bottom, zIndex }}></div>
            </div>
          );
        })()}
    </div>
  );
};

export default PopoverBackdrop;
