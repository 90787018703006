import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'components/common/Button/Button';

import styles from './CookieBanner.module.scss';
import { initializePosthog } from './PosthogHandler';

const CookieBanner: React.FC = () => {
  const { t } = useTranslation();

  const cookieAccepted = localStorage.getItem('cookieBannerIsAccepted');
  const isAccepted = cookieAccepted === 'true';
  const isDenied = cookieAccepted === 'false';
  const choiceHasBeenMade = isAccepted || isDenied;

  const [bannerIsVisible, setBannerIsVisible] = React.useState(!choiceHasBeenMade);

  const onAccept = () => {
    localStorage.setItem('cookieBannerIsAccepted', 'true');
    setBannerIsVisible(false);
    initializePosthog();
  };

  const onDeny = () => {
    localStorage.setItem('cookieBannerIsAccepted', 'false');
    setBannerIsVisible(false);
  };

  if (!bannerIsVisible) {
    return null;
  }
  return (
    <div className={styles.container}>
      <p>
        <Trans
          i18nKey="login:cookieBannerText"
          components={[<Link to="/policies" key={'1'} target="_blank" rel="noopener noreferrer" />]}
        />
      </p>

      <div className={styles.buttons}>
        <Button
          fullWidth={true}
          className={styles.forceSingleLine}
          variant="alt"
          onClick={onDeny}
          label={t('login:cookieAllowNecessary')}
        />
        <Button
          fullWidth={true}
          className={styles.forceSingleLine}
          color="primary"
          onClick={onAccept}
          label={t('login:cookieAllowAll')}
        />
      </div>
    </div>
  );
};

export default React.memo(CookieBanner);
