import React, { Suspense, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

const PrivacyPolicy_en = React.lazy(() => import('pages/Policies/PrivacyPolicy/PrivacyPolicy_en'));
const PrivacyPolicy_de = React.lazy(() => import('pages/Policies/PrivacyPolicy/PrivacyPolicy_de'));

const PrivacyPolicy: React.FC = () => {
  const { i18n } = useTranslation();

  const language = i18n.resolvedLanguage;

  return (
    <Suspense fallback={<i></i>}>
      {language === 'de' ? <PrivacyPolicy_de /> : <PrivacyPolicy_en />}
    </Suspense>
  );
};

export default React.memo(PrivacyPolicy);
