import { useCallback } from 'react';

import {
  ConfirmDefaultType,
  ConfirmDeleteType,
  useConfirmationModalContext,
} from './confirmationModalContextProvider';

const useConfirmDialog = () => {
  const modalContext = useConfirmationModalContext();

  const deleteConfirmation = useCallback(
    (cotent: ConfirmDeleteType) => {
      return modalContext.showDeleteConfirmation(cotent);
    },
    [modalContext]
  );
  const confirmation = useCallback(
    (cotent: ConfirmDefaultType) => {
      return modalContext.showDefaultConfirmation(cotent);
    },
    [modalContext]
  );

  return {
    deleteConfirmation,
    confirmation,
  };
};

export default useConfirmDialog;
