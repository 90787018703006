import 'redux';

import React from 'react';

import { ReactComponent as DocIcon } from 'assets/icons/doc.svg';
import { ReactComponent as FileIcon } from 'assets/icons/file.svg';
import { ReactComponent as ImageFileTypeIcon } from 'assets/icons/imageFileType.svg';
import { ReactComponent as MaterialIcon } from 'assets/icons/material.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg';
import { ReactComponent as PptIcon } from 'assets/icons/ppt.svg';
import { ReactComponent as TxtIcon } from 'assets/icons/txt.svg';
import { ReactComponent as WhiteboardIcon } from 'assets/icons/whiteboardIcon.svg';
import { ReactComponent as XlsIcon } from 'assets/icons/xls.svg';

const getFolderIcon = (color = '#E2B544') => {
  return (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.75 13.6663C1.26875 13.6663 0.856771 13.495 0.514063 13.1523C0.171354 12.8096 0 12.3976 0 11.9163V2.08301C0 1.60176 0.171354 1.18978 0.514063 0.84707C0.856771 0.504362 1.26875 0.333008 1.75 0.333008H5.85417C6.09226 0.333008 6.3192 0.3783 6.53498 0.468883C6.75074 0.559452 6.94047 0.687772 7.10417 0.853841L8.33333 2.08301H14.9167C15.3979 2.08301 15.8099 2.25436 16.1526 2.59707C16.4953 2.93978 16.6667 3.35176 16.6667 3.83301V11.9163C16.6667 12.3976 16.4953 12.8096 16.1526 13.1523C15.8099 13.495 15.3979 13.6663 14.9167 13.6663H1.75Z"
        fill={color}
      />
    </svg>
  );
};
const icons = {
  pdf: <PdfIcon />,
  txt: <TxtIcon />,
  ppt: <PptIcon />,
  xls: <XlsIcon />,
  xlsx: <XlsIcon />,
  doc: <DocIcon />,
  docx: <DocIcon />,
  file: <FileIcon />,
  png: <ImageFileTypeIcon />,
  image: <ImageFileTypeIcon />,
  jpeg: <ImageFileTypeIcon />,
  jpg: <ImageFileTypeIcon />,
  material: <MaterialIcon />,
  whiteboard: <WhiteboardIcon />,
};
const FileTypeIcon: React.FC<{ type: string; color?: string }> = ({ type, color }) => {
  if (type === 'folder') {
    return getFolderIcon(color);
  }
  return icons[type] ?? icons.file;
};

export default React.memo(FileTypeIcon);
