import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EyeCrossedIcon } from 'assets/icons/eyeCrossed.svg';
import fieldErrorParser, { FieldErrorParserInterface } from 'utils/fieldErrorParser';

import LabelWithErrors from '../LabelWithErrors/LabelWithErrors';
import styles from './TextField.module.scss';

// Reusable text input component, can be single line or multi line. Supports auto growth
// Example:

//  <TextField
//    multiLine                         // If enabled, renders textarea
//    value={comment}                   // Value of input
//    startIcon={<Icon/>}               // Icon used as prefix
//    onChange={e => {                  // Handler, supplies event
//      setComment(e.target.value);
//    }}
//  />

interface Props {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onEndIconClick?: () => void;
  multiLine?: boolean;
  labelAndValidation?: FieldErrorParserInterface;
  rows?: number;
  className?: string;
  allowPositiveNumberOnly?: boolean;
  showNumberControls?: boolean;
}

const TextField: React.FC<
  Props & React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>
> = ({
  onChange,
  onBlur,
  startIcon = null,
  endIcon = null,
  onEndIconClick,
  disabled = false,
  multiLine = false,
  type = 'text',
  labelAndValidation,
  rows,
  className,
  allowPositiveNumberOnly = false,
  showNumberControls = true,
  ...rest
}) => {
  const wrapperNode = useRef(null);
  const textAreaNode = useRef(null);
  const inputNode = useRef(null);
  const [fieldIsTouched, setFieldIsTouched] = useState(false);
  const [usedType, setUsedType] = useState(type);

  const currentEndIcon =
    type === 'text' || type === 'number' ? (
      endIcon
    ) : usedType === 'password' ? (
      <EyeCrossedIcon />
    ) : (
      <EyeIcon />
    );

  const handleEndIconClick = () => {
    if (type === 'password') {
      setUsedType(usedType === 'password' ? 'text' : 'password');
    } else {
      onEndIconClick && onEndIconClick();
    }
  };

  const onChangeHandler = e => {
    if (onChange) {
      onChange(e);
    }
  };

  // Auto-adjust textarea height based on content changes
  useEffect(() => {
    if (textAreaNode.current && wrapperNode.current) {
      textAreaNode.current.style.height = 'auto';

      const scrollHeight = textAreaNode.current.scrollHeight - 5;

      textAreaNode.current.style.height = `${scrollHeight}px`;
    }
  }, [rest.value]);

  // Simulate an input number field change event
  const simulateNumberChangeEvent = (
    type: 'increment' | 'decrement',
    targetInput: HTMLInputElement
  ) => {
    const event = new Event('input', { bubbles: true });
    type === 'increment' ? targetInput.stepUp(1) : targetInput.stepDown(1);
    targetInput.dispatchEvent(event);
  };

  const incrementNumber = () => simulateNumberChangeEvent('increment', inputNode.current);

  const decrementNumber = () => simulateNumberChangeEvent('decrement', inputNode.current);

  const onBlurHandler = e => {
    setFieldIsTouched(true);
    onBlur && onBlur(e);
  };

  const errorInfo = fieldErrorParser(labelAndValidation, fieldIsTouched);

  return (
    <div style={{ width: '100%' }}>
      <LabelWithErrors errorInfo={errorInfo} labelAndValidation={labelAndValidation} />
      <label
        ref={wrapperNode}
        htmlFor={rest.id ? rest.id : undefined}
        className={classNames({
          [styles.textField]: true,
          [styles.start]: startIcon,
          [styles.endIconIsUsed]: currentEndIcon,
          [styles.disabled]: disabled,
          [styles.errorInput]: errorInfo.showFirstError,
          [className]: !!className,
        })}
      >
        {startIcon && (
          <div className={classNames(styles.iconContainer, styles.start)}>{startIcon}</div>
        )}
        {multiLine && (
          <textarea
            rows={rows || 1}
            ref={textAreaNode}
            onInput={onChangeHandler}
            onBlur={onBlurHandler}
            {...rest}
          />
        )}
        {!multiLine && (
          <input
            ref={inputNode}
            type={usedType}
            onChange={onChange}
            onBlur={onBlurHandler}
            min={allowPositiveNumberOnly ? 1 : undefined}
            {...rest}
          />
        )}
        {type === 'number' && showNumberControls && (
          <div className={styles.numberIncrementDecrementButtons}>
            <div onClick={incrementNumber}>
              <ChevronIcon style={{ transform: 'rotate(180deg)' }} />
            </div>
            <div onClick={decrementNumber}>
              <ChevronIcon />
            </div>
          </div>
        )}
        {currentEndIcon && (
          <div
            className={classNames(styles.iconContainer, {
              [styles.iconContainerClickable]: onEndIconClick || type === 'password',
            })}
            onClick={handleEndIconClick}
          >
            {currentEndIcon}
          </div>
        )}
      </label>
    </div>
  );
};

export default TextField;
