import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as DocbuilderIcon } from 'assets/icons/docbuilder-navbar.svg';
import { ReactComponent as LibraryIcon } from 'assets/icons/library.svg';
import logo from 'assets/icons/logo-short.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as MyFilesIcon } from 'assets/icons/myMaterials.svg';
import { ReactComponent as WhiteboardIcon } from 'assets/icons/whiteboard.svg';
import { changeLanguage } from 'assets/translations/languageHandler';
import Button from 'components/common/Button/Button';
import useCurrentLanguage from 'hooks/generic/useCurrentLanguage';
import useWindowSize from 'hooks/generic/useWindowSize';
import actions from 'redux/authentication/auth.slice';
import useUser from 'redux/authentication/hooks/useUser';
import { useAppDispatch } from 'redux/hooks';
import requestAPI from 'utils/apiHandler';
import { shakeModal } from 'utils/shakeElement';

import useFeatureFlagChecker from '../../../hooks/generic/useFeatureFlagChecker';
import LoadingUserAvatar from '../LoadingUserAvatar/LoadingUserAvatar';
import BillingBadge from './BillingBadge';
import styles from './NavBar.module.scss';
import SmallLanguageDropDown from './SmallLanguageDropDown';

export const pages = [
  {
    id: 'whiteboard',
    path: '/whiteboard',
    title: 'common:nav.whiteboard',
    icon: <WhiteboardIcon />,
  },
  {
    id: 'documents',
    path: '/editor',
    title: 'common:nav.documents',
    icon: <DocbuilderIcon />,
  },
  {
    id: 'myFiles',
    path: '/mymaterials',
    title: 'common:nav.myMaterials',
    icon: <MyFilesIcon style={{ maxWidth: 26, maxHeight: 26, height: 26, width: 26 }} />,
  },
  {
    id: 'calendar',
    path: '/calendar',
    title: 'common:nav.calender',
    icon: <CalendarIcon />,
  },
  {
    id: 'library',
    path: '/library',
    title: 'common:nav.library',
    icon: <LibraryIcon />,
  },
];

const NavBar: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const history = useHistory();
  const [isNavMenuOpen, toggleNavMenu] = useState(false);
  const { isLarge } = useWindowSize();
  const currentLanguage = useCurrentLanguage();

  const showMenuItem = {
    library: useFeatureFlagChecker('showLibrary'),
    myFiles: true,
    calendar: true,
    whiteboard: true,
    documents: true,
  };

  useEffect(() => {
    toggleNavMenu(false);
  }, [location.pathname]);

  const user = useUser();
  const isTempUser = !!user?.temporary;
  const emailIsValidated = !!user?.email_verified_at;

  const dispatch = useAppDispatch();
  const onLanguageChange = async lang => {
    if (!lang) {
      return;
    }
    dispatch(actions.setLanguage(lang));

    changeLanguage(lang);
    if (user) {
      await requestAPI({
        url: '/user',
        method: 'PUT',
        data: {
          language: lang,
        },
      });
    }
  };

  const pathsRedirectToLogin = user?.temporary || !user;

  const setModal = (modal: 'login' | 'register' | 'confirmCode' | 'resetPassword') => {
    dispatch(actions.setOpenedModal(modal));
    if (modal) {
      shakeModal();
    }
  };

  const onNavClick = (page: { path: string; id: string }) => {
    const normalUserLogedIn = user && !user.temporary;
    const notLoggedIn = !user;
    const tempUserLoggedIn = user && user.temporary;
    if (normalUserLogedIn) {
      history.push(page.path);
      return;
    }

    if (notLoggedIn) {
      if (page.path === '/whiteboard') {
        setModal(null);
        history.push('/whiteboard');
        return;
      }
      setModal('login');
    }

    if (tempUserLoggedIn) {
      if (page.path === '/whiteboard') {
        setModal(null);
      } else {
        setModal('login');
      }
      const currentlyInWhiteboard = location.pathname.startsWith('/whiteboard');
      if (!currentlyInWhiteboard) {
        history.push('/whiteboard');
      }
    }
  };

  const Policies = (
    <div className={styles.policies}>
      <span className={styles.copyrightYear}>© {new Date().getFullYear()}</span>

      <Link to="/policies" className={styles.policy}>
        {t('common:policies')}
      </Link>
    </div>
  );

  const showUserDetails = user && !isTempUser;
  const avatarSize = isLarge ? 48 : 64;

  return (
    <>
      <div className={classNames('t-navbar', styles.container)}>
        <div className={styles.logoAndNavigation}>
          <Link to="/">
            <img src={logo} className={styles.logo} alt="logo" />
          </Link>
          {pages
            .filter(obj => showMenuItem[obj.id])
            .map(page => {
              return (
                <Link
                  key={page.path}
                  to={'#'}
                  className={classNames(styles.item, `t-navbar-link-${page.id}`, {
                    [styles.itemSelected]: location.pathname.startsWith(page.path),
                    [styles.routeDisabled]:
                      pathsRedirectToLogin && !page.path.startsWith('/whiteboard'),
                  })}
                  onClick={() => onNavClick(page)}
                >
                  <div className={styles.iconContainer}>{page.icon}</div>
                  <div className={styles.title}>{t(page.title)}</div>
                </Link>
              );
            })}
        </div>
        <div style={{ flexGrow: '1' }}></div>
        {(isTempUser || !user) && (
          <div style={{ display: 'flex' }}>
            <SmallLanguageDropDown
              value={currentLanguage}
              onChange={lang => {
                onLanguageChange(lang);
              }}
            />
          </div>
        )}
        {showUserDetails ? (
          <div className={styles.userInfoAndLogout}>
            <div className={styles.userNameAndPicture}>
              <BillingBadge />
              {!user || emailIsValidated ? (
                <Link to="/account" style={{ textDecoration: 'none' }}>
                  <div className={styles.avatarContainer}>
                    <LoadingUserAvatar size={avatarSize} />
                  </div>
                </Link>
              ) : (
                <LoadingUserAvatar size={avatarSize} />
              )}
            </div>
          </div>
        ) : (
          <div className={styles.logRegBtns}>
            <Button
              fullWidth={true}
              className={styles.loginBtn}
              size="small"
              variant="alt"
              onClick={() => {
                dispatch(actions.setOpenedModal('login'));
              }}
              label={t('login:login')}
            />
            <Button
              fullWidth={true}
              className={styles.registerBtn}
              size="small"
              variant="primary"
              onClick={() => {
                dispatch(actions.setOpenedModal('register'));
              }}
              label={t('login:register')}
            />
          </div>
        )}
        {Policies}
      </div>

      <div
        className={classNames(styles.mobileContainer, { [styles.mobileNavOpen]: isNavMenuOpen })}
      >
        <div className={styles.mobileNavHeader}>
          <div onClick={() => toggleNavMenu(!isNavMenuOpen)}>
            <MenuIcon />
          </div>
          <Link to="/">
            <img src={logo} className={styles.logo} alt="logo" />
          </Link>
          <div>
            {showUserDetails ? (
              <Link to="/account">
                <LoadingUserAvatar size={24} />
              </Link>
            ) : (
              <div style={{ width: '24px' }}></div>
            )}
          </div>
        </div>

        {isNavMenuOpen && (
          <>
            <div className={styles.mobileMenuItems}>
              {pages
                .filter(obj => showMenuItem[obj.id])
                .map(page => {
                  return (
                    <Link
                      key={page.path}
                      to={'#'}
                      className={classNames(styles.menuItem, {
                        [styles.itemSelected]: location.pathname.startsWith(page.path),
                        [styles.routeDisabled]:
                          pathsRedirectToLogin && !page.path.startsWith('/whiteboard'),
                      })}
                      onClick={() => {
                        toggleNavMenu(!isNavMenuOpen);
                        onNavClick(page);
                      }}
                    >
                      <div className={styles.iconContainer}>{page.icon}</div>
                      <div className={styles.title}>{t(page.title)}</div>
                    </Link>
                  );
                })}
              <div className={styles.mobilePolicies}>{Policies}</div>
            </div>
          </>
        )}
      </div>

      <div className={styles.mobileHeaderOffset}></div>
    </>
  );
};

export default NavBar;
