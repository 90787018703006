import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
}

const ScrollToTop: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const element = document.getElementById('root-route');
    const noScrollElements = document.getElementsByClassName('dont-scroll');
    if (element && noScrollElements.length === 0) {
      element.scroll({ top: 0 });
    }
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
