import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { createPortal } from 'react-dom';

import useEffectAfterMount from 'hooks/generic/useEffectAfterMount';

import cssStyles from './TooltipNew.module.scss';
import helper, {
  Box,
  HorizontalAnchorsAdjusted,
  PositionHorizontal,
  PositionVertical,
  VerticalAnchorsAdjusted,
} from './helper';

const targetMapping = {
  top: 'top.center',
  right: 'center.right',
  bottom: 'bottom.center',
  left: 'center.left',
};
const childrenMapping = {
  top: 'bottom.center',
  right: 'center.left',
  bottom: 'top.center',
  left: 'center.right',
};

interface Props {
  paddingVertical?: number;
  paddingHorizontal?: number;
  maxWidth?: number;
  showArrow?: boolean;
  tooltipBackgroundColor?: string;
  arrowColor?: string;
  tooltip?: React.ReactNode;
  delay?: number;
  position?: 'top' | 'right' | 'bottom' | 'left';
  alignCenter?: boolean;
}

const TooltipNew: React.FC<Props> = (props, refs) => {
  const {
    children,
    maxWidth = 280,
    paddingVertical = 16,
    paddingHorizontal = 16,
    showArrow = true,
    tooltipBackgroundColor = '#213960',
    arrowColor = '#213960',
    tooltip = '',
    delay = 100,
    position = 'top',
    alignCenter = true,
  } = props;

  const [isMeasurmentRender, setIsMeasurmentRender] = useState(true);
  const [targetRect, setTargetRect] = useState<Box>(null);
  const [childrenRect, setChildrenRect] = useState<Box>(null);

  const [anchors, setAchors] = useState<{
    verticalAnchorsAdjusted: VerticalAnchorsAdjusted;
    horizontalAnchorsAdjusted: HorizontalAnchorsAdjusted;
  }>({ horizontalAnchorsAdjusted: null, verticalAnchorsAdjusted: null });

  const [mouseIsOnTarget, setMouseIsOnTarget] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const targetRef = useRef(null);

  const timerRef = useRef(null);
  useEffect(() => {
    if (mouseIsOnTarget) {
      timerRef.current = setTimeout(() => setShowTooltip(true), delay);
      return () => clearTimeout(timerRef.current);
    }
  }, [mouseIsOnTarget, delay]);

  useEffectAfterMount(() => {
    if (!tooltip) {
      hideTooltip();
    }
  }, [tooltip]);

  const childrenRef = useRef(null);

  const setPosition = () => {
    const childrenBox: DOMRect = childrenRef?.current?.getBoundingClientRect();
    const targetBoxRect: DOMRect = targetRef?.current?.childNodes[0].getBoundingClientRect();

    const targetBox = helper.addPadding(targetBoxRect, paddingVertical, paddingHorizontal);

    const childrenHeight = childrenBox.height;
    const childrenWidth = childrenBox.width;

    setIsMeasurmentRender(false);
    setTargetRect(targetBox);
    setChildrenRect({ ...childrenBox, height: childrenHeight, width: childrenWidth });

    const targetElementPos = targetMapping[position];
    const childrenElementPos = childrenMapping[position];
    const targetElementPosVertical = targetElementPos.split('.')[0] as PositionVertical;
    const targetElementPosHorizontal = targetElementPos.split('.')[1] as PositionHorizontal;

    const childrenElementPosVertical = childrenElementPos.split('.')[0] as PositionVertical;
    const childrenElementPosHorizontal = childrenElementPos.split('.')[1] as PositionHorizontal;

    setAchors({
      verticalAnchorsAdjusted: helper.getVerticalAnchorPointsAdjusted(
        targetBox,
        childrenHeight,
        targetElementPosVertical,
        childrenElementPosVertical
      ),
      horizontalAnchorsAdjusted: helper.getHorizontalAnchorPointsAdjusted(
        targetBox,
        childrenWidth,
        targetElementPosHorizontal,
        childrenElementPosHorizontal
      ),
    });
  };

  const setChildrenRef = node => {
    childrenRef.current = node;

    if (node && isMeasurmentRender) {
      setPosition();
    }
  };

  const styles = helper.getCssStylesByAnchors(
    anchors.verticalAnchorsAdjusted,
    anchors.horizontalAnchorsAdjusted,
    isMeasurmentRender,
    targetRect,
    childrenRect,
    false
  );

  useEffect(() => {
    if (!showTooltip) {
      return;
    }
    const keys = [33, 34, 38, 40];
    const hide = e => hideTooltip();
    const preventKeys = e => keys.includes(e.keyCode) && hideTooltip();

    targetRef.current.addEventListener('wheel', hide, { passive: false });
    targetRef.current.addEventListener('touchmove', hide, { passive: false });
    targetRef.current.addEventListener('keydown', preventKeys, false);
    return () => {
      targetRef.current?.removeEventListener('wheel', hide, { passive: false });
      targetRef.current?.removeEventListener('touchmove', hide, { passive: false });
      targetRef.current?.removeEventListener('keydown', preventKeys, false);
    };
  }, [showTooltip]);

  const showTooltipForTheFirstTime = () => {
    if (tooltip) {
      setMouseIsOnTarget(true);
    }
  };
  const hideTooltip = () => {
    setIsMeasurmentRender(true);
    setMouseIsOnTarget(false);
    setShowTooltip(false);
    clearTimeout(timerRef.current);
  };

  //@ts-ignore
  const childrenType: string = children.type;

  const shouldAddContainerAroundChildren =
    childrenType === undefined || childrenType.toString() === React.Fragment.toString();

  return (
    <>
      <div
        tabIndex={1}
        ref={targetRef}
        style={{ display: 'contents' }}
        onMouseLeave={hideTooltip}
        onMouseEnter={showTooltipForTheFirstTime}
      >
        {shouldAddContainerAroundChildren ? (
          <div style={{ display: 'inline-block' }}>{children}</div>
        ) : (
          children
        )}
      </div>
      {showTooltip &&
        createPortal(
          <>
            {showArrow && (
              <div
                className={cssStyles.arrow}
                style={helper.getArrowStyles(
                  styles,
                  targetRect,
                  childrenRect,
                  arrowColor,
                  isMeasurmentRender
                )}
              ></div>
            )}

            <div
              tabIndex={1}
              ref={setChildrenRef}
              className={cssStyles.tooltipContent}
              style={{
                ...styles,
                pointerEvents: 'none',
                maxWidth: `${maxWidth}px`,
                textAlign: alignCenter ? 'center' : 'left',
              }}
            >
              <div
                className={cssStyles.tooltipContentInner}
                style={{ backgroundColor: tooltipBackgroundColor }}
              >
                {tooltip}
              </div>
            </div>
          </>,
          document.getElementById('tooltip')
        )}
    </>
  );
};

export default TooltipNew;
