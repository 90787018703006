import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import requestAPI from 'utils/apiHandler';

import { PdfI, SlideI, SlideItemI, TemplateI, WhiteboardDataI } from './Interfaces';

export const getInitialSlide = (): SlideI => ({
  id: 'slide1',
  backgroundImage: null,
  items: {},
  orderNr: 1,
});

export type Tool =
  | 'line'
  | 'path'
  | 'text'
  | 'rect'
  | 'circle'
  | 'triangle'
  | 'arrow'
  | 'fadeOutMarker'
  | 'fixedSpotlight'
  | 'spotlight';

const initialState: {
  data?: WhiteboardDataI;
  width?: number;
  height?: number;
  moveIsActive: boolean;
  whiteboardId?: string;
  selectedSlideId: string;
  selectedElementIds: string[];
  templateModal: {
    mode: 'create' | 'edit';
    template: TemplateI;
    isOpened: boolean;
    templateJson: string;
  };
  past: {
    [slideId: string]: SlideI[];
  };
  future: {
    [slideId: string]: SlideI[];
  };
  controls: {
    controlPanelSubMenu:
      | 'path'
      | 'line'
      | 'shape'
      | 'add'
      | 'templatesAndWidgets'
      | 'fadeOutMarker'
      | 'fixedSpotlight'
      | 'spotlight';
    tool: Tool;
    presentationTools: 'fixedSpotlight'[];
    color: string;
    borderWidth: number;
    zoom: number;
    zoomingIsActive: boolean;
    openedModal: 'image' | 'pdf' | 'video' | 'material' | 'proFeature';
    hideAllTools: boolean;
    smoothLineCoeff: number;
    smoothLines: boolean;
  };
  templates: TemplateI[];
  curtain: {
    enabled: boolean;
    cursor: string;
  };
} = {
  data: null,
  width: 0,
  height: 0,
  moveIsActive: false,
  whiteboardId: null,
  selectedSlideId: null,
  selectedElementIds: [],
  templateModal: {
    mode: 'create',
    template: null,
    isOpened: false,
    templateJson: null,
  },
  past: {},
  future: {},
  controls: {
    controlPanelSubMenu: null,
    tool: null,
    presentationTools: [],
    color: '#43C0A2',
    borderWidth: 10,
    zoom: 1,
    zoomingIsActive: false,
    openedModal: null,
    hideAllTools: false,
    smoothLineCoeff: 0.2,
    smoothLines: true,
  },
  templates: [],
  curtain: {
    enabled: false,
    cursor: 'default',
  },
};

const addToHistory = (state: any, slideId: string) => {
  if (!state.past[slideId]) {
    const initialSlide = getInitialSlide();
    state.past[slideId] = [{ ...initialSlide, id: slideId }];
  }
  state.future[slideId] = [];
  state.past[slideId].push(state.data.slides[slideId]);
};

export const fetchTemplates = createAsyncThunk(
  'whiteboard/fetchTemplates',
  async (payload: any, thunkApi) => {
    const templates = await requestAPI('/whiteboard-templates?showSlides=true');
    const sorted = orderBy(templates, ['title'], ['asc']);
    await thunkApi.dispatch(WhiteboardSlice.actions.setTemplates(sorted));
  }
);

const WhiteboardSlice = createSlice({
  name: 'whiteboard',
  initialState,
  reducers: {
    resetState(state) {
      return {
        ...initialState,
        templates: state.templates,
      };
    },
    initWhiteboard(
      state,
      action: PayloadAction<{
        data: any;
        whiteboardId: string;
      }>
    ) {
      const { whiteboardId, data } = action.payload;
      state.whiteboardId = whiteboardId;
      state.data = data;
      state.selectedSlideId = Object.keys(data.slides)[0];

      state.future = {};
      state.past = {};
      Object.values(data.slides).forEach((slide: any) => {
        state.past[slide.id] = [slide];
      });
      // if (!state.past[slideId]) {
      //   const initialSlide = getInitialSlide();
      //   state.past[slideId] = [{ ...initialSlide, id: slideId }];
      // }
    },
    saveStateToHistory(state) {
      addToHistory(state, state.selectedSlideId);
    },
    enableWhiteboardCurtain(state, action: PayloadAction<string>) {
      state.curtain.enabled = true;
      state.curtain.cursor = action.payload;
    },
    disableWhiteboardCurtain(state) {
      state.curtain.enabled = false;
      state.curtain.cursor = 'default';
    },
    openTemplateModal(
      state,
      action: PayloadAction<{ json: string; mode: 'create' | 'edit'; template: TemplateI }>
    ) {
      state.templateModal = {
        isOpened: true,
        template: action.payload.template,
        mode: action.payload.mode,
        templateJson: action.payload.json,
      };
    },
    closeTemplateModal(state) {
      state.templateModal.isOpened = false;
      state.templateModal.templateJson = null;
      state.templateModal = {
        isOpened: false,
        mode: 'create',
        template: null,
        templateJson: null,
      };
    },

    disablePresentationTool(state, action: PayloadAction<'fixedSpotlight'>) {
      const index = state.controls.presentationTools.indexOf(action.payload);
      if (index !== -1) {
        state.controls.presentationTools.splice(index, 1);
      }
    },
    enablePresentationTool(state, action: PayloadAction<'fixedSpotlight'>) {
      const index = state.controls.presentationTools.indexOf(action.payload);
      if (index === -1) {
        state.controls.presentationTools.push(action.payload);
      }
    },
    bringToFront(
      state,
      action: PayloadAction<{
        slideId: string;
        itemIds: string[];
      }>
    ) {
      const { slideId, itemIds } = action.payload;

      const maxOrderNumber = Math.max(
        ...Object.values(state.data.slides[slideId].items).map(i => i.orderNumber)
      );

      const selectedItemsSorted = orderBy(
        Object.values(state.data.slides[slideId].items),
        item => item.orderNumber
      ).filter(i => itemIds.includes(i.id));

      selectedItemsSorted.forEach((item, index) => {
        state.data.slides[slideId].items[item.id].orderNumber = maxOrderNumber + index + 1;
      });

      addToHistory(state, slideId);
    },
    bringToBack(
      state,
      action: PayloadAction<{
        slideId: string;
        itemIds: string[];
      }>
    ) {
      const { slideId, itemIds } = action.payload;

      const minOrderNumber = Math.min(
        ...Object.values(state.data.slides[slideId].items).map(i => i.orderNumber)
      );

      const selectedItemsSorted = orderBy(
        Object.values(state.data.slides[slideId].items),
        item => item.orderNumber
      ).filter(i => itemIds.includes(i.id));

      selectedItemsSorted.forEach((item, index) => {
        state.data.slides[slideId].items[item.id].orderNumber = minOrderNumber - index - 1;
      });

      addToHistory(state, slideId);
    },
    setTemplates(state, action: PayloadAction<(typeof state)['templates']>) {
      state.templates = action.payload;
    },
    setPdfPage(state, action: PayloadAction<{ itemId: string; direction: 'next' | 'prev' }>) {
      const { itemId, direction } = action.payload;
      const item = state.data.slides[state.selectedSlideId].items[itemId];
      const allPages = Array.from(
        Array(item.totalNumberOfPages === null ? 0 : item.totalNumberOfPages).keys()
      ).map(i => i + 1);
      const pagesRelative = item.pagesSelected
        ? item.pagesSelected.split(',').map(i => +i)
        : allPages;
      const currentIndex = pagesRelative.indexOf(item.currentPage) + 1;
      const relativeIndexNext = currentIndex === pagesRelative.length ? 1 : currentIndex + 1;
      const relativeIndexPrev = currentIndex > 1 ? currentIndex - 1 : pagesRelative.length;

      item.currentPage =
        pagesRelative[(direction === 'next' ? relativeIndexNext : relativeIndexPrev) - 1];
    },
    setPdfPageCount(state, action: PayloadAction<{ itemId: string; totalNumberOfPages: number }>) {
      const { itemId, totalNumberOfPages } = action.payload;
      const item = state.data.slides[state.selectedSlideId].items[itemId];
      const allPages = Array.from(
        Array(totalNumberOfPages === null ? 0 : totalNumberOfPages).keys()
      ).map(i => i + 1);
      item.totalNumberOfPages = totalNumberOfPages;
      item.allPages = allPages;
      item.pagesRelative = allPages;
    },
    setPdfZoom(state, action: PayloadAction<{ itemId: string; direction: 'zoomIn' | 'zoomOut' }>) {
      const { itemId } = action.payload;
      const item = state.data.slides[state.selectedSlideId].items[itemId];
      const zooms = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2, 3, 4];
      const index = zooms.indexOf(item.scale);
      const newIndex = action.payload.direction === 'zoomIn' ? index + 1 : index - 1;
      if (zooms[newIndex]) {
        item.scale = zooms[newIndex];
      }
    },
    setSmoothLineCoeff(state, action: PayloadAction<number>) {
      state.controls.smoothLineCoeff = action.payload;
    },
    setSmoothLines(state, action: PayloadAction<boolean>) {
      state.controls.smoothLines = action.payload;
    },
    setMoveIsActive(state, action: PayloadAction<boolean>) {
      state.moveIsActive = action.payload;
    },
    toogleHideAllTools(state, action: PayloadAction<boolean>) {
      state.controls.hideAllTools = action.payload;
      state.controls.tool = null;
    },
    setItemProperty(
      state,
      action: PayloadAction<{
        itemIds: string[];
        property: string;
        value: any;
      }>
    ) {
      const { itemIds, property, value } = action.payload;
      const slideId = state.selectedSlideId;

      itemIds.forEach(itemId => {
        state.data.slides[slideId].items[itemId][property] = value;
      });
    },
    setItemProperties(
      state,
      action: PayloadAction<{
        changes: { itemId: string; property: string; value: any }[];
      }>
    ) {
      const { changes } = action.payload;
      const slideId = state.selectedSlideId;

      changes.forEach(change => {
        state.data.slides[slideId].items[change.itemId][change.property] = change.value;
      });
    },
    setPdfPageSelector(
      state,
      action: PayloadAction<{
        pagesSelected: string;
        pageSelector: string;
        currentPage: number;
        itemId: string;
      }>
    ) {
      const { pagesSelected, pageSelector, currentPage, itemId } = action.payload;
      const item = state.data.slides[state.selectedSlideId].items[itemId] as PdfI;
      item.pagesSelected = pagesSelected;
      item.pageSelector = pageSelector;
      item.currentPage = currentPage;

      if (pagesSelected) {
        item.pagesRelative = item.pagesSelected.split(',').map(i => +i);
      } else {
        item.pagesRelative = item.allPages;
      }
    },
    setSelectedElementIds(state, action: PayloadAction<string[]>) {
      state.selectedElementIds = action.payload;
    },
    setColor(state, action: PayloadAction<string>) {
      state.controls.color = action.payload;
    },
    setZoom(state, action: PayloadAction<number>) {
      state.controls.zoom = action.payload;
      state.controls.zoomingIsActive = false;
    },
    setZoomingIsActive(state, action: PayloadAction<boolean>) {
      state.controls.zoomingIsActive = action.payload;
    },
    setBorderWidth(state, action: PayloadAction<number>) {
      state.controls.borderWidth = action.payload;
    },
    clearSlideItems(state) {
      state.data.slides[state.selectedSlideId].items = {};
      state.selectedElementIds = [];
      addToHistory(state, state.selectedSlideId);
    },
    setTool(state, action: PayloadAction<{ tool: Tool; clearSelection?: boolean }>) {
      const { tool, clearSelection = true } = action.payload;
      state.controls.tool = tool;
      if (clearSelection) {
        state.selectedElementIds = [];
      }
    },
    setControlPanelSubMenu(
      state,
      action: PayloadAction<(typeof state)['controls']['controlPanelSubMenu']>
    ) {
      state.controls.controlPanelSubMenu = action.payload;
    },
    setOpenedModal(state, action: PayloadAction<(typeof state)['controls']['openedModal']>) {
      state.controls.openedModal = action.payload;
    },
    setWhiteboardSize(state, action: PayloadAction<{ width: number; height: number }>) {
      state.width = action.payload.width;
      state.height = action.payload.height;
    },

    setSelectedSlideId(state, action: PayloadAction<string>) {
      state.selectedSlideId = action.payload;
      state.selectedElementIds = [];
    },
    selectAllSlideItems(state, action: PayloadAction<string>) {
      state.selectedElementIds = Object.keys(state.data.slides[action.payload].items);
      state.controls.tool = null;
    },

    removeSlide(state) {
      const currentSlideIndex = state.data.slides[state.selectedSlideId].orderNr;
      const slidesArr = Object.values(state.data.slides);
      if (slidesArr.length === 1) {
        return;
      }
      const prevSlides = slidesArr
        .filter(i => i.orderNr < currentSlideIndex)
        .sort((a, b) => a.orderNr - b.orderNr);
      const nextSlides = slidesArr
        .filter(i => i.orderNr > currentSlideIndex)
        .sort((a, b) => a.orderNr - b.orderNr);

      delete state.data.slides[state.selectedSlideId];
      state.selectedSlideId =
        nextSlides.length > 0 ? nextSlides[0].id : prevSlides[prevSlides.length - 1].id;

      nextSlides.forEach(slide => {
        slide.orderNr -= 1;
      });
    },
    addSlide(state) {
      const currentSlideIndex = state.data.slides[state.selectedSlideId].orderNr;

      Object.values(state.data.slides).forEach(slide => {
        if (slide.orderNr > currentSlideIndex) {
          slide.orderNr += 1;
        }
      });

      const newSlideId = `slide${Math.random().toString().replace('.', '')}`;
      state.data.slides[newSlideId] = {
        backgroundImage: null,
        id: newSlideId,
        items: {},
        orderNr: currentSlideIndex + 1,
      };
      state.selectedSlideId = newSlideId;
    },
    setWhiteboardTitle(state, action: PayloadAction<string>) {
      state.data.title = action.payload;
    },
    setNextSlide(state) {
      const slides = Object.values(state.data?.slides);
      const slidesSorted = orderBy(slides, slide => slide.orderNr);
      const selectedSlide = slidesSorted.find(s => s.id === state.selectedSlideId);
      const index = slidesSorted.indexOf(selectedSlide);
      const nextSlide =
        index === slidesSorted.length - 1 ? slidesSorted[0] : slidesSorted[index + 1];
      state.selectedSlideId = nextSlide.id;
      state.selectedElementIds = [];
    },
    setPrevSlide(state) {
      const slides = Object.values(state.data?.slides);
      const slidesSorted = orderBy(slides, slide => slide.orderNr);
      const selectedSlide = slidesSorted.find(s => s.id === state.selectedSlideId);
      const index = slidesSorted.indexOf(selectedSlide);
      const prevSlide =
        index === 0 ? slidesSorted[slidesSorted.length - 1] : slidesSorted[index - 1];
      state.selectedSlideId = prevSlide.id;
      state.selectedElementIds = [];
    },

    addSlideItems(
      state,
      action: PayloadAction<{
        items: Omit<SlideItemI, 'orderNumber' | 'id'>[];
        selectAll?: boolean;
        shouldAddToHistory?: boolean;
      }>
    ) {
      const { items, selectAll, shouldAddToHistory = true } = action.payload;
      const slideId = state.selectedSlideId;

      state.selectedElementIds = [];

      items.forEach(item => {
        const highestOrder = Math.max(
          ...Object.values(state.data.slides[slideId].items).map(item => +item.orderNumber),
          0
        );

        const itemId = `item${Math.random().toString().replace('.', '')}`;

        state.data.slides[slideId].items[itemId] = {
          ...item,
          id: itemId,
          orderNumber: highestOrder + 1,
        };
        if (selectAll || !['line', 'arrow', 'text'].includes(item.type)) {
          state.selectedElementIds.push(itemId);
        }
      });

      if (shouldAddToHistory) {
        addToHistory(state, slideId);
      }
    },
    deleteSlideItems(state, action: PayloadAction<string[]>) {
      const itemIds = action.payload;
      const slideId = state.selectedSlideId;
      const slideIdOfInterest = slideId || state.selectedSlideId;
      itemIds.forEach(itemId => {
        delete state.data.slides[slideIdOfInterest].items[itemId];
      });
      state.selectedElementIds = [];

      addToHistory(state, slideIdOfInterest);
    },
    moveSlideItem(
      state,
      action: PayloadAction<{
        itemIds: string[];
        diffX: number;
        diffY: number;
      }>
    ) {
      const { itemIds, diffX, diffY } = action.payload;

      const moveItemIsSelected = state.selectedElementIds.includes(itemIds[0]);
      const ids = moveItemIsSelected ? state.selectedElementIds : itemIds;

      ids.forEach(itemId => {
        const item = state.data.slides[state.selectedSlideId].items[itemId];
        item.x += diffX;
        item.y += diffY;
      });

      addToHistory(state, state.selectedSlideId);
    },
    resizeSlideItem(
      state,
      action: PayloadAction<{
        itemId: string;
        width: number;
        height: number;
      }>
    ) {
      const { itemId, width, height } = action.payload;

      const item = state.data.slides[state.selectedSlideId].items[itemId];

      item.width = width;
      item.height = height;

      addToHistory(state, state.selectedSlideId);
    },
    addToHistory(
      state,
      action: PayloadAction<{
        slideId: string;
      }>
    ) {
      addToHistory(state, action.payload.slideId);
    },
    undo(state) {
      const arrPast = state.past[state.selectedSlideId] || [];

      if (arrPast.length > 1) {
        const lastState = arrPast.pop();
        state.data.slides[state.selectedSlideId] = arrPast[arrPast.length - 1];

        state.future[state.selectedSlideId] = [...state.future[state.selectedSlideId], lastState];
        state.selectedElementIds = [];
      }
    },
    redo(state) {
      const arrFuture = state.future[state.selectedSlideId] || [];

      if (arrFuture.length) {
        const lastState = arrFuture.pop();
        state.data.slides[state.selectedSlideId] = lastState;
        state.past[state.selectedSlideId] = [...state.past[state.selectedSlideId], lastState];
      }
    },
  },
});

export type WhiteboardStateI = typeof initialState;

export const slidesArr = createSelector([(s: WhiteboardStateI) => s.data.slides], slides => {
  return orderBy(
    Object.entries(slides).map(([slideId, slide]) => slide),
    slide => slide.orderNr
  );
});

export const { reducer } = WhiteboardSlice;
export default WhiteboardSlice.actions;
