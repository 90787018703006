import CouponInput from 'pages/UserProfile/CouponInput';

import React, { useState } from 'react';

import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'components/common/Button/Button';
import CheckBox from 'components/common/CheckBox/CheckBox';
import LabelWithErrors from 'components/common/LabelWithErrors/LabelWithErrors';
import ModalDialog from 'components/common/ModalDialog/ModalDialog';
import TextField from 'components/common/TextField/TextField';
import useAuthentication from 'hooks/api/useAuthentication';
import useForm from 'hooks/generic/useForm';
import actions from 'redux/authentication/auth.slice';
import useUser from 'redux/authentication/hooks/useUser';
import { useAppDispatch } from 'redux/hooks';
import requestAPI from 'utils/apiHandler';
import fieldErrorParser from 'utils/fieldErrorParser';
import { shakeModal } from 'utils/shakeElement';

import styles from './RegisterModal.module.scss';

const capitalizeString = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const RegisterModal: React.FC = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    // first_name: 'YourFirstName',
    // last_name: 'YourLastName',
    first_name: '',
    last_name: '',
  });
  const [coupon, setCoupon] = useState('');
  const [couponStatus, setCouponStatus] = useState<'isValid' | 'notValid'>(null);
  const [couponInputVisible, setCouponInputVisible] = useState(false);

  const [termsAreAccepted, setTermsAreAccepted] = useState(false);
  const [errorMsg, setErrMsg] = useState('');
  const dispatch = useAppDispatch();

  const user = useUser();
  const { login, getCurrentUser } = useAuthentication();

  const applyCouponCode = async () => {
    if (couponStatus !== 'isValid') {
      return;
    }
    try {
      const res = await requestAPI({
        url: '/coupon/apply',
        method: 'POST',
        data: {
          code: coupon,
        },
      });
      if (res.status === 'ok') {
        getCurrentUser();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onRegisterClick = async () => {
    form.setFormIsTouched(true);
    if (!form.isValid) {
      shakeModal();
      return;
    }
    setErrMsg('');
    form.setIsLoadingActions(true);

    try {
      const response = await requestAPI({
        url: user ? 'register/temporary' : 'register',
        method: 'POST',
        data: credentials,
      });

      if (response.errors) {
        setErrMsg(JSON.stringify(response.errors));
        const error = response.errors[0];
        const path = error.path;
        const msg = error.msg;
        if (path === 'email' && msg === 'The record exists') {
          setErrMsg(t('login:accountAllreadyCreatedForEmail', { email: error.value }));
        } else if (path === 'password' && msg === 'Invalid value') {
          setErrMsg(t('login:passwordIsInvalid'));
        } else {
          setErrMsg(t('common:somethingWentWrong'));
        }
        shakeModal();
      } else {
        await login(credentials);
        await applyCouponCode();
        dispatch(actions.setOpenedModal('confirmCode'));
      }
    } catch (err) {
      console.error(err);
    }

    form.setIsLoadingActions(false);
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      onRegisterClick();
    }
  };

  const simpleStrRegex = 'regex:^[A-Za-z Šš Žž Õõ Ää Öö Üü -]+$';

  const form = useForm({
    formIsTouched: false,
    rules: [
      ['email', credentials.email, 'email', t('login:isNotValid')],
      ['firstName', credentials.first_name, 'required'],
      ['firstName', credentials.first_name, simpleStrRegex],
      ['lastName', credentials.last_name, 'required'],
      ['lastName', credentials.last_name, simpleStrRegex],
      ['password', credentials.password, 'required', t('common:isRequired')],
      ['password', credentials.password, 'password', t('common:passwordReset.isTooWeak')],
      [
        'termsAreAccepted',
        termsAreAccepted,
        'required',
        t('common:youNeedToAcceptOurTermsAndConditions'),
      ],
    ],
  });

  const isTempUser = !!user?.temporary;
  const labelAndValidation = {
    label: '',
    form,
    property: 'termsAreAccepted',
  };

  return (
    <ModalDialog
      onClose={
        isTempUser
          ? () => {
              dispatch(actions.setOpenedModal(null));
            }
          : undefined
      }
      allowBackdropNav={true}
      open={true}
      title={t('login:register')}
      hasContentPadding={false}
      isLoadingActions={form.isLoadingActions}
      error={errorMsg}
      actions={[
        <div key={'1'} style={{ width: '100%' }}>
          <Button
            fullWidth={true}
            label={t('login:register')}
            onClick={onRegisterClick}
            isLoading={form.isLoadingActions}
          />
        </div>,
      ]}
    >
      <form autoComplete="on" className={styles.container}>
        <div className={styles.header}>
          <div>{t('login:alreadyHaveAnAccount')}</div>
          <div>
            <Link to="#" onClick={() => dispatch(actions.setOpenedModal('login'))}>
              {t('login:login') as string}
            </Link>
            {/* <Link to="confirmEmailRegistrationCode">Enter code</Link> */}
          </div>
        </div>
        <div className={styles.content}>
          <TextField
            labelAndValidation={{
              label: t('login:email'),
              form,
              property: 'email',
            }}
            name="email"
            id="email"
            autoComplete="email"
            placeholder={t('login:emailPlaceholder')}
            value={credentials.email}
            onChange={e => {
              setCredentials(prevState => ({ ...prevState, email: e.target.value.toLowerCase() }));
            }}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={styles.line}></div>
        <div className={styles.content}>
          <TextField
            labelAndValidation={{
              label: t('common:personalInformation.firstName'),
              form,
              property: 'firstName',
            }}
            name="name"
            id="name"
            autoComplete="given-name"
            placeholder={t('common:personalInformation.firstName')}
            value={credentials.first_name}
            onChange={e => {
              setCredentials(prevState => ({
                ...prevState,
                first_name: capitalizeString(e.target.value),
              }));
            }}
            onKeyDown={onKeyDown}
          />
          <div className={styles.line}></div>
          <TextField
            labelAndValidation={{
              label: t('common:personalInformation.lastName'),
              form,
              property: 'lastName',
              labelMarginTop: true,
            }}
            name="surname"
            id="surname"
            autoComplete="family-name"
            placeholder={t('common:personalInformation.lastName')}
            value={credentials.last_name}
            onChange={e => {
              setCredentials(prevState => ({
                ...prevState,
                last_name: capitalizeString(e.target.value),
              }));
            }}
            onKeyDown={onKeyDown}
          />
          <div className={styles.line}></div>
          <TextField
            labelAndValidation={{
              label: t('common:personalInformation.password'),
              form,
              property: 'password',
              labelMarginTop: true,
              toolTip: t('common:passwordReset.passwordRule'),
            }}
            name="password"
            id="password"
            autoComplete="new-password"
            placeholder={t('common:personalInformation.password')}
            value={credentials.password}
            onChange={e =>
              setCredentials(prevState => ({ ...prevState, password: e.target.value }))
            }
            onKeyDown={onKeyDown}
            type="password"
          />
          <div className={styles.line}></div>
          {couponInputVisible ? (
            <div className={styles.couponCodeContainer}>
              <CouponInput
                coupon={coupon}
                setCoupon={setCoupon}
                couponStatus={couponStatus}
                setCouponStatus={setCouponStatus}
                onRemoveCoupon={() => {
                  setCouponInputVisible(false);
                  setCouponStatus(null);
                  setCoupon('');
                }}
              />
            </div>
          ) : (
            <div className={styles.addCouponLabel} onClick={() => setCouponInputVisible(true)}>
              {t('login:gotAPromotionalCode')}
            </div>
          )}

          <div className={styles.line}></div>

          <LabelWithErrors
            errorInfo={fieldErrorParser(labelAndValidation, false)}
            labelAndValidation={labelAndValidation}
          />
          <div className={styles.termsContainer}>
            <CheckBox
              checked={termsAreAccepted}
              onChange={() => setTermsAreAccepted(!termsAreAccepted)}
            />

            <div>
              <Trans
                i18nKey="login:agreeToTerms"
                components={[
                  <Link to="/policies" key={'1'} target="_blank" rel="noopener noreferrer" />,
                ]}
              />
            </div>
            <div></div>
          </div>
        </div>
      </form>
    </ModalDialog>
  );
};

export default RegisterModal;
