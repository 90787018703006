import { memo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import ModalDialog from 'components/common/ModalDialog/ModalDialog';
import useAuthentication from 'hooks/api/useAuthentication';
import requestAPI from 'utils/apiHandler';

import CouponInput from './CouponInput';
import styles from './CouponModal.module.scss';

interface Props {
  onClose: () => void;
}
const CouponModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [couponStatus, setCouponStatus] = useState<'isValid' | 'notValid'>(null);

  const { getCurrentUser } = useAuthentication();

  const applyCode = async () => {
    if (couponStatus !== 'isValid') {
      return;
    }
    setIsLoading(true);

    const res = await requestAPI({
      url: '/coupon/apply',
      method: 'POST',
      data: {
        code: coupon,
      },
    });

    if (res.status === 'ok') {
      getCurrentUser();
      onClose();
    }

    setIsLoading(false);
  };

  return (
    <ModalDialog
      open={true}
      onClose={onClose}
      title={t('login:addCoupon')}
      actionsJustifyContent="center"
      hasContentPadding={false}
      isLoadingActions={isLoading}
      actions={[
        {
          fullWidth: true,
          disabled: couponStatus !== 'isValid',
          label: t('login:applyYourCode'),
          onClick: () => {
            applyCode();
          },
        },
      ]}
    >
      <div className={styles.container}>
        <CouponInput
          coupon={coupon}
          setCoupon={setCoupon}
          couponStatus={couponStatus}
          setCouponStatus={setCouponStatus}
        />
      </div>
    </ModalDialog>
  );
};

export default memo(CouponModal);
