import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface UserI {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  email_verified_at: string;
  profile_image_id: string;
  validationEmailTimeStamp: string;
  role: string;
  plan: any;
  permissions: any;
  in_trial: boolean;
  trial_days_left: string;
  total_trial_days: string;
  temporary: boolean;
  language?: string;
}
export interface AuthState {
  user: UserI;
  featureFlags: string[];
  openedModal: 'login' | 'register' | 'confirmCode' | 'resetPassword';
}

const initialState: AuthState = {
  user: null,
  featureFlags: [],
  openedModal: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setOpenedModal(state, action: PayloadAction<AuthState['openedModal']>) {
      state.openedModal = action.payload;
    },
    setLanguage(state, action: PayloadAction<string>) {
      if (state.user) {
        state.user.language = action.payload;
      }
    },
    setAvatarUrl(state, action: PayloadAction<string>) {
      state.user.profile_image_id = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setFeatureFlags(state, action: PayloadAction<any>) {
      state.featureFlags = action.payload;
    },
    setUserField(state, action: PayloadAction<{ property: string; value: any }>) {
      state.user[action.payload.property] = action.payload.value;
    },
  },
});

export const reducer = authSlice.reducer;

export default authSlice.actions;
