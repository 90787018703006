import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import requestAPI from 'utils/apiHandler';

import { findMyFileById, getMyFileTitle, iterateMyFiles } from './helper';
import { MyFileI } from './interfaces';

const initialState: {
  myFiles?: MyFileI[];
  myFilesVersion: number;
  search: string;
  openedFolders: string[];
  selectedFolderId: string;
  selectedFolderIdParents: string[];
  modals: {
    folderDetailsModal: {
      id: 'new' | string;
      parent: string;
      isOpened: boolean;
    };
    folderSelectModal: {
      moveFolderId: string;
      targetFolderId: string;
      isOpened: boolean;
      openedFolders: string[];
    };
    filePreviewModal: {
      isOpened: boolean;
      item: MyFileI;
    };
    publishRequestModal: {
      isOpened: boolean;
      item: MyFileI;
    };
    fileRenameModal: {
      id: string;
      isOpened: boolean;
    };
  };
} = {
  myFiles: null,
  myFilesVersion: 0,
  search: '',
  openedFolders: [],
  selectedFolderId: null,
  selectedFolderIdParents: [],
  modals: {
    folderDetailsModal: {
      id: null,
      parent: null,
      isOpened: false,
    },
    folderSelectModal: {
      moveFolderId: null,
      targetFolderId: null,
      isOpened: false,
      openedFolders: [],
    },
    filePreviewModal: {
      isOpened: false,
      item: null,
    },
    fileRenameModal: {
      id: null,
      isOpened: false,
    },
    publishRequestModal: {
      isOpened: false,
      item: null,
    },
  },
};

export const fetchMyFiles = createAsyncThunk(
  'myFiles/fetchMyFiles',
  async (payload: any, thunkApi) => {
    let result = await requestAPI<null, MyFileI[]>('/myfiles');
    const compareFunc = ['type', item => getMyFileTitle(item).toLowerCase()];
    const compareSortOrder: ('desc' | 'asc')[] = ['desc', 'asc'];

    result = _.orderBy(result, compareFunc, compareSortOrder);
    iterateMyFiles(result, (item, parents) => {
      item.path = parents.map(i => i.title).join(' / ');
      if (item.childs) {
        item.childs = _.orderBy(item.childs, compareFunc, compareSortOrder);
      }
    });

    thunkApi.dispatch(
      MyFilesSlice.actions.addMyFiles({
        data: result,
      })
    );
  }
);

const MyFilesSlice = createSlice({
  name: 'myFiles',
  initialState,
  reducers: {
    setSelectedFolder(state, action: PayloadAction<string>) {
      if (action.payload !== null) {
        const { selectedItemParents } = findMyFileById(state.myFiles, action.payload);
        state.selectedFolderIdParents = [
          ...(selectedItemParents || []).map(i => i._id),
          action.payload,
        ];
      } else {
        state.selectedFolderIdParents = [];
      }
      state.selectedFolderId = action.payload;
    },
    addMyFiles(
      state,
      action: PayloadAction<{
        data: MyFileI[];
      }>
    ) {
      state.myFiles = action.payload.data;
      state.myFilesVersion += 1;
    },
    openFolderDetailsModal(state, action: PayloadAction<{ id: 'new' | string; parent: string }>) {
      const { id, parent } = action.payload;
      state.modals.folderDetailsModal = {
        id,
        parent,
        isOpened: true,
      };
    },
    closeFolderDetailsModal(state) {
      state.modals.folderDetailsModal = {
        id: null,
        parent: null,
        isOpened: false,
      };
    },
    openFileRenameModal(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.modals.fileRenameModal = {
        id,
        isOpened: true,
      };
    },
    closeFileRenameModal(state) {
      state.modals.fileRenameModal = {
        id: null,
        isOpened: false,
      };
    },

    openFolderInFileExplorer(state, action: PayloadAction<string>) {
      const folderId = action.payload;
      if (!state.openedFolders.includes(folderId)) {
        state.openedFolders.push(folderId);
      }
    },
    closeFolderInFileExplorer(state, action: PayloadAction<string>) {
      const folderId = action.payload;
      if (state.openedFolders.includes(folderId)) {
        state.openedFolders = state.openedFolders.filter(i => i !== folderId);
      }
    },
    setSearch(state, action: PayloadAction<string>) {
      const value = action.payload;
      state.search = value;
    },
    openFolderSelectModal(state, action: PayloadAction<string>) {
      const moveFolderId = action.payload;
      state.modals.folderSelectModal = {
        moveFolderId,
        isOpened: true,
        openedFolders: [],
        targetFolderId: null,
      };
    },
    closeFolderSelectModal(state) {
      state.modals.folderSelectModal = {
        moveFolderId: null,
        isOpened: false,
        openedFolders: [],
        targetFolderId: null,
      };
    },
    openFolderInFolderSelect(state, action: PayloadAction<string>) {
      const folderId = action.payload;
      const folders = state.modals.folderSelectModal.openedFolders;
      if (!folders.includes(folderId)) {
        folders.push(folderId);
      }
    },
    closeFolderInFolderSelect(state, action: PayloadAction<string>) {
      const folderId = action.payload;
      const folders = state.modals.folderSelectModal.openedFolders;
      if (folders.includes(folderId)) {
        state.modals.folderSelectModal.openedFolders = folders.filter(i => i !== folderId);
      }
    },
    openFilePreviewModal(state, action: PayloadAction<MyFileI>) {
      const item = action.payload;
      state.modals.filePreviewModal = {
        item,
        isOpened: true,
      };
    },
    closeFilePreviewModal(state, action: PayloadAction) {
      state.modals.filePreviewModal = {
        item: null,
        isOpened: false,
      };
    },
    openPublishRequestModal(state, action: PayloadAction<MyFileI>) {
      const item = action.payload;
      state.modals.publishRequestModal = {
        item,
        isOpened: true,
      };
    },
    closePublishRequestModal(state, action: PayloadAction) {
      state.modals.publishRequestModal = {
        item: null,
        isOpened: false,
      };
    },

    setTargetFolderInFolderSelect(state, action: PayloadAction<string>) {
      state.modals.folderSelectModal.targetFolderId = action.payload;
    },
  },

  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchMyFiles.pending, (state, action) => {
  //       // action is inferred correctly here if using TS
  //     })
  //     // You can chain calls, or have separate `builder.addCase()` lines each time
  //     .addCase(fetchMyFiles.fulfilled, (state, action) => {

  //     })
  //     // You can match a range of action types

  //     // and provide a default case if no other handlers matched
  //     .addDefaultCase((state, action) => {})

  // }
});

export const { reducer } = MyFilesSlice;
export default MyFilesSlice.actions;
