import React from 'react';

import classnames from 'classnames';

import './CheckBox.scoped.scss';

// Reusable checkbox, extends native checkbox
// Example:

// <CheckBox
//  round                                        // Basically the only additional argument that can alter it's look. Makes it round
//  onChange={handleSelecting(id)}
//  checked={false}
// />

interface Props {
  round?: boolean;
}

const CheckBox: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
  round,
  // I need this to avoid checking for function existing, if case of handler beeing elsewere
  // eslint-disable-next-line  @typescript-eslint/no-empty-function
  onChange = () => null,
  ...props
}) => {
  return (
    <label className={classnames({ 'custom-checkbox': true, round })}>
      <input type="checkbox" onChange={onChange} {...props} />
      <div className="fake-box" />
    </label>
  );
};

export default CheckBox;
