import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link, Route } from 'react-router-dom';

import DataProtection from './DataProtection/DataProtection';
import Imprints from './Imprints/Imprints';
import styles from './Policies.module.scss';
import PolicyTitle from './PolicyTitle';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Terms from './Terms/Terms';

const Policies: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <Route
          path="/policies"
          exact
          render={() => {
            return (
              <>
                <div className={styles.title}>{t('common:policies')}</div>
                <div className={styles.content}>
                  <div className={styles.subTitle}>{t('common:schoolifyPolicies')}:</div>
                  <Link to="/policies/terms">{t('common:termsAndConditions')}</Link>
                  <br />
                  <Link to="/policies/imprints">{t('common:imprints')}</Link>
                  <br />
                  <Link to="/policies/dataProtection">{t('common:dataProtection')}</Link>
                  <br />
                  <Link to="/policies/privacyPolicy">{t('common:privacyPolicy')}</Link>
                  <br />
                  <br />
                  <div className={styles.info}>{t('common:policiesInfo')}</div>
                </div>
              </>
            );
          }}
        />

        <Route
          path="/policies/terms"
          component={() => (
            <>
              <PolicyTitle title={t('common:termsAndConditions')} />
              <div className={styles.content}>
                <Terms />
              </div>
            </>
          )}
        />

        <Route
          path="/policies/imprints"
          component={() => (
            <>
              <PolicyTitle title={t('common:imprints')} />
              <div className={styles.content}>
                <Imprints />
              </div>
            </>
          )}
        />

        <Route
          path="/policies/dataProtection"
          component={() => (
            <>
              <PolicyTitle title={t('common:dataProtection')} />
              <div className={styles.content}>
                <DataProtection />
              </div>
            </>
          )}
        />

        <Route
          path="/policies/privacyPolicy"
          component={() => (
            <>
              <PolicyTitle title={t('common:privacyPolicy')} />
              <div className={styles.content}>
                <PrivacyPolicy />
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
};

export default React.memo(Policies);
