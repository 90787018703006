import React from 'react';

import classNames from 'classnames';

import { ReactComponent as ArrowDown } from 'assets/icons/chevron.svg';
import { ReactComponent as GlobeIcon } from 'assets/icons/globe.svg';
import DropDown from 'components/common/DropDown/DropDown';
import useLanguages from 'hooks/generic/useLanguages';

import styles from './SmallLanguageDropDown.module.scss';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const SmallLanguageDropDown: React.FC<Props> = ({ value, onChange }) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const languages = useLanguages();

  const handleChangeLanguage = value => {
    onChange(value);
  };

  return (
    <>
      <div className={styles.container}>
        <DropDown
          targetElementPos="top.center"
          childrenElementPos="bottom.center"
          paddingVertical={-6}
          value={value}
          onDropdownOpen={dropdownOpen => {
            setDropdownOpen(dropdownOpen);
          }}
          inputClassName={styles.inputClassName}
          emptyItemLabel="Language"
          showChevron={false}
          options={languages}
          panelClassName={styles.panelClassName}
          onChange={handleChangeLanguage}
          renderSelected={({ item }) => {
            return (
              <div className={classNames(styles.selected, { [styles.opened]: dropdownOpen })}>
                <GlobeIcon />
                <div className={styles.selectedLabel}>{item.id.toUpperCase()}</div>
                <div className={styles.chevronContainer}>
                  <ArrowDown
                    style={{
                      transform: dropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                  />
                </div>
              </div>
            );
          }}
          renderPanel={({ handleSelection }) => {
            return languages.map(item => {
              return (
                <div
                  key={item.id}
                  className={classNames(styles.item, {
                    [styles.itemSelected]: value === item.id,
                  })}
                  onClick={() => handleSelection(item)}
                >
                  {item.label}
                </div>
              );
            });
          }}
        />
      </div>
    </>
  );
};

export default SmallLanguageDropDown;
