import { useState } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import VerificationInput from 'react-verification-input';

import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import Button from 'components/common/Button/Button';
import LabelWithErrors from 'components/common/LabelWithErrors/LabelWithErrors';
import ModalDialog from 'components/common/ModalDialog/ModalDialog';
import TextField from 'components/common/TextField/TextField';
import useForm from 'hooks/generic/useForm';
import actions from 'redux/authentication/auth.slice';
import { useAppDispatch } from 'redux/hooks';
import requestAPI from 'utils/apiHandler';
import fieldErrorParser from 'utils/fieldErrorParser';
import { shakeModal } from 'utils/shakeElement';

import styles from './ResetPasswordModal.module.scss';

const ChangePasswordModal: React.FC = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    code: '',
    password: '',
    codeSentAt: '',
  });
  const { t } = useTranslation();
  const [errorMsg, setErrMsg] = useState('');
  const [codeIsSent, setCodeIsSent] = useState(false);
  const dispatch = useAppDispatch();

  const sendCodeToEmail = async () => {
    formSendEmail.setFormIsTouched(true);
    if (!formSendEmail.isValid) {
      shakeModal();
      return;
    }
    setErrMsg('');
    formSendEmail.setIsLoadingActions(true);

    try {
      const response = await requestAPI({
        url: 'request-reset-password',
        method: 'POST',
        data: {
          email: credentials.email,
        },
      });
      if (response.errors) {
        setErrMsg(t('login:checkWhetherTheEmailIsCorrect'));
        shakeModal();
      } else if (response.status === 'ok') {
        setErrMsg('');
        setCodeIsSent(true);
        setCredentials(prevState => ({ ...prevState, codeSentAt: DateTime.now().toISO() }));
      }
    } catch (err) {
      console.error(err);
    }

    formSendEmail.setIsLoadingActions(false);
  };
  const resetPassword = async () => {
    formChangePassword.setFormIsTouched(true);
    if (!formChangePassword.isValid) {
      return;
    }
    setErrMsg('');
    formChangePassword.setIsLoadingActions(true);

    try {
      const response = await requestAPI({
        url: 'reset-password',
        method: 'POST',
        data: {
          code: credentials.code.toUpperCase(),
          new_password: credentials.password,
          confirm_password: credentials.password,
        },
      });

      if (response.errors) {
        setErrMsg(t('login:somethingWentWrongCheckTheVirificationCode'));
        shakeModal();
      }
      if (response.message === 'User with following code not found') {
        setErrMsg(t('login:somethingWentWrongCheckTheVirificationCode'));
        shakeModal();
      }
      if (response.status === 'ok') {
        dispatch(actions.setOpenedModal('login'));
      }
    } catch (err) {
      console.error(err);
    }

    formChangePassword.setIsLoadingActions(false);
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      sendCodeToEmail();
    }
  };

  const formSendEmail = useForm({
    formIsTouched: false,
    rules: [['email', credentials.email, 'email', t('login:isNotValid')]],
  });
  const formChangePassword = useForm({
    formIsTouched: false,
    rules: [
      ['code', credentials.code, () => credentials.code.length === 6, 'required'],
      ['password', credentials.password, 'required', t('common:isRequired')],
      ['password', credentials.password, 'password', t('common:passwordReset.isTooWeak')],
    ],
  });

  const labelAndValidation = {
    label: t('login:verificationCode'),
    form: formChangePassword,
    property: 'code',
    labelMarginTop: true,
  };

  return (
    <ModalDialog
      onBackBtnClick={() => {
        if (codeIsSent) {
          setCodeIsSent(false);
          setErrMsg('');
        } else {
          dispatch(actions.setOpenedModal('login'));
        }
      }}
      open={true}
      title={t('login:resetPassword')}
      hasContentPadding={false}
      isLoadingActions={formSendEmail.isLoadingActions || formChangePassword.isLoadingActions}
      error={errorMsg}
      actions={[
        <div key={'1'} style={{ width: '100%' }}>
          <Button
            fullWidth={true}
            isLoading={formChangePassword.isLoadingActions || formSendEmail.isLoadingActions}
            label={
              codeIsSent
                ? t('common:passwordReset.confirmNewPassword')
                : t('login:sendVerificationCode')
            }
            onClick={() => {
              if (codeIsSent) {
                resetPassword();
              } else {
                sendCodeToEmail();
              }
            }}
          />

          {!codeIsSent && <br />}
          {!codeIsSent && (
            <div className={styles.footer}>
              <div>{t('login:alreadyHaveAVerificationCode')}</div>
              <div>
                <Link
                  onClick={() => {
                    setCredentials(prevState => ({ ...prevState, email: '' }));
                    setErrMsg('');
                    setCodeIsSent(true);
                    dispatch(actions.setOpenedModal('resetPassword'));
                  }}
                  to="#"
                >
                  {t('common:clickHere')}
                </Link>
              </div>
            </div>
          )}
        </div>,
      ]}
    >
      <form autoComplete="on" className={styles.container} onSubmit={e => e.preventDefault()}>
        {!codeIsSent && (
          <div className={styles.content}>
            <TextField
              labelAndValidation={{
                label: t('login:email'),
                form: formSendEmail,
                property: 'email',
                labelMarginTop: true,
              }}
              name="email"
              id="email"
              autoComplete="email"
              autoFocus={true}
              placeholder={t('login:emailPlaceholder')}
              value={credentials.email}
              onChange={e => {
                setCredentials(prevState => ({
                  ...prevState,
                  email: e.target.value.toLowerCase(),
                }));
              }}
              onKeyDown={onKeyDown}
            />
          </div>
        )}
        {codeIsSent && (
          <div className={styles.content}>
            <div className={styles.emailMessage}>
              <div className={styles.iconContainer}>
                <MailIcon />
              </div>
              <br />
              {t('login:checkYourEmail')}
              <br />
              {credentials.email &&
                t('login:thePasswordVerificationCodeHasBeenSentTo', {
                  email: credentials.email,
                  timestamp: DateTime.fromISO(credentials.codeSentAt).toFormat('dd.MM.yyyy HH:mm'),
                })}
            </div>
            <div className={styles.line}></div>
            <LabelWithErrors
              errorInfo={fieldErrorParser(labelAndValidation, credentials.code !== '')}
              labelAndValidation={labelAndValidation}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <VerificationInput
                autoFocus={true}
                value={credentials.code}
                onChange={code => {
                  setCredentials(prevState => ({ ...prevState, code }));
                }}
                onComplete={() => {
                  document.getElementById('password').focus();
                }}
                placeholder=""
                classNames={{
                  character: styles.character,
                  characterInactive: styles.characterInactive,
                  characterSelected: styles.characterSelected,
                  characterFilled: styles.characterFilled,
                }}
              />
            </div>
            <input
              style={{ pointerEvents: 'none', opacity: '0.01', height: '1px' }}
              onFocus={() => {
                document.getElementById('password').focus();
              }}
              // readOnly={true}
            />
            <TextField
              labelAndValidation={{
                label: t('common:passwordReset.newPassword'),
                form: formChangePassword,
                property: 'password',
                labelMarginTop: true,
                toolTip: t('common:passwordReset.passwordRule'),
              }}
              id="password"
              name="password"
              placeholder={t('common:passwordReset.newPassword')}
              value={credentials.password}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  resetPassword();
                }
              }}
              onChange={e =>
                setCredentials(prevState => ({ ...prevState, password: e.target.value }))
              }
              type="password"
              autoComplete="new-password"
            />
          </div>
        )}
      </form>
    </ModalDialog>
  );
};

export default ChangePasswordModal;
