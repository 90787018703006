import { createSlice } from '@reduxjs/toolkit';

interface UserProfileState {
  error: string;
  loading: 'idle' | 'pending' | 'success' | 'failed';
  userPrivateData: any;
  userData: any;
}

const initialState = {
  loading: 'idle',
  userPrivateData: null,
  userData: null,
} as UserProfileState;

const userProfileSlice = createSlice({
  name: 'userProfileSlice',
  initialState,
  reducers: {},
});

export const selectUserData = state => state.userProfile.userData;
export const selectLoading = state => state.userProfile.loading;

export default userProfileSlice.reducer;
