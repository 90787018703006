import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useConfirmDialog from 'components/common/ConfirmationModal/useConfirmDialog';
import useAuthentication from 'hooks/api/useAuthentication';
import actions from 'redux/authentication/auth.slice';

const DemoModeLanding: React.FC = () => {
  const history = useHistory();
  const { loginAsTempUser } = useAuthentication();
  const { confirmation } = useConfirmDialog();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const success = await loginAsTempUser();
      if (success) {
        history.push('/');
      } else {
        const result = await confirmation({
          title: t('login:demoUserTimeout'),
          message: (
            <div style={{ width: '300px', padding: '20px 0px', textAlign: 'left' }}>
              {t('login:cantCreateDemoModeAccount')}
            </div>
          ),
        });
        if (result) {
          location.reload();
        } else {
          dispatch(actions.setOpenedModal('login'));
        }
      }
    })();
  }, []);

  return null;
};

export default React.memo(DemoModeLanding);
