import { useEffect, useRef } from 'react';

// This hook is pretty self explanatory, it us used just like useEffect, the only difference is that it discards first use (so there is no init)

const useEffectAfterMount: (effect: () => void, deps: any[]) => void = (effect, deps) => {
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (effect) effect();
  }, deps);
};

export default useEffectAfterMount;
