import React from 'react';

import { FieldErrorParserInterface, FieldErrorParserResultInterface } from 'utils/fieldErrorParser';

import TooltipNew from '../TooltipNew/TooltipNew';
import styles from './LabelWithErrors.module.scss';

interface Props {
  errorInfo: FieldErrorParserResultInterface;
  labelAndValidation: FieldErrorParserInterface;
}

const LabelWithErrors: React.FC<Props> = ({ labelAndValidation, errorInfo }) => {
  const label = labelAndValidation?.label;
  const labelMarginTop = labelAndValidation?.labelMarginTop;
  const tooltip = labelAndValidation?.toolTip;
  return (
    <div>
      {(label || errorInfo.showFirstError) && (
        <div
          className={styles.labelContainer}
          style={{ margin: labelMarginTop ? '15px 0px 9px' : '0px 0px 9px 0px' }}
        >
          {label && !errorInfo.showFirstError && <div className={styles.title}>{label}</div>}
          {errorInfo.showFirstError && (
            <span className={styles.error} data-label-error={true}>
              {`${label.charAt(0).toUpperCase() + label.slice(1)} ${errorInfo.firstError}`}
            </span>
          )}

          {tooltip && (
            <TooltipNew tooltip={tooltip}>
              <div className={styles.toolTipIcon}>i</div>
            </TooltipNew>
          )}
        </div>
      )}

      {!label && labelMarginTop && <div style={{ marginTop: '38px' }}></div>}
    </div>
  );
};

export default LabelWithErrors;
