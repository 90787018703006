import React, { Suspense, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

const Imprints_en = React.lazy(() => import('pages/Policies/Imprints/Imprints_en'));
const Imprints_de = React.lazy(() => import('pages/Policies/Imprints/Imprints_de'));

const Imprints: React.FC = () => {
  const { i18n } = useTranslation();

  const language = i18n.resolvedLanguage;

  return (
    <Suspense fallback={<i></i>}>{language === 'de' ? <Imprints_de /> : <Imprints_en />}</Suspense>
  );
};

export default React.memo(Imprints);
