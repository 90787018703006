export default function useLanguages() {
  const languages = [
    {
      id: 'en',
      label: 'English',
    },
    {
      id: 'et',
      label: 'Eesti',
    },
    {
      id: 'de',
      label: 'Deutsch',
    },
    {
      id: 'es',
      label: 'Español',
    },
    {
      id: 'fr',
      label: 'Français',
    },
    {
      id: 'it',
      label: 'Italiano',
    },
    {
      id: 'lt',
      label: 'Lietuvių',
    },
    {
      id: 'lv',
      label: 'Latviešu',
    },
    {
      id: 'pt',
      label: 'Polski',
    },
    {
      id: 'ru',
      label: 'Русский',
    },
  ];
  return languages;
}
