import React, { Suspense, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

const DataProtection_en = React.lazy(
  () => import('pages/Policies/DataProtection/DataProtection_en')
);
const DataProtection_de = React.lazy(
  () => import('pages/Policies/DataProtection/DataProtection_de')
);

const DataProtection: React.FC = () => {
  const { i18n } = useTranslation();

  const language = i18n.resolvedLanguage;

  return (
    <Suspense fallback={<i></i>}>
      {language === 'de' ? <DataProtection_de /> : <DataProtection_en />}
    </Suspense>
  );
};

export default React.memo(DataProtection);
