import { combineReducers } from 'redux';

import { reducer as fileUploadsProgress } from 'components/logic/FileUploadsProgress/FileUploadsProgress.slice';

import { reducer as library } from '../pages/Library/Library.slice';
import { reducer as myFiles } from '../pages/MyFiles/myFiles.slice';
import { reducer as whiteboard } from '../pages/Whiteboard/whiteboard.slice';
import { reducer as app } from './app.slice';
import { reducer as auth } from './authentication/auth.slice';
import userProfileSlice from './editProfile/userProfile.slices';

const appReducer = combineReducers({
  whiteboard,
  library,
  myFiles,
  auth,
  userProfile: userProfileSlice,
  app,
  fileUploadsProgress,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};
export default rootReducer;
